import { Loader, Space, Stack } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { useWallet } from '@solana/wallet-adapter-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DISCORD_AUTH_URL } from '..';
import { APIClient } from '../api/ApiClient';
import { useUserContext } from '../contexts/UserContext';
import { Button } from '../stories/Button/Button';
import { StyledStepper } from '../stories/Stepper/Stepper';
import { StyledDialog } from '../stories/StyledDialog/StyledDialog';
import { BottomSpacer, isJwtValid, parseJWT, showNotificationOnError, TopSpacer } from '../utils';

export function DiscordLinkView() {
  const { user, setUser } = useUserContext();
  const { connected, publicKey } = useWallet();
  const [jwt] = useLocalStorage<string>({ key: 'sw-jwt', defaultValue: '' });
  const [loading, setLoading] = useState<boolean>(true);
  let navigate = useNavigate();

  useEffect(() => {
    const isConnected = connected && publicKey !== null;
    const validJwt = isJwtValid(jwt);

    async function getStatus() {
      setLoading(true);
      await showNotificationOnError(async () => {
        const user = await APIClient.resetDiscordForUser({ jwt });
        setUser(user);
        setLoading(false);
      });
    }

    if (isConnected && validJwt) {
      const claims = parseJWT(jwt);
      if (claims.address !== publicKey.toBase58()) {
        console.log(claims)
        console.log('jwt does not match connected wallet');
        navigate('/status');
      } else {
        getStatus();
      }
    }
  }, [
    connected,
    jwt,
    publicKey
  ]);

  if (loading) {
    return (
      <Stack align="center" spacing={0}>
        <TopSpacer />
        <Space h={24} />
        <Loader />
      </Stack>
    );
  } else {
    return (
      <Stack align="center" spacing={0}>
        <TopSpacer />
        <StyledStepper step={1} />
        <Space h={41} />
        <StyledDialog
          title="Relink your Discord"
          description="Loading this page will unlink your Discord. Relink it using the button below."
          descriptionProps={{
            color: '#909296',
            size: 12
          }}
          children={
            <>
              <Space h={30} />
              <Button
                {...{
                  label: 'Connect with Discord',
                  variant: 'discord',
                  toLink: `${DISCORD_AUTH_URL}&state=${user.discordRequestToken}`,
                  external: true
                }}
              />
              <Space h={38} />
            </>
          }
        />
        <BottomSpacer />
      </Stack>
    );
  }
}
