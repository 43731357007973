import { Group } from '@mantine/core';
import { useLocation } from 'react-router-dom';
import { Button } from '../Button/Button';

const showHomeForPaths = [
  '/',
  '/quests',
]

export function Header() {
  const location = useLocation();

  return (
    <Group position="apart">
      {location.pathname !== '/' ? (<Button label="Home" variant="secondary" toLink='/' />) : <Button label="Docs" variant="secondary" toLink='https://solworks.notion.site/Sujiko-772d2b629049477f9a7b131f43e642d8' external={true} />}
      {(showHomeForPaths.includes(location.pathname)) && <Button label="Check eligibility" variant="secondary" toLink='/status' />}
    </Group>
  );
}
