import { createContext, useState, useContext } from 'react';

interface IUserContext {
  user: any;
  setUser: (user: any) => void;
}

const defaultState = {
  user: {},
  setUser: () => { }
};

const UserContext = createContext<IUserContext>(defaultState);

export const UserProvider = ({ children }: { children: any }) => {
  const [user, setUser] = useState<any | null>(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
