import { Avatar, createStyles, Space, Stack, Text } from '@mantine/core';
import { PRIMARY_TEXT_COLOR } from '..';
import { useUserContext } from '../contexts/UserContext';
import { Button } from '../stories/Button/Button';
import { StyledStepper } from '../stories/Stepper/Stepper';
import { StyledDialog } from '../stories/StyledDialog/StyledDialog';
import { BottomSpacer, TopSpacer } from '../utils';

const useStyles = createStyles(() => ({
  title: {
    fontFamily: 'JetBrains Mono',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: PRIMARY_TEXT_COLOR,
    maxWidth: '366px'
  },
  subtitle: {
    margin: '14px 26px',
    fontFamily: 'JetBrains Mono',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#909296',
    maxWidth: '330px'
  },
  outline: {}
}));

export function FinishedView() {
  const { classes } = useStyles();
  const { user } = useUserContext();

  return (
    <Stack align="center" spacing={0} className={classes.outline}>
      <TopSpacer />
      <StyledStepper step={3} />
      <Space h={41} />
      <StyledDialog
        title={user ? `Congrats @${user.twitterScreenName} 🔥` : 'Congrats 🔥'}
        titleProps={{
          color: '#92f7cb'
        }}
        descriptionProps={{
          color: PRIMARY_TEXT_COLOR,
          size: 18
        }}
        description="Your application has been submitted!"
        children={
          <>
            {user?.twitterProfileImageUrl ? (
              <>
                <Space h={16} />
                <Avatar
                  src={user?.twitterProfileImageUrl}
                  size="xl"
                  radius={100}
                />
              </>
            ) : (
              <></>
            )}
            <Space h={16} />
            <Text className={classes.subtitle}>If you share this on Twitter, you might become a Warrior faster ⚔️ 😉</Text>
            <Space h={16} />
            <Button
              {...{
                label: 'Share on Twitter',
                variant: 'twitter',
                toLink: 'https://twitter.com/intent/tweet?text=ATTENTION%3A%20You%20will%20never%20be%20a%20Warrior%20if%20you%20do%20not%20apply!%0A%0AI%20just%20applied%20to%20the%20%40SujikoProtocol%20Scholarship.%0A%0AApply%20for%20a%20Scholarship%3A%20sujiko.dev%20(takes%20%3C60%20seconds)%20',
                external: true
              }}
            />
            <Space h={20} />
            <Button
              {...{
                label: 'Join our Discord',
                variant: 'discord',
                toLink: 'https://discord.com/invite/Qbd7yNcEPS',
                external: true
              }}
            />
            <Space h={20} />
            <Button
              {...{
                label: 'Track your application here',
                variant: 'outline',
                toLink: '/status',
                props: {
                  sx: {
                    textAlign: 'center !important'
                  }
                }
              }}
            />
            <Space h={38} />
          </>
        }
      />
      <BottomSpacer />
    </Stack>
  );
}
