import { MediaQuery, Space, createStyles} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { APPLICATIONS_OPEN } from '.';

export function parseJWT(jwt: string): any {
  return JSON.parse(atob(jwt.split('.')[1]));
}

export function routeBasedOnStage(stage: string, routeToQuests: boolean = false): string {
  switch (stage) {
  case 'SIGN_IN_WITH_TWITTER':
  case 'SIGN_IN_WITH_DISCORD':
    return '/step-three';
  case 'SELECT_CATEGORY':
    return routeToQuests 
      ? '/status' 
      : '/step-four';
  case 'QUESTION_ONE':
    return '/step-five';
  case 'QUESTION_TWO':
    return '/step-six';
  case 'QUESTION_THREE':
    return '/step-seven';
  case 'SUBMITTED':
  case 'REJECTED':
  case 'APPROVED':
  case 'PENDING_REJECTED':
    return '/status';
  case 'SIGN_IN_WITH_SOLANA':
  case 'NOT_CREATED':
  default:
    return '/step-one';
  }
}

export function stageToBadgeColor(stage: string, adminView: boolean = false): string {
  switch (stage) {
  case 'SUBMITTED':
    return 'blue';
  case 'PENDING_REJECTED':
    return adminView ? 'red' : 'blue';
  case 'REJECTED':
    return 'red';
  case 'APPROVED':
    return 'green';
  case 'SIGN_IN_WITH_TWITTER':
  case 'SIGN_IN_WITH_DISCORD':
  case 'SELECT_CATEGORY':
  case 'QUESTION_ONE':
  case 'QUESTION_TWO':
  case 'QUESTION_THREE':
  case 'SIGN_IN_WITH_SOLANA':
  case 'NOT_CREATED':
  default:
    return 'gray';
  }
}

export function stageToIsInProgress(stage: string): boolean {
  switch (stage) {
  case 'SUBMITTED':
  case 'REJECTED':
  case 'APPROVED':
  case 'PENDING_REJECTED':
    return false;
  case 'SIGN_IN_WITH_TWITTER':
  case 'SIGN_IN_WITH_DISCORD':
  case 'SELECT_CATEGORY':
  case 'QUESTION_ONE':
  case 'QUESTION_TWO':
  case 'QUESTION_THREE':
  case 'SIGN_IN_WITH_SOLANA':
  case 'NOT_CREATED':
  default:
    return true;
  }
}

export function stageToStatusLabel(
  stage: string, 
  adminView: boolean = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  twitterVerified: boolean = false,
  discordVerified: boolean = false,
  daoAndAccessCodeVerified: boolean = false
): string {
  let status = '';
  switch (stage) {
  case 'SUBMITTED':
    status = 'Under review';
    break;
  case 'PENDING_REJECTED':
    status = adminView ? 'Rejected (P)' : 'Under review';
    break;
  case 'REJECTED':
    status = 'Rejected';
    break;
  case 'APPROVED':
    status = 'Approved 🥳';
    break;
  // case 'SIGN_IN_WITH_TWITTER':
  //   status = 'Twitter not linked';
  //   break;
  case 'SIGN_IN_WITH_DISCORD':
    status = 'Discord not linked';
    break;
  case 'SELECT_CATEGORY':
  case 'QUESTION_ONE':
  case 'QUESTION_TWO':
  case 'QUESTION_THREE':
  case 'SIGN_IN_WITH_SOLANA':
    status = APPLICATIONS_OPEN ? 'In progress' : 'Not submitted in time';
    break;
  case 'NOT_CREATED':
  default:
    status = 'No application';
    break;
  }

  // if (!twitterVerified) {
  //   status = 'Twitter not linked';
  // }

  if (!discordVerified) {
    status = 'Discord not linked';
  }

  if (daoAndAccessCodeVerified) {
    status = 'Eligible for WL/Presale raffle';
  }


  return status;
}

interface showNotificationOnErrorConfig {
  showOnSuccess?: boolean;
  message?: string;
}

export async function showNotificationOnError(
  action: () => any,
  config?: showNotificationOnErrorConfig
) {
  try {
    await action();
    if (config?.showOnSuccess) {
      showNotification({
        title: 'Success',
        message: config?.message || 'Nice one 🎉',
        color: 'green'
      });
    }
  } catch (e: any) {
    console.log(e);
    showNotification({
      title: 'Something went wrong',
      message: e.message,
      color: 'red'
    });
  }
}


export const BottomSpacer = () => {
  return (
    <>
      <MediaQuery smallerThan={'sm'} styles={{ display: 'none' }}>
        <Space h={150} />
      </MediaQuery>
      <MediaQuery largerThan={'sm'} styles={{ display: 'none' }}>
        <Space h={80} />
      </MediaQuery>
    </>
  );
};

export const TopSpacer = () => {
  return (
    <>
      <MediaQuery smallerThan={'sm'} styles={{ display: 'none' }}>
        <Space h={77} />
      </MediaQuery>
      <MediaQuery largerThan={'sm'} styles={{ display: 'none' }}>
        <Space h={20} />
      </MediaQuery>
    </>
  );
};

export const CompactTopSpacer = () => {
  return (
    <>
      <MediaQuery smallerThan={'sm'} styles={{ display: 'none' }}>
        <Space h={40} />
      </MediaQuery>
      <MediaQuery largerThan={'sm'} styles={{ display: 'none' }}>
        <Space h={16} />
      </MediaQuery>
    </>
  );
};

export function isJwtValid(jwt: string): boolean {
  // validate jwt is string
  if (jwt === '' || jwt === undefined || jwt === null) {
    return false;
  }

  // parse jwt
  const { exp } = parseJWT(jwt);

  // validate jwt is not expired
  if (exp * 1000 < Date.now()) {
    return false;
  } else {
    return true;
  }
}

export function formatNumber(value: number, decimals: number): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
  return formatter.format(value);
}


export const useStyles = createStyles(() => ({
  normalText: {
    fontFamily: 'JetBrains Mono',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#d6d6d6',
    paddingBottom: '5px',
  },
  input: {
    height: 40,
    borderRadius: 0,
    border: '1px solid #2f3747',
    backgroundColor: 'transparent',
    fontFamily: 'JetBrains Mono',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    padding: '0 0 0 20px',
    color: 'white',
    '&:focus': {
      outline: 'none',
      border: '1px solid #ff8aad',
    },
    width: '100%',
  },
  error: {
    fontFamily: 'JetBrains Mono',
    fontSize: '10px',
    color: '#ff8aad',
    paddingTop: '5px',
  },  
  rightSection: {
    width: '100px',
    alignSelf: 'right',
    textAlign: 'right',
  },
  root: {
    width: '100%'
  }
}));
