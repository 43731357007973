import {
  ActionIcon,
  Divider,
  Grid,
  Group,
  MediaQuery,
  Space,
  Stack,
  Text
} from '@mantine/core';
import { createStyles } from '@mantine/core';
import { Button } from '../stories/Button/Button';
import { InfoCard } from '../stories/InfoCard/InfoCard';
import { BottomSpacer, TopSpacer } from '../utils';
import { 
  IconAppWindow,
  // IconBrandTwitter, 
  IconBrandDiscord, 
  IconBrandTwitter
} from '@tabler/icons';
import { APPLICATIONS_OPEN, PRIMARY_BACKGROUND_COLOR, PRIMARY_TEXT_COLOR } from '..';

export const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl,
    backgroundColor: PRIMARY_BACKGROUND_COLOR,
    color: PRIMARY_TEXT_COLOR,
    width: '100%'
  },
  titleText: {
    width: '327px',
    fontFamily: 'JetBrains Mono',
    fontSize: '28px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: PRIMARY_TEXT_COLOR
  },
  subtitleText: {
    width: '277px',
    fontFamily: 'JetBrains Mono',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#575757'
  },
  hintText: {
    fontFamily: 'JetBrains Mono',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#575757'
  },
  divider: {
    maxWidth: '420px',
    width: '100%',
    borderColor: 'rgba(87, 87, 87, 0.48)'
  }
}));

export function HomeView() {
  const { classes } = useStyles();

  return (
    <Stack align="center" spacing={0}>
      <TopSpacer />
      <Text className={classes.titleText}>
        Join the Sujiko Warriors.
      </Text>
      <Space h={14} />
      <Text className={classes.subtitleText}>
        We’re building a community of 4,444 Warriors.
      </Text>
      <Space h={29} />
      <Button
        label={APPLICATIONS_OPEN ? 'Apply in 60 seconds' : 'Check eligibility'}
        toLink="/status"
        variant={'primary'}
      />
      <Space h={8} />
      {APPLICATIONS_OPEN && (<Text className={classes.hintText}>Mint in December</Text>)}
      <Space h={APPLICATIONS_OPEN ? 39 : 25} />
      <Divider className={classes.divider} size={0.5} />
      <Space h={41} />
      <Text className={classes.titleText}>How does it work?</Text>
      <Space h={41} />
      <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
        <Grid gutter={91} align="center" justify="center">
          <Grid.Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <InfoCard
              title="Connect your wallet"
              description="Start by connecting your Solana wallet."
            />
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <InfoCard
              title="Claim your Survivor role"
              description="If you are eligible, claim your Survivor role."
            />
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <InfoCard
              title="Become a Warrior"
              description="When WL opens, mint your Warrior. More details soon."
            />
          </Grid.Col>
        </Grid>
      </MediaQuery>
      <MediaQuery largerThan="md" styles={{ display: 'none' }}>
        <Stack align="center" spacing={0}>
          <InfoCard
            title="Connect your wallet"
            description="Start by connecting your Solana wallet."
          />
          <Space h={40} />
          <InfoCard
            title="Claim your Survivor role"
            description="If you are eligible, claim your Survivor role."
          />
          <Space h={40} />
          <InfoCard
            title="Become a Warrior"
            description="When WL opens, mint your Warrior. More details soon."
          />
        </Stack>
      </MediaQuery>
      <Space h={41} />
      <Divider className={classes.divider} size={0.5} />
      <Space h={41} />
      <Text className={classes.titleText}></Text>
      <Space h={29} />
      <Group>
        <ActionIcon
          variant="outline"
          color="fire-pink.0"
          radius={0}
          size="xl"
          component="a"
          href="https://twitter.com/sujiko_perps"
          target="_blank"
        >
          <IconBrandTwitter size={24} />
        </ActionIcon>
        <ActionIcon
          variant="outline"
          color="fire-pink.0"
          radius={0}
          size="xl"
          component="a"
          href="https://discord.com/invite/Qbd7yNcEPS"
          target="_blank"
        >
          <IconBrandDiscord size={24} />
        </ActionIcon>
        <ActionIcon
          variant="outline"
          color="fire-pink.0"
          radius={0}
          size="xl"
          component="a"
          href="https://sujiko.trade"
          target="_blank"
        >
          <IconAppWindow size={24} />
        </ActionIcon>
      </Group>
      <BottomSpacer />
    </Stack>
  );
}
