import {
  Box,
  createStyles,
  Space,
  Stack,
  Text,
  Textarea,
  TextInput,
  TextInputProps,
  TextProps
} from '@mantine/core';
import { ReactNode, useState } from 'react';
import { PRIMARY_TEXT_COLOR } from '../..';

const useStyles = createStyles(() => ({
  wrapper: {
    maxWidth: '420px',
    width: '100%',
    padding: '24px 36px 0px 36px',
    '@media (max-width: 768px)': {
      padding: '24px 24px 0px 24px',
    },
    borderRadius: '0px',
    border: 'solid 1px rgb(44, 46, 51)',
    boxShadow: 'rgba(256, 256, 256, 0.1) 0px 5px 10px 0px'
  },
  title: {
    margin: '0 0 14px',
    fontFamily: 'JetBrains Mono',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center'
  },
  subtitle: {
    fontFamily: 'JetBrains Mono',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
  },
  textarea: {
    width: '100%',
    height: '182px',
    margin: '20px 0 0',
    padding: '20px 20px 15px',
    borderRadius: '8px',
    border: 'solid 1px #c6c6c6',
    backgroundColor: 'transparent',
    fontFamily: 'JetBrains Mono',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: PRIMARY_TEXT_COLOR
  },
  textinput: {
    minWidth: '327px',
    '@media (max-width: 768px)': {
      minWidth: '100%'
    },
    width: '100%',
    height: '47px',
    margin: '2px 0 0',
    padding: '16px 20px',
    borderRadius: '8px',
    border: 'solid 1px #1a1b1e',
    backgroundColor: 'transparent',
    fontFamily: 'JetBrains Mono',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: PRIMARY_TEXT_COLOR
  },
  textinputLabel: {
    fontFamily: 'JetBrains Mono',
    fontSize: '10px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#909296'
  }
}));

export function StyledDialog({
  title,
  description,
  subtitle,
  children, 
  titleProps,
  descriptionProps
}: {
  title: string;
  description: ReactNode;
  subtitle?: string;
  children?: ReactNode;
  titleProps?: TextProps;
  descriptionProps?: TextProps;
}) {
  const { classes } = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Stack align="center" spacing={0}>
        {/* subtitle */}
        {subtitle ? (
          <>
            <Text align="center" className={classes.subtitle}>
              {subtitle}
            </Text>
            <Space h={12} />
          </>
        ) : (
          <Space h={20} />
        )}

        {/* title */}
        <Text align="center" className={classes.title} {...titleProps}>
          {title}
        </Text>
        <Space h={14} />

        {/* description */}
        <Text align="center" className={classes.subtitle} {...descriptionProps}>
          {description}
        </Text>

        {children}
      </Stack>
    </Box>
  );
}

export function StyledTextArea({
  placeholder,
  value,
  setValue
}: {
  placeholder: string;
  value: string;
  setValue: any;
}) {
  const { classes } = useStyles();

  return (
    <Textarea
      classNames={{
        input: classes.textarea
      }}
      placeholder={placeholder}
      value={value}
      onChange={(event) => setValue(event.currentTarget.value)}
      minRows={4}
      maxRows={4}
      error={value.length > 280 ? 'Aim for less than 280 characters' : undefined}
      styles={{
        root: {
          width: '100%'
        }
      }}
    />
  );
}

export function StyledTextInput({
  placeholder,
  props,
  onChange
}: {
  placeholder: string;
  props?: TextInputProps;
  onChange?: (e: any) => void;
}) {
  const { classes } = useStyles();
  const [value, setValue] = useState('');

  return (
    <TextInput
      classNames={{
        input: classes.textinput,
        label: classes.textinputLabel
      }}
      placeholder={placeholder}
      value={value}
      onChange={(event) => {
        setValue(event.currentTarget.value);
        if (onChange) onChange(event);
      }}
      {...props}
    />
  );
}
