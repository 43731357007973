import {
  createStyles,
  Group,
  Loader,
  Text,
  UnstyledButton
} from '@mantine/core';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PRIMARY_COLOR, PRIMARY_TEXT_COLOR } from '../..';
import { showNotificationOnError } from '../../utils';

const useStyles = createStyles(() => ({
  primaryButton: {
    backgroundColor: '#ff8aad',
    color: PRIMARY_COLOR,
    padding: '10px 42px',
    borderRadius: '0px',
    border: '1px solid #ff8aad',
    height: '40px',
    transition: '0.2s all',
    '&:hover': {
      transform: 'scale(1.02)',
      backgroundColor: '#ff8aad'
    },
    '&:active': {
      transform: 'scale(0.98)'
    },
  },
  greenButton: {
    backgroundColor: '#AAF4CE',
    color: PRIMARY_COLOR,
    padding: '10px 42px',
    borderRadius: '0px',
    border: '1px solid #AAF4CE',
    height: '40px',
    transition: '0.2s all',
    '&:hover': {
      transform: 'scale(1.02)',
      backgroundColor: '#AAF4CE'
    },
    '&:active': {
      transform: 'scale(0.98)'
    },
  },
  primaryButtonFullWidth: {
    backgroundColor: '#ff8aad',
    color: PRIMARY_COLOR,
    padding: '10px 42px',
    borderRadius: '0px',
    border: '1px solid #ff8aad',
    height: '40px',
    transition: '0.2s all',
    '&:hover': {
      transform: 'scale(1.02)',
      backgroundColor: '#ff8aad'
    },
    '&:active': {
      transform: 'scale(0.98)'
    },
    width: '100%'
  },
  primaryButtonDisabledFullWidth: {
    backgroundColor: '#ff8aad',
    color: PRIMARY_COLOR,
    padding: '10px 42px',
    borderRadius: '0px',
    border: '1px solid #ff8aad',
    height: '40px',
    transition: '0.2s all',
    '&:hover': {
      transform: 'scale(1.02)',
      backgroundColor: '#ff8aad'
    },
    '&:active': {
      transform: 'scale(0.98)'
    },
    width: '100%',
    opacity: 0.5,
    cursor: 'not-allowed'
  },
  primaryMiniButton: {
    backgroundColor: '#ff8aad',
    color: PRIMARY_COLOR,
    border: '1px solid #ff8aad',
    padding: '6px 16px',
    borderRadius: '1px',
    transition: '0.2s all',
    '&:hover': {
      transform: 'scale(1.02)',
      backgroundColor: '#ff8aad'
    },
    '&:active': {
      transform: 'scale(0.98)'
    }
  },
  primaryButtonDisabled: {
    backgroundColor: '#ff8aad',
    color: PRIMARY_COLOR,
    padding: '10px 42px',
    borderRadius: '0px',
    border: '1px solid #ff8aad',
    height: '40px',
    transition: '0.2s all',
    '&:hover': {
      transform: 'scale(1.02)',
      backgroundColor: '#ff8aad'
    },
    '&:active': {
      transform: 'scale(0.98)'
    },
    opacity: 0.5,
    cursor: 'not-allowed'
  },
  primaryMiniDisabledButton: {
    backgroundColor: '#ff8aad',
    color: PRIMARY_COLOR,
    border: '1px solid #ff8aad',
    padding: '6px 16px',
    borderRadius: '1px',
    transition: '0.2s all',
    '&:hover': {
      transform: 'scale(1.02)',
      backgroundColor: '#ff8aad'
    },
    '&:active': {
      transform: 'scale(0.98)'
    },
    opacity: 0.5,
    cursor: 'not-allowed'
  },
  primaryButtonText: {
    fontFamily: 'JetBrains Mono',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: PRIMARY_COLOR
  },
  primaryMiniText: {
    fontFamily: 'JetBrains Mono',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: PRIMARY_COLOR
  },
  solanaMiniText: {
    fontFamily: 'JetBrains Mono',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: PRIMARY_TEXT_COLOR
  },
  secondaryButton: {
    backgroundColor: 'transparent',
    color: PRIMARY_TEXT_COLOR,
    padding: '12px 35px',
    borderRadius: '0px',
    transition: '0.2s all',
    border: '1px solid #ff8aad',
    '&:hover': {
      transform: 'scale(1.02)',
      backgroundColor: 'rgba(255, 138, 173, 0.15)',
      border: 'solid 1px #ff8aad'
    },
    '&:active': {
      transform: 'scale(0.98)'
    }
  },
  whiteText: {
    fontFamily: 'JetBrains Mono',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: PRIMARY_TEXT_COLOR
  },
  twitterButton: {
    backgroundColor: '#53a9f0',
    color: PRIMARY_TEXT_COLOR,
    padding: '12px 35px',
    borderRadius: '0px',
    transition: '0.2s all',
    '&:hover': {
      transform: 'scale(1.02)'
    },
    '&:active': {
      transform: 'scale(0.98)'
    }
  },
  solanaButton: {
    backgroundColor: '#564dc7',
    color: PRIMARY_TEXT_COLOR,
    padding: '12px 35px',
    borderRadius: '0px',
    transition: '0.2s all',
    '&:hover': {
      transform: 'scale(1.02)'
    },
    '&:active': {
      transform: 'scale(0.98)'
    }
  },
  solanaMiniButton: {
    backgroundColor: '#564dc7',
    color: PRIMARY_TEXT_COLOR,
    padding: '6px 12px',
    borderRadius: '0px',
    transition: '0.2s all',
    '&:hover': {
      transform: 'scale(1.02)'
    },
    '&:active': {
      transform: 'scale(0.98)'
    }
  },
  discordButton: {
    backgroundColor: '#6a73ef',
    color: PRIMARY_TEXT_COLOR,
    padding: '12px 35px',
    borderRadius: '0px',
    transition: '0.2s all',
    '&:hover': {
      transform: 'scale(1.02)'
    },
    '&:active': {
      transform: 'scale(0.98)'
    }
  },
  discordMiniButton: {
    backgroundColor: '#6a73ef',
    color: PRIMARY_TEXT_COLOR,
    padding: '6px 16px',
    borderRadius: '1px',
    transition: '0.2s all',
    '&:hover': {
      transform: 'scale(1.02)'
    },
    '&:active': {
      transform: 'scale(0.98)'
    }
  },
  discordMiniDisabledButton: {
    backgroundColor: '#6a73ef',
    color: PRIMARY_TEXT_COLOR,
    padding: '6px 16px',
    borderRadius: '1px',
    transition: '0.2s all',
    '&:hover': {
      transform: 'scale(1.02)',
    },
    '&:active': {
      transform: 'scale(0.98)'
    },
    opacity: 0.5,
    cursor: 'not-allowed'
  },
  discordMiniText: {
    fontFamily: 'JetBrains Mono',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: PRIMARY_TEXT_COLOR
  },
  outlineButton: {
    backgroundColor: 'transparent',
    color: '909296',
    '&:hover': {
      transform: 'scale(1.02)'
    },
    '&:active': {
      transform: 'scale(0.98)'
    }
  },
  outlineButtonText: {
    fontFamily: 'JetBrains Mono',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#909296'
  }
}));

export function SimpleButton({
  label,
  url
}: {
  label: ReactNode;
  url: string;
}) {
  const { classes } = useStyles();

  return (
    <UnstyledButton
      component="a"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.outlineButton}
    >
      <Text className={classes.outlineButtonText}>{label}</Text>
    </UnstyledButton>
  );
}

export function Button({
  label,
  variant = 'primary',
  toLink,
  onClick,
  underlined,
  external,
  defaultIsLoading,
  props
}: ButtonProps) {
  const { classes } = useStyles();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(defaultIsLoading || false);

  let buttonClass = classes.primaryButton;
  let buttonTextClass = classes.primaryButtonText;

  switch (variant) {
  case 'primary':
    buttonClass = classes.primaryButton;
    buttonTextClass = classes.primaryButtonText;
    break;
  case 'primary-full-width':
    buttonClass = classes.primaryButtonFullWidth;
    buttonTextClass = classes.primaryButtonText;
    break;
  case 'primary-disabled':
    buttonClass = classes.primaryButtonDisabled;
    buttonTextClass = classes.primaryButtonText;
    break;
  case 'primary-disabled-full-width':
    buttonClass = classes.primaryButtonDisabledFullWidth;
    buttonTextClass = classes.primaryButtonText;
    break;
  case 'primary-mini-disabled':
    buttonClass = classes.primaryMiniDisabledButton;
    buttonTextClass = classes.primaryMiniText;
    break;
  case 'primary-mini':
    buttonClass = classes.primaryMiniButton;
    buttonTextClass = classes.primaryMiniText;
    break;
  case 'solana-mini':
    buttonClass = classes.solanaMiniButton;
    buttonTextClass = classes.solanaMiniText;
    break;
  case 'discord-mini':
    buttonClass = classes.discordMiniButton;
    buttonTextClass = classes.discordMiniText;
    break;
  case 'discord-mini-disabled':
    buttonClass = classes.discordMiniDisabledButton;
    buttonTextClass = classes.discordMiniText;
    break;
  case 'secondary':
    buttonClass = classes.secondaryButton;
    buttonTextClass = classes.whiteText;
    break;
  case 'twitter':
    buttonClass = classes.twitterButton;
    buttonTextClass = classes.whiteText;
    break;
  case 'solana':
    buttonClass = classes.solanaButton;
    buttonTextClass = classes.whiteText;
    break;
  case 'discord':
    buttonClass = classes.discordButton;
    buttonTextClass = classes.whiteText;
    break;
  case 'outline':
    buttonClass = classes.outlineButton;
    buttonTextClass = classes.outlineButtonText;
    break;
  case 'green':
    buttonClass = classes.greenButton;
    buttonTextClass = classes.primaryButtonText;
    break;
  }

  if (toLink) {
    if (external) {
      return (
        <UnstyledButton
          className={buttonClass}
          onClick={async () => {
            setLoading(true);
            await showNotificationOnError(async () => {
              if (onClick) {
                await onClick();
              }
              window.location.href = toLink;
              return null;
            });
            setLoading(false);
          }}
          {...props}
        >
          <Text className={buttonTextClass}>
            {loading ? (
              <Group>
                {label}
                <Loader size={14} color="black" variant="bars" />
              </Group>
            ) : (
              label
            )}
          </Text>
        </UnstyledButton>
      );
    } else {
      return (
        <UnstyledButton
          className={buttonClass}
          onClick={async () => {
            setLoading(true);
            await showNotificationOnError(async () => {
              if (onClick) {
                await onClick();
              }
              navigate(toLink);
            });
            setLoading(false);
          }}
          {...props}
        >
          <Text className={buttonTextClass}>
            {loading ? (
              <Group>
                {label}
                <Loader size={14} color="black" variant="bars" />
              </Group>
            ) : (
              label
            )}
          </Text>
        </UnstyledButton>
      );
    }
  } else {
    return (
      <UnstyledButton
        className={buttonClass}
        onClick={async () => {
          setLoading(true);
          await showNotificationOnError(async () => {
            if (onClick) {
              await onClick();
            }
          });
          setLoading(false);
        }}
        {...props}
      >
        <Text className={buttonTextClass}>
          {loading ? (
            <Group position='center'>
              <Loader size={14} color="black" variant="bars" />
              {label}
            </Group>
          ) : underlined ? (
            <u>{label}</u>
          ) : (
            <>{label}</>
          )}
        </Text>
      </UnstyledButton>
    );
  }
}

export interface ButtonProps {
  label: ReactNode;
  variant?: ButtonVariant;
  toLink?: string;
  onClick?: () => void;
  underlined?: boolean;
  external?: boolean;
  defaultIsLoading?: boolean;
  props?: any;
}

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'twitter'
  | 'discord'
  | 'solana'
  | 'outline'
  | 'primary-disabled'
  | 'primary-mini'
  | 'primary-mini-disabled'
  | 'solana-mini'
  | 'discord-mini'
  | 'discord-mini-disabled'
  | 'primary-full-width'
  | 'primary-disabled-full-width'
  | 'green';
