/* eslint-disable @typescript-eslint/no-unused-vars */
import { Badge, Divider, Group, Loader, Popover, Space, Stack, Text } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { APIClient } from '../api/ApiClient';
import { StyledDialog } from '../stories/StyledDialog/StyledDialog';
import { useStyles } from './StatusView';
import moment from 'moment';
import { TopSpacer } from '../utils';
import { Navigate } from 'react-router-dom';

export function RaffleView() {
  const { classes } = useStyles();
  const [jwt] = useLocalStorage<string>({ key: 'sw-jwt', defaultValue: '' });
  
  const [loading, setLoading] = useState<boolean>(false);
  const [reloadData, setReloadData] = useState<boolean>(false);
  const [raffleEndDate, setRaffleEndDate] = useState<Date | null>(null);
  const [numberOfEntrants, setNumberOfEntrants] = useState<number>(0);
  const [numberOfSpots, setNumberOfSpots] = useState<number>(0);
  const [daoName, setDaoName] = useState<string>('-');
  const [hasRaffleEnded, setHasRaffleEnded] = useState<boolean>(false);
  const [isUserWinner, setIsUserWinner] = useState<boolean>(false);
  const [hasUserEntered, setHasUserEntered] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    async function getInfo() {
      if (jwt) {
        console.log('JWT found');
        setLoading(true);
        const user = await APIClient.getUser({ jwt });
        const info = await APIClient.getUserRaffleInfo(jwt);
        if (info === null) {
          setError(true);
          setLoading(false);
        } else {
          setDaoName(info.info.dao);
          setNumberOfEntrants(info.info.raffleEntrantAdresses.length);
          setNumberOfSpots(info.info.totalPresaleSpots);
          setRaffleEndDate(new Date(info.info.raffleEndsAt));
          setHasRaffleEnded(new Date(info.info.raffleEndsAt) < new Date());
  
          const winners: string[] = info.info.raffleWinners;
          const entrants: string[] = info.info.raffleEntrantAdresses;
  
          if (winners.includes(user.address)) {
            setIsUserWinner(true);
          }
  
          if (entrants.includes(user.address)) {
            setHasUserEntered(true);
          }
        }
      } else {
        console.log('No JWT found');
      }

      setLoading(false);
    }

    getInfo();
  }, [reloadData, jwt]);

  if (loading) {
    return (
      <Stack align="center" spacing={0}>
        <TopSpacer />
        <Group>
          <Loader />
        </Group>
      </Stack>
    );
  } else if (error) {
    return <Navigate to={'/'} />;
  } else {
    return (
      <Stack align="center" spacing={0} className={classes.outline}>
        <TopSpacer />
        <StyledDialog
          title="DAO Raffle"
          description="View your DAO's raffle for presale spots"
          descriptionProps={{
            color: '#909296',
            size: 12
          }}
          children={
            <>
              <Space h={28} />
              <Divider
                size={1}
                style={{
                  width: '100%',
                  borderColor: 'rgba(87, 87, 87, 0.48)'
                }}
              />
              <Space h={28} />
              <Stack style={{ width: '100%' }}>
                <Group position="apart">
                  <Text className={classes.statusLabel}>DAO name:</Text>
                  <Text className={classes.statusLabel}>{daoName}</Text>
                </Group>
                <Group position="apart">
                  <Text className={classes.statusLabel}>
                    Presale spots available:
                  </Text>
                  <Text className={classes.statusLabel}>{numberOfSpots}</Text>
                </Group>
                <Group position="apart">
                  <Text className={classes.statusLabel}>Status:</Text>
                  {raffleEndDate !== null && (
                    <Badge color={raffleEndDate! > new Date() ? 'yellow' : 'green'} variant="outline">
                      {raffleEndDate! > new Date() ? 'Active' : 'Ended'}
                    </Badge>
                  )}
                </Group>
                <Space h={2} />
                <Divider
                  size={1}
                  style={{
                    width: '100%',
                    borderColor: 'rgba(87, 87, 87, 0.48)'
                  }}
                />
                <Space h={2} />
                <Group position="apart">
                  <Text className={classes.statusLabel}>Entrants:</Text>
                  <Text className={classes.statusLabel}>{numberOfEntrants}</Text>
                </Group>
                <Group position="apart">
                  <Text className={classes.statusLabel}>Raffle ends:</Text>
                  <Popover width={175} position="bottom" withArrow shadow="md" styles={{ dropdown: { backgroundColor: 'black' }}}>
                    <Popover.Target>
                      <Text className={classes.statusLabel}>
                        {raffleEndDate !== null ? moment(raffleEndDate).fromNow() : ''}
                      </Text>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Text size="sm">{raffleEndDate?.toLocaleString()}</Text>
                    </Popover.Dropdown>
                  </Popover>
                </Group>
                <Group position="apart">
                  <Text className={classes.statusLabel}>Your status:</Text>
                  <Badge
                    color={
                      hasRaffleEnded ? (isUserWinner ? 'green' : 'red') : hasUserEntered ? 'blue' : 'gray'
                    }
                    variant="outline"
                  >
                    {hasRaffleEnded
                      ? isUserWinner
                        ? 'You won, LFG!'
                        : 'You didn\'t win'
                      : hasUserEntered ? 'Entered' : 'Not entered'}
                  </Badge>
                </Group>
              </Stack>
              <Space h={40} />
            </>
          }
        />
      </Stack>
    );
  }
}
