import React from 'react';
import { Group, Space, Stack, Tabs } from '@mantine/core';
import { BottomSpacer, CompactTopSpacer } from '../utils';
import { AdminView, useStyles } from './AdminView';
import { DaoAccessCodesView } from './DaoAccessCodesView';
import { QuestsView } from './QuestsView';

export const AdminViewWrapper = () => {
  const { classes } = useStyles();

  return (
    <Stack align="center" spacing={0}>
      <CompactTopSpacer />
      <Tabs
        color="white"
        defaultValue="applications"
        unstyled
        styles={() => ({
          tabsList: {
            display: 'flex'
          }
        })}
        classNames={{
          tab: classes.tab
        }}
      >
        <Group position="center">
          <Tabs.List>
            <Tabs.Tab value="applications">Applications</Tabs.Tab>
            <Tabs.Tab value="quests">Quests</Tabs.Tab>
            <Tabs.Tab value="quest-submissions">DAO/Access Codes</Tabs.Tab>
          </Tabs.List>
        </Group>

        <Space h={30} />

        <Tabs.Panel value="applications">
          <AdminView />
        </Tabs.Panel>

        <Tabs.Panel value="quests">
          <QuestsView />
        </Tabs.Panel>

        <Tabs.Panel value="quest-submissions">
          <DaoAccessCodesView />
        </Tabs.Panel>
      </Tabs>

      <BottomSpacer />
    </Stack>
  );
};
