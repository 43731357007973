import { AppShell } from '@mantine/core';
import { ReactNode } from 'react';
import { StyledFooter } from '../stories/StyledFooter/StyledFooter';
import { useStyles } from '../views/HomeView';
import { Header } from '../stories/Header/Header';
import { MantineProvider } from '@mantine/core';

export function ProvidersWrapper({ children }: { children: ReactNode }) {
  const { classes } = useStyles();

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={{
      colors: {
        'fire-pink': ['#575757', '#5FCCDB', '#44CADC', '#2AC9DE', '#1AC2D9', '#11B7CD', '#09ADC3', '#0E99AC', '#128797', '#147885'],
      }
    }}>
      <AppShell
        className={classes.root}
        header={<Header />}
        footer={<StyledFooter />}
        styles={{
          main: {
            height: '100',
            padding: '0'
          }
        }}
      >
        {children}
      </AppShell>
    </MantineProvider>
  );
}
