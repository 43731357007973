import '@fontsource/jetbrains-mono';
import { ProvidersWrapper } from './components/ProvidersWrapper';
import { HomeView } from './views/HomeView';
import { useLocation, useOutlet, createBrowserRouter } from 'react-router-dom';
import { StepOneView } from './views/StepOneView';
import { StepTwoView } from './views/StepTwoView';
import { StepThreeView } from './views/StepThreeView';
import { StepFourView } from './views/StepFourView';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './styles.css';
import { createRef, useMemo } from 'react';
import { StepFiveView } from './views/StepFiveView';
import { StepSixView } from './views/StepSixView';
import { StepSevenView } from './views/StepSevenView';
import { FinishedView } from './views/FinishedView';
import { StatusView } from './views/StatusView';
import {
  ConnectionProvider,
  WalletProvider
} from '@solana/wallet-adapter-react';

import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';
import { CloverWalletAdapter } from '@solana/wallet-adapter-clover';
import { Coin98WalletAdapter } from '@solana/wallet-adapter-coin98';
import { GlowWalletAdapter } from '@solana/wallet-adapter-glow';
import { MathWalletAdapter } from '@solana/wallet-adapter-mathwallet';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { SolletWalletAdapter } from '@solana/wallet-adapter-sollet';
import { CoinbaseWalletAdapter } from '@solana/wallet-adapter-coinbase';
import { LedgerWalletAdapter } from '@solana/wallet-adapter-ledger';
import { BackpackWalletAdapter } from '@solana/wallet-adapter-backpack';
import { NotificationsProvider } from '@mantine/notifications';
import { UserProvider } from './contexts/UserContext';
import { TwitterCallbackView } from './views/TwitterCallbackView';
import { DiscordCallbackView } from './views/DiscordCallbackView';
import { AdminUserView } from './views/AdminUserView';
import { AdminViewWrapper } from './views/AdminViewWrapper';
import { UserQuestsView } from './views/UserQuestsView';
import { RaffleView } from './views/RaffleView';
import { UserPresaleView } from './views/UserPresaleView';
import { DiscordLinkView } from './views/DiscordLinkView';
import { ENDPOINT_CONFIGS } from '.';
import { FourZeroFourView } from './views/FourZeroFourView';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const routes = [
  { path: '/', name: 'Home', element: <HomeView />, nodeRef: createRef() },
  {
    path: '/step-one',
    name: 'Home',
    element: <StepOneView />,
    nodeRef: createRef()
  },
  {
    path: '/step-two',
    name: 'Home',
    element: <StepTwoView />,
    nodeRef: createRef()
  },
  {
    path: '/step-three',
    name: 'Home',
    element: <StepThreeView />,
    nodeRef: createRef()
  },
  {
    path: '/step-four',
    name: 'Home',
    element: <StepFourView />,
    nodeRef: createRef()
  },
  {
    path: '/step-five',
    name: 'Home',
    element: <StepFiveView />,
    nodeRef: createRef()
  },
  {
    path: '/step-six',
    name: 'Home',
    element: <StepSixView />,
    nodeRef: createRef()
  },
  {
    path: '/step-seven',
    name: 'Home',
    element: <StepSevenView />,
    nodeRef: createRef()
  },
  {
    path: '/finished',
    name: 'Home',
    element: <FinishedView />,
    nodeRef: createRef()
  },
  {
    path: '/status',
    name: 'Home',
    element: <StatusView />,
    nodeRef: createRef()
  },
  {
    path: '/twitter-callback',
    name: 'Callback',
    element: <TwitterCallbackView />,
    nodeRef: createRef()
  },
  {
    path: '/discord-callback',
    name: 'Callback',
    element: <DiscordCallbackView />,
    nodeRef: createRef()
  },
  {
    path: '/bacon',
    name: 'Bacon',
    element: <AdminViewWrapper />,
    nodeRef: createRef()
  },
  {
    path: '/bacon/:id',
    name: 'Bacon',
    element: <AdminUserView />,
    nodeRef: createRef()
  },
  {
    path: '/quests',
    name: 'Open Quests',
    element: <UserQuestsView />,
    nodeRef: createRef()
  },
  {
    path: '/raffle',
    name: 'Raffle',
    element: <RaffleView />,
    nodeRef: createRef()
  },
  {
    path: '/presale',
    name: 'Presale',
    element: <UserPresaleView />,
    nodeRef: createRef()
  },
  {
    path: '/relink',
    name: 'Discord Relink',
    element: <DiscordLinkView />,
    nodeRef: createRef()
  },
  {
    path: '*',
    name: '404',
    element: <FourZeroFourView />,
    nodeRef: createRef()
  }
];

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: routes.map((route) => ({
      index: route.path === '/',
      path: route.path === '/' ? undefined : route.path,
      element: route.element
    }))
  }
]);

const firebaseConfig = {
  apiKey: 'AIzaSyCAw08FnOgFhnHzKt2Dtw5tnTQ0t-GUoUI',
  authDomain: 'sujiko-wl.firebaseapp.com',
  projectId: 'sujiko-wl',
  storageBucket: 'sujiko-wl.appspot.com',
  messagingSenderId: '711721858843',
  appId: '1:711721858843:web:e80daa7dae50965c5c45be',
  measurementId: 'G-MYZVGPHJR2'
};
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export default function App() {
  let location = useLocation();
  const currentOutlet = useOutlet();
  const { nodeRef } =
    routes.find((route) => route.path === location.pathname) ?? ({} as any);
  const endpoint = ENDPOINT_CONFIGS[1];
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter({}),
      new SolletWalletAdapter({}),
      new CoinbaseWalletAdapter({}),
      new LedgerWalletAdapter(),
      new BackpackWalletAdapter({}),
      new SolflareWalletAdapter({}),
      new CloverWalletAdapter({}),
      new Coin98WalletAdapter({}),
      new GlowWalletAdapter({}),
      new MathWalletAdapter({})
    ],
    []
  );

  return (
    <UserProvider>
      <ConnectionProvider endpoint={endpoint.url}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <NotificationsProvider>
              <ProvidersWrapper>
                <TransitionGroup>
                  <CSSTransition
                    key={location.key}
                    nodeRef={nodeRef}
                    classNames="fade"
                    timeout={500}
                  >
                    <div ref={nodeRef} className="fade">
                      {currentOutlet}
                    </div>
                  </CSSTransition>
                </TransitionGroup>
              </ProvidersWrapper>
            </NotificationsProvider>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </UserProvider>
  );
}
