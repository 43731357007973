import { createStyles, Group, Space, Stack } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PRIMARY_TEXT_COLOR } from '..';
import { APIClient } from '../api/ApiClient';
import { useUserContext } from '../contexts/UserContext';
import { Button } from '../stories/Button/Button';
import { StyledStepper } from '../stories/Stepper/Stepper';
import {
  StyledDialog,
  StyledTextInput
} from '../stories/StyledDialog/StyledDialog';
import { showNotificationOnError, routeBasedOnStage, BottomSpacer, TopSpacer } from '../utils';

const useStyles = createStyles(() => ({
  title: {
    fontFamily: 'JetBrains Mono',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: PRIMARY_TEXT_COLOR,
    maxWidth: '366px'
  },
  subtitle: {
    margin: '14px 26px',
    fontFamily: 'JetBrains Mono',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#909296',
    maxWidth: '330px'
  },
  outline: {}
}));

export function StepSevenView() {
  const { classes } = useStyles();
  const [numberOfLinks, setNumberOfLinks] = useState(1);
  const [links, setLinks] = useState(Array(5).fill(''));

  const linkInputs = useMemo(() => {
    const linkInputComponents = [];
    for (let i = 0; i < numberOfLinks; i++) {
      linkInputComponents.push(
        <StyledTextInput placeholder="" props={{ label: `Link #${i + 1}` }} onChange={
          (e) => {
            const newLinks = [...links];
            newLinks[i] = e.target.value;
            setLinks(newLinks);
          }
        } />
      );

      if (i < numberOfLinks - 1) {
        linkInputComponents.push(<Space h={12} />);
      }
    }
    return linkInputComponents;
  }, [numberOfLinks]);
  const { setUser } = useUserContext();
  const navigate = useNavigate();
  const [jwt] = useLocalStorage({
    key: 'sw-jwt',
    defaultValue: ''
  });
  const [, setStatus] = useLocalStorage({
    key: 'sw-status',
    defaultValue: ''
  });

  return (
    <Stack align="center" spacing={0} className={classes.outline}>
      <TopSpacer />
      <StyledStepper step={2} />
      <Space h={41} />
      <StyledDialog
        title="Show off your work"
        description="Share links to your website, blog, relevant tweets, GitHub repos, art, etc. If you don’t have any links, just click Submit."
        descriptionProps={{
          color: '#909296',
          size: 12
        }}
        subtitle="Question 3 of 3"
        children={
          <>
            <Space h={20} />
            {linkInputs}
            <Space h={20} />

            {numberOfLinks < 5 ? (
              <>
                <Button
                  label="+ Add link"
                  variant="outline"
                  onClick={() => {
                    if (numberOfLinks < 5) {
                      setNumberOfLinks(numberOfLinks + 1);
                    }
                  }}
                  underlined
                />
                <Space h={20} />
              </>
            ) : (
              <></>
            )}
          </>
        }
      />
      <Space h={18} />
      <Group position="center" spacing={60}>
        <Button label="Back" toLink="/step-six" variant="secondary" />
        <Button
          label="Submit"
          toLink="/finished"
          variant="primary"
          onClick={async () => {
            await showNotificationOnError(async () => {
              if (jwt === undefined) {
                throw new Error('Address is undefined');
              }

              await APIClient.updateUserQuestionThreeAnswer({
                answer: links.filter((link) => link !== ''),
                jwt
              });

              // get user
              const user = await APIClient.getUser({ jwt });
              setUser(user);

              // get status
              const status = await APIClient.getUserStage({ jwt });
              setStatus(status);

              navigate(routeBasedOnStage(status));
            });
          }}
        />
      </Group>
      <BottomSpacer />
    </Stack>
  );
}