import { createStyles, Group, Space, Stack } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PRIMARY_TEXT_COLOR } from '..';
import { APIClient } from '../api/ApiClient';
import { useUserContext } from '../contexts/UserContext';
import { Button } from '../stories/Button/Button';
import { StyledStepper } from '../stories/Stepper/Stepper';
import {
  StyledDialog,
  StyledTextArea
} from '../stories/StyledDialog/StyledDialog';
import { BottomSpacer, routeBasedOnStage, showNotificationOnError, TopSpacer } from '../utils';

const useStyles = createStyles(() => ({
  title: {
    fontFamily: 'JetBrains Mono',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: PRIMARY_TEXT_COLOR,
    maxWidth: '366px'
  },
  subtitle: {
    margin: '14px 26px',
    fontFamily: 'JetBrains Mono',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#909296',
    maxWidth: '330px'
  },
  outline: {}
}));

export function StepFiveView() {
  const { classes } = useStyles();
  const { setUser, user } = useUserContext();
  const [value, setValue] = useState(user ? user?.questionOneAnswer || '' : '');
  const navigate = useNavigate();
  const [jwt] = useLocalStorage({
    key: 'sw-jwt',
    defaultValue: ''
  });
  const [, setStatus] = useLocalStorage({
    key: 'sw-status',
    defaultValue: ''
  });

  return (
    <Stack align="center" spacing={0} className={classes.outline}>
      <TopSpacer />
      <StyledStepper step={2} />
      <Space h={41} />
      <StyledDialog
        title="What are you currently working on?"
        description="Tell us what you are building and what your role is in it."
        subtitle="Question 1 of 3"
        descriptionProps={{
          color: '#909296',
          size: 12
        }}
        children={
          <>
            <StyledTextArea placeholder="" setValue={setValue} value={value} />
            <Space h={20} />
          </>
        }
      />
      <Space h={18} />
      <Group position="center" spacing={60}>
        <Button label="Back" toLink="/step-four" variant="secondary" />
        <Button
          label="Next"
          toLink="/step-six"
          variant="secondary"
          onClick={async () => {
            await showNotificationOnError(async () => {
              if (jwt === undefined) {
                throw new Error('Address is undefined');
              }

              await APIClient.updateUserQuestionOneAnswer({
                answer: value,
                jwt
              });

              // get user
              const user = await APIClient.getUser({ jwt });
              setUser(user);

              // get status
              const status = await APIClient.getUserStage({ jwt });
              setStatus(status);

              navigate(routeBasedOnStage(status));
            });
          }}
        />
      </Group>
      <BottomSpacer />
    </Stack>
  );
}
