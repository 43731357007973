import React from 'react';
import { createRoot } from 'react-dom/client';
import { router } from './App';
import { RouterProvider } from 'react-router-dom';

export const DISCORD_AUTH_URL = process.env.REACT_APP_DISCORD_AUTH_URL!;
export const API_URL = process.env.REACT_APP_API_URL!;
export const APPLICATIONS_OPEN = process.env.REACT_APP_APPLICATIONS_OPEN! === 'true';
export const PRIMARY_COLOR = process.env.REACT_APP_PRIMARY_COLOR!;
export const PRIMARY_TEXT_COLOR = process.env.REACT_APP_PRIMARY_TEXT_COLOR!;
export const PRIMARY_BACKGROUND_COLOR = process.env.REACT_APP_BACKGROUND_COLOR!;
export const REDIRECT_TO_QUESTS = process.env.REACT_APP_REDIRECT_TO_QUESTS! === 'true';
export const RPC_URL = process.env.REACT_APP_RPC_URL!;

export const ENDPOINT_CONFIGS: IConfig[] = [
  {
    label: 'Helius',
    url: 'https://mainnet.helius-rpc.com/?api-key=1b9c8608-b054-4f30-ab1b-cdbbfaba6e5f',
    value: 'mainnet-1',
    network: 'mainnet-beta',
  },
  {
    label: 'HelloMoon',
    url: 'https://rpc.hellomoon.io/894f32e0-c057-4a6f-ae60-98ff49166ff2',
    value: 'mainnet-4',
    network: 'mainnet-beta',
  }
];
export type IConfig = {
    label: string;
    url: string;
    value: string;
    network: string;
  }

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<RouterProvider router={router} />);
