import { Space, Stack, Text } from '@mantine/core';
import { Navigate, useLocation } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { APIClient } from '../api/ApiClient';
import { useLocalStorage } from '@mantine/hooks';
import { BottomSpacer, TopSpacer } from '../utils';
import { parseJWT } from '../utils';
import { useUserContext } from '../contexts/UserContext';
import { StyledStepper } from '../stories/Stepper/Stepper';
import { StyledDialog } from '../stories/StyledDialog/StyledDialog';
import { Button } from '../stories/Button/Button';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export function DiscordCallbackView() {
  // get query params
  let query = useQuery();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [jwt] = useLocalStorage({
    key: 'sw-jwt',
    defaultValue: ''
  });
  const { setUser } = useUserContext();
  const [, setLSStatus] = useLocalStorage({
    key: 'sw-status',
    defaultValue: ''
  });

  // validate code and state are present as query params
  useMemo(() => {
    async function callback() {
      // send request to validate code
      const code = query.get('code');
      const state = query.get('state');

      try {
        const response = await APIClient.discordCallback(
          code as string,
          state as string
        );

        if (response.success) {
          if (jwt) {
            const parsedJwt = parseJWT(jwt);
            const address = parsedJwt.address as string;

            // update user
            const user = await APIClient.getUser({ address, jwt });
            setUser(user);

            // get status
            const status = await APIClient.getUserStage({ address, jwt });
            setLSStatus(status);
          } else {
            throw new Error('No JWT or user found');
          }
        } else {
          throw new Error(`Discord callback failed: ${response.message}`);
        }
      } catch (error: any) {
        setError(error.message);
      }

      setLoading(false);
    }

    if (jwt && query.get('code') && query.get('state')) {
      callback();
    } else if (query.get('error')) {
      setError(query.get('error_description'));
      setLoading(false);
      return;
    }
  }, [jwt]);

  if (
    ((query.get('code') === null || query.get('state') === null) &&
      query.get('error_description') === null) ||
    error
  ) {
    return (
      <Stack align="center" spacing={0}>
        <Space h={77} />
        <Text
          size="xl"
          align="center"
          style={{
            maxWidth: '500px'
          }}
        >
          Something went wrong. <br /> <br />
          Share error code 0x01D with our developers to let us debug what went
          wrong.
          {error ? <br /> : null}
          {error ? `Developer logs: ${error}` : null}
        </Text>
      </Stack>
    );
  } else if (loading) {
    return (
      <Stack align="center" spacing={0}>
        <TopSpacer />
        <StyledStepper step={1} />
        <Space h={41} />
        <StyledDialog
          title="We gotta make sure you’re a human, part 2"
          description="We only request access to verify your username. You can revoke access at anytime."
          descriptionProps={{
            color: '#909296',
            size: 12
          }}
          children={
            <>
              <Space h={30} />
              <Button
                {...{
                  label: 'Connecting with Discord',
                  variant: 'discord',
                  defaultIsLoading: true
                }}
              />
              <Space h={38} />
            </>
          }
        />
        <BottomSpacer />
      </Stack>
    );
  } else {
    return <Navigate to={'/status'} />;
  }
}
