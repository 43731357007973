import { Box, createStyles, Space, Stack, Text } from '@mantine/core';

const useStyles = createStyles(() => ({
  infoCard: {
    height: '138px',
    backgroundColor: 'rgba(20, 21, 23, 0.5)',
    borderRadius: '8px',
    padding: '0px 20px 0px',
    '@media (max-width: 600px)': {
      maxWidth: '100%',
      height: '100%',
      padding: '0px 20px 20px',
    },
    maxWidth: '300px',
    border: 'solid 1px black',
    '&:hover': {
      backgroundColor: 'rgba(255, 138, 173, 0.15)',
      border: 'solid 1px #ff8aad',
      cursor: 'pointer'
    }
  },
  infoCardTitle: {
    fontFamily: 'JetBrains Mono',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#f8f9fa'
  },
  infoCardDescription: {
    fontFamily: 'JetBrains Mono',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#909296'
  }
}));

export function InfoCard({
  title,
  description,
  alignText = 'center',
  onClick
}: InfoCardProps) {
  const { classes } = useStyles();

  return (
    <Box className={classes.infoCard} onClick={onClick}>
      <Stack align={alignText} spacing={0}>
        <Space h={20} />
        <Text align={alignText} className={classes.infoCardTitle}>
          {title}
        </Text>
        <Space h={14} />
        <Text align={alignText} className={classes.infoCardDescription}>
          {description}
        </Text>
      </Stack>
    </Box>
  );
}

export interface InfoCardProps {
  title: string;
  description: string;
  alignText?: 'start' | 'center' | 'right';
  onClick?: () => void;
}
