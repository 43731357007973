/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ActionIcon,
  Badge,
  createStyles,
  Divider,
  Group,
  Loader,
  Modal,
  Popover,
  Radio,
  Space,
  Stack,
  Text,
  TextInput,
  TextProps,
  Tooltip
} from '@mantine/core';
import { Button, ButtonVariant } from '../stories/Button/Button';
import { StyledDialog } from '../stories/StyledDialog/StyledDialog';
import { useWallet } from '@solana/wallet-adapter-react';
import { useEffect, useState } from 'react';
import { APIClient, QuestFilter } from '../api/ApiClient';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import {
  BottomSpacer,
  isJwtValid,
  parseJWT,
  routeBasedOnStage,
  showNotificationOnError,
  stageToBadgeColor,
  stageToIsInProgress,
  stageToStatusLabel,
  TopSpacer
} from '../utils';
import { useLocalStorage } from '@mantine/hooks';
import {
  IconBrandTwitter,
  IconBrandDiscord,
  IconRefresh,
  IconPlus,
  IconLink,
  IconEdit,
  IconCheck,
  IconCross,
  IconCrossOff,
  IconX,
  IconLoader,
  IconSettings
} from '@tabler/icons';
import { encode } from 'bs58';
import { APPLICATIONS_OPEN, DISCORD_AUTH_URL, ENDPOINT_CONFIGS, PRIMARY_TEXT_COLOR } from '..';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../contexts/UserContext';
export const useStyles = createStyles(() => ({
  title: {
    fontFamily: 'JetBrains Mono',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: PRIMARY_TEXT_COLOR,
    maxWidth: '366px'
  },
  subtitle: {
    margin: '14px 26px',
    fontFamily: 'JetBrains Mono',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#909296',
    maxWidth: '330px'
  },
  outline: {},
  statusLabel: {
    fontFamily: 'JetBrains Mono',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: PRIMARY_TEXT_COLOR
  },
  disabledStatusLabel: {
    fontFamily: 'JetBrains Mono',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    opacity: 0.5,
    color: PRIMARY_TEXT_COLOR
  },
  greenStatusLabel: {
    fontFamily: 'JetBrains Mono',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#92F7CB'
  },
  redStatusLabel: {
    fontFamily: 'JetBrains Mono',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#FA8AAD'
  },
  text: {
    fontFamily: 'JetBrains Mono',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'right',
    color: '#909296'
  },
  smallText: {
    fontFamily: 'JetBrains Mono',
    fontSize: '10px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#909296'
  },
  normalText: {
    fontFamily: 'JetBrains Mono',
    fontSize: '14px !important',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: PRIMARY_TEXT_COLOR
  },
  input: {
    height: 40,
    borderRadius: 0,
    border: '0.5px solid #ff8aad',
    backgroundColor: 'transparent',
    fontFamily: 'JetBrains Mono',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    padding: '0 0 0 20px',
    color: 'white'
  }
}));

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function StatusView() {
  const { classes } = useStyles();
  const { connected, publicKey, signMessage, disconnect } = useWallet();
  const { setVisible } = useWalletModal();
  const { setUser } = useUserContext();
  const [jwt, setJwt] = useLocalStorage<string>({
    key: 'sw-jwt',
    defaultValue: ''
  });

  const [status, setStatus] = useState<string | null>(null);
  const [discordRoleClaimed, setDiscordRoleClaimed] =
    useState<boolean | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [reloadData, setReloadData] = useState<boolean>(false);
  const [xp, setXp] = useState<number>(0);
  const [openQuests, setOpenQuests] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [wlSpotClaimed, setWlSpotClaimed] = useState<boolean>(false);
  const [presaleSpotClaimed, setPresaleSpotClaimed] = useState<boolean>(false);
  const [daoAndAccessCodeVerified, setDaoAndAccessCodeVerified] =
    useState<boolean>(false);
  const [dao, setDao] = useState<string | null>(null);
  const [accessCode, setAccessCode] = useState<string | null>(null);
  const [showDAOAccessCodeModal, setShowDAOAccessCodeModal] =
    useState<boolean>(false);
  const [twitterVerified, setTwitterVerified] = useState(false);
  const [discordVerified, setDiscordVerified] = useState(false);
  const [inRaffle, setInRaffle] = useState(false);
  const [isWarrior, setIsWarrior] = useState(false);
  const [wonRaffle, setWonRaffle] = useState(false);
  const [hasRaffleEnded, setHasRaffleEnded] = useState<boolean>(false);
  const [isWhitelistFull, setIsWhitelistFull] = useState<boolean>(false);
  const [twitterUrl, setTwitterUrl] = useState<string>('');
  const [discordRequestToken, setDiscordRequestToken] = useState<string>('');
  const [survivorRoleClaimed, setSurvivorRoleClaimed] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const isConnected = connected && publicKey !== null;
    const validJwt = isJwtValid(jwt);

    async function getStatus() {
      setLoading(true);
      const user = await APIClient.getUser({
        jwt,
        address: publicKey?.toBase58()
      });
      setUser(user);
      setStatus(user.status);
      setDiscordRoleClaimed(user.discordRoleClaimed);
      setTwitterVerified(user.twitterVerified);
      setDiscordVerified(user.discordVerified);
      setSurvivorRoleClaimed(user.discordSurivorRoleClaimed);
      setTwitterUrl(user.twitterAuthenticationLink);
      setDiscordRequestToken(user.discordRequestToken);
      setXp(user.xp);
      setDao(user.dao);
      setAccessCode(user.accessCode);
      setWlSpotClaimed(user.claimedWhitelistSpot);
      setPresaleSpotClaimed(user.claimedPresaleSpot);
      setDaoAndAccessCodeVerified(user.daoAndAccessCodeVerified);
      setInRaffle(user.enteredRaffle);
      setIsWarrior(user.status === 'APPROVED');
      const info = await APIClient.getUserRaffleInfo(jwt);
      if (info === null) {
        setHasRaffleEnded(false);
        setWonRaffle(false);
        setIsWhitelistFull(false);
      } else {
        setHasRaffleEnded(new Date(info.info.raffleEndsAt) < new Date());
        setWonRaffle(info.info.isWinner);
        setIsWhitelistFull(info.info.isWhitelistFull);
      }

      const questData = await APIClient.getAllQuests({
        jwt,
        address: publicKey?.toBase58(),
        showCompletedOnly: false,
        filter: QuestFilter.OPEN
      });
      setOpenQuests(questData.totalCount);
      setLoading(false);
      return user;
    }

    async function signIn() {
      // fetch nonce
      const nonce = await APIClient.getNonce(publicKey!.toBase58());

      // sign nonce
      if (signMessage) {
        const signature = await signMessage(new TextEncoder().encode(nonce));

        // save jwt
        const updatedJwt = await APIClient.login(
          publicKey!.toBase58(),
          encode(signature)
        );
        setJwt(updatedJwt);
      } else {
        console.log('No signMessage function found');
      }
    }

    if (isConnected && validJwt) {
      // validate jwt matches public key
      const claims = parseJWT(jwt);
      if (claims.address !== publicKey.toBase58()) {
        console.log(claims);
        console.log('jwt does not match connected wallet');
        signIn();
      } else {
        console.log('getting status');
        getStatus()
          .then()
          .catch((err: any) => {
            console.log('error getting status', err);
            signIn();
          });
      }
    } else if (isConnected && !validJwt) {
      console.log('signing in...');
      signIn();
    }
  }, [connected, publicKey, jwt, reloadData]);

  useEffect(() => {
    if (jwt) {
      const parsedJwt = parseJWT(jwt);
      const isAdmin = parsedJwt.admin as boolean;
      setIsAdmin(isAdmin);
    }
  }, [jwt]);

  const form = useForm({});
  let presaleLabel = presaleSpotClaimed
    ? 'Spot claimed'
    : daoAndAccessCodeVerified
      ? inRaffle
        ? hasRaffleEnded
          ? wonRaffle
            ? 'You won! Claim presale spot'
            : 'Didn\'t win raffle'
          : 'View DAO raffle'
        : 'Enter DAO raffle'
      : isWarrior || xp > 100
        ? 'Claim presale spot'
        : `You need ${100 - xp} XP more`;
  let presaleVariant: ButtonVariant = presaleSpotClaimed
    ? 'outline'
    : daoAndAccessCodeVerified
      ? inRaffle
        ? hasRaffleEnded
          ? wonRaffle
            ? 'discord-mini'
            : 'discord-mini'
          : 'discord-mini'
        : 'discord-mini'
      : isWarrior || xp > 100
        ? 'discord-mini'
        : 'outline';
  let presaleLinkLabel = presaleSpotClaimed ? 'Open' : '-';

  let wlLabel = 'N/A';
  if (connected) {
    if (isWarrior) {
      wlLabel = wlSpotClaimed ? 'Spot reserved' : 'Claim your spot';
    } else if (xp >= 50) {
      wlLabel = wlSpotClaimed ? 'Spot reserved' : 'Claim your spot';
    } else if (daoAndAccessCodeVerified) {
      if (isWhitelistFull) {
        wlLabel = wlSpotClaimed ? 'Spot reserved' : 'All spots claimed';
      } else {
        wlLabel = wlSpotClaimed ? 'Spot reserved' : 'Claim your spot (FCFS)';
      }
    } else {
      wlLabel = 'All spots claimed';
    }
  }
  const eligibleForWl =
    (connected &&
      (isWarrior ||
        xp >= 50 ||
        (daoAndAccessCodeVerified && !isWhitelistFull))) ||
    wlSpotClaimed;
  const eligibleForPresale =
    (connected && (isWarrior || xp >= 100 || wonRaffle)) || presaleSpotClaimed;

  return (
    <Stack align="center" spacing={0} className={classes.outline}>
      <Modal
        opened={showDAOAccessCodeModal}
        onClose={() => setShowDAOAccessCodeModal(false)}
        size="md"
        title={'Update DAO and access code'}
        centered
        styles={{
          modal: {
            backgroundColor: 'black',
            color: 'white',
            border: '1px solid #ff8aad',
            fontFamily: 'JetBrains Mono'
          },
          title: {
            fontFamily: 'JetBrains Mono'
          }
        }}
      >
        <form
          onSubmit={form.onSubmit(async (values) => {
            await showNotificationOnError(async () => {
              if (jwt) {
                console.log(values);
                await APIClient.updateUserDaoAccessCode({
                  jwt,
                  dao: values.daoname as string,
                  accessCode: values.accesscode as string
                });
              } else {
                throw new Error('Not logged in');
              }

              setShowDAOAccessCodeModal(false);
              setReloadData(!reloadData);
            });
          })}
        >
          <Group grow>
            <TextInput
              classNames={{
                label: classes.normalText,
                input: classes.input
              }}
              placeholder={'Enter name'}
              label="Name of your DAO"
              required
              {...form.getInputProps('daoname')}
            />
          </Group>
          <Space h={20} />
          <Group grow>
            <TextInput
              classNames={{
                label: classes.normalText,
                input: classes.input
              }}
              placeholder={'Enter access code'}
              label="Access code given by your DAO"
              required
              {...form.getInputProps('accesscode')}
            />
          </Group>
          <Space h={20} />
          <Group position="right">
            <Button label={'Submit'} props={{ type: 'submit' }} />
          </Group>
        </form>
      </Modal>
      <TopSpacer />
      <StyledDialog
        title="Your Eligibility"
        description={
          connected
            ? 'View if you are eligibile for a whitelist spot.'
            : 'Connect your wallet to see your eligibility.'
        }
        descriptionProps={{
          color: '#909296',
          size: 12
        }}
        children={
          <>
            <Space h={30} />
            <Group>
              <Button
                {...{
                  label: connected
                    ? `${publicKey?.toBase58().slice(0, 4)}...${publicKey
                      ?.toBase58()
                      .slice(-4)}`
                    : 'Select wallet',
                  variant: 'solana',
                  onClick: () => {
                    if (!connected) {
                      setVisible(true);
                    } else {
                      disconnect();
                    }
                  }
                }}
              />
              {connected && (
                <ActionIcon
                  onClick={() => setReloadData(!reloadData)}
                  variant="outline"
                  loading={loading}
                  radius={0}
                  color="fire-pink.0"
                  size={42.5}
                  sx={{
                    '&[data-loading]::before': {
                      backgroundColor: 'black',
                      zIndex: -1,
                      border: '1px solid #909296'
                    },
                    '&[data-loading]': {
                      backgroundColor: 'transparent !important',
                      border: '1px solid rgba(87, 87, 87, 1)'
                    }
                  }}
                  loaderProps={{
                    color: 'fire-pink.0',
                    style: {
                      backgroundColor: 'black',
                      border: 'none'
                    }
                  }}
                >
                  <IconRefresh size={18} />
                </ActionIcon>
              )}
            </Group>
            {connected ? (
              <>
                <Space h={28} />
                <Divider
                  size={1}
                  style={{
                    width: '100%',
                    borderColor: 'rgba(87, 87, 87, 0.48)'
                  }}
                />
                <Space h={28} />
                <Stack style={{ width: '100%' }}>
                  <Group
                    position="apart"
                    style={{ width: '100%' }}
                    spacing={12}
                  >
                    {/* Connect with wallet */}
                    <Group position="left" spacing="xs">
                      <ActionIcon
                        variant={connected ? 'filled' : 'transparent'}
                        size={connected ? 18 : 20}
                        color="green"
                        onClick={async () => {
                          if (!connected) {
                            setVisible(true);
                          }
                        }}
                      >
                        {connected ? (
                          <IconCheck size={14} stroke={4} color="black" />
                        ) : (
                          <IconLink size={20} stroke={2} color="white" />
                        )}
                      </ActionIcon>
                      <Text className={classes.statusLabel}>
                        Sign in with wallet
                      </Text>
                    </Group>

                    {/* Connect to Twitter */}
                    {/* <Group position="left" spacing="xs">
                      <ActionIcon
                        variant={twitterVerified ? 'filled' : 'transparent'}
                        color="green"
                        size={twitterVerified ? 18 : 20}
                        component="a"
                        href={twitterUrl}
                      >
                        {twitterVerified ? (
                          <IconCheck size={14} stroke={4} color="black" />
                        ) : (
                          <IconLink size={20} stroke={2} color="white" />
                        )}
                      </ActionIcon>
                      <Text className={classes.statusLabel}>
                        {'Connect to Twitter'}
                      </Text>
                    </Group> */}

                    {/* Connect to Discord */}
                    <Group position="left" spacing="xs">
                      <ActionIcon
                        variant={discordVerified ? 'filled' : 'transparent'}
                        color="green"
                        size={discordVerified ? 18 : 20}
                        component="a"
                        href={`${DISCORD_AUTH_URL}&state=${discordRequestToken}`}
                      >
                        {discordVerified ? (
                          <IconCheck size={14} stroke={4} color="black" />
                        ) : (
                          <IconLink size={20} stroke={2} color="white" />
                        )}
                      </ActionIcon>
                      <Text className={classes.statusLabel}>
                        {'Connect to Discord'}
                      </Text>
                    </Group>
                  </Group>

                  {/* Link DAO */}
                  {/* <Group position="left" spacing="xs">
                    <ActionIcon
                      variant={
                        daoAndAccessCodeVerified ? 'filled' : 'transparent'
                      }
                      color="green"
                      size={daoAndAccessCodeVerified ? 18 : 20}
                      onClick={() => {
                        setShowDAOAccessCodeModal(true);
                      }}
                    >
                      {daoAndAccessCodeVerified ? (
                        <IconCheck size={14} stroke={4} color="black" />
                      ) : daoAndAccessCodeVerified &&
                        hasRaffleEnded &&
                        !wonRaffle &&
                        !wlSpotClaimed &&
                        xp > 300 ? (
                          <IconEdit size={20} stroke={2} />
                        ) : (
                          <IconLink size={20} stroke={2} color="white" />
                        )}
                    </ActionIcon>
                    <Text className={classes.statusLabel}>
                      Link DAO (optional)
                    </Text>
                  </Group> */}

                  {/* Entered DAO raffle (only show if DAO linked) */}
                  {/* {daoAndAccessCodeVerified && (
                    <Group
                      position="left"
                      spacing="xs"
                      onClick={async () => {
                        if (inRaffle) {
                          navigate('/raffle');
                        } else {
                          await APIClient.enterRaffle(jwt);
                          setReloadData(!reloadData);
                        }
                      }}
                      sx={{ cursor: 'pointer' }}
                    >
                      <ActionIcon
                        variant={inRaffle ? 'filled' : 'transparent'}
                        color={inRaffle ? 'green' : 'white'}
                        size={inRaffle ? 18 : 20}
                      >
                        {inRaffle ? (
                          <IconCheck size={14} stroke={4} color="black" />
                        ) : (
                          <IconEdit size={20} stroke={2} color="white" />
                        )}
                      </ActionIcon>
                      <Text
                        className={classes.statusLabel}
                        underline={!hasRaffleEnded}
                      >
                        Enter DAO presale raffle
                      </Text>
                    </Group>
                  )} */}

                  {/* Eligible for presale */}
                  {/* <Group position="left" spacing="xs">
                    <ActionIcon
                      variant={eligibleForPresale ? 'filled' : 'transparent'}
                      color="green"
                      size={eligibleForPresale ? 18 : 20}
                      disabled={!eligibleForPresale}
                    >
                      {eligibleForPresale ? (
                        <IconCheck size={14} stroke={4} color="black" />
                      ) : (
                        <IconX size={20} stroke={4} color="red" />
                      )}
                    </ActionIcon>
                    <Tooltip
                      label={presaleLabel}
                      color="black"
                      withArrow
                      position="top"
                    >
                      <Text className={classes.statusLabel}>
                        Eligible for presale
                      </Text>
                    </Tooltip>
                  </Group> */}

                  {/* Eligible for WL */}
                  <Group position="left" spacing="xs">
                    <ActionIcon
                      variant={eligibleForWl && survivorRoleClaimed ? 'filled' : 'transparent'}
                      color="green"
                      size={20}
                      onClick={() => { }}
                    >
                      {eligibleForWl && survivorRoleClaimed ? (
                        <IconCheck size={14} stroke={4} color="black" />
                      ) : (
                        <IconX size={20} stroke={4} color="red" />
                      )}
                    </ActionIcon>
                    <Tooltip
                      label={wlLabel}
                      color="black"
                      position="bottom"
                      withArrow
                    >
                      <Text className={classes.statusLabel}>
                        Eligible for whitelist
                      </Text>
                    </Tooltip>
                  </Group>

                  {/* Claim Discord role */}
                  {/* <Group
                    position="left"
                    spacing="xs"
                    sx={{ cursor: 'pointer' }}
                    onClick={async () => {
                      if (publicKey && !discordRoleClaimed) {
                        await showNotificationOnError(async () => {
                          // fetch nonce
                          const nonce = await APIClient.getNonce(
                            publicKey.toBase58()
                          );

                          // sign nonce
                          if (signMessage) {
                            const signature = await signMessage(
                              new TextEncoder().encode(nonce)
                            );

                            // save jwt
                            const updatedJwt = await APIClient.login(
                              publicKey.toBase58(),
                              encode(signature)
                            );
                            setJwt(updatedJwt);

                            await APIClient.claimUserDiscordRole({
                              jwt: updatedJwt
                            });
                            setReloadData(!reloadData);
                          }
                        });
                      }
                    }}
                  >
                    {eligibleForPresale || eligibleForWl || isWarrior ? (
                      <ActionIcon
                        variant={discordRoleClaimed ? 'filled' : 'transparent'}
                        color="green"
                        size={discordRoleClaimed ? 18 : 20}
                        onClick={() => { }}
                      >
                        {discordRoleClaimed ? (
                          <IconCheck size={14} stroke={4} color="black" />
                        ) : (
                          <IconPlus size={20} stroke={4} color="white" />
                        )}
                      </ActionIcon>
                    ) : (
                      <ActionIcon
                        variant={'transparent'}
                        size={20}
                        disabled
                        sx={{ '&[data-disabled]': { opacity: 0.4 } }}
                      >
                        <IconLoader size={20} stroke={3} />
                      </ActionIcon>
                    )}
                    <Text
                      className={
                        eligibleForPresale || eligibleForWl || isWarrior
                          ? classes.statusLabel
                          : classes.disabledStatusLabel
                      }
                      underline={
                        (eligibleForPresale || eligibleForWl || isWarrior) &&
                        !discordRoleClaimed
                      }
                    >
                      {discordRoleClaimed
                        ? 'Warrior role claimed'
                        : 'Claim Warrior role'}
                    </Text>
                  </Group> */}

                  {/* Claim presale */}
                  {/* <Group
                    position="left"
                    spacing="xs"
                    sx={{ cursor: 'pointer' }}
                    onClick={async () => {
                      await showNotificationOnError(async () => {
                        if (
                          eligibleForPresale &&
                          !presaleSpotClaimed &&
                          publicKey
                        ) {
                          console.log('claiming presale spot');
                          await APIClient.claimPresaleSpot({
                            jwt,
                            address: publicKey.toBase58()
                          });
                          setReloadData(!reloadData);
                        }
                      });
                    }}
                  >
                    {eligibleForPresale ? (
                      <ActionIcon
                        variant={presaleSpotClaimed ? 'filled' : 'transparent'}
                        color="green"
                        size={presaleSpotClaimed ? 18 : 20}
                      >
                        {presaleSpotClaimed ? (
                          <IconCheck size={14} stroke={4} color="black" />
                        ) : (
                          <IconPlus size={20} stroke={4} color="white" />
                        )}
                      </ActionIcon>
                    ) : (
                      <ActionIcon
                        variant={'transparent'}
                        size={20}
                        disabled
                        sx={{ '&[data-disabled]': { opacity: 0.4 } }}
                      >
                        <IconLoader size={20} stroke={3} />
                      </ActionIcon>
                    )}
                    <Text
                      className={
                        eligibleForPresale
                          ? classes.statusLabel
                          : classes.disabledStatusLabel
                      }
                      underline={eligibleForPresale && !presaleSpotClaimed}
                    >
                      {presaleSpotClaimed
                        ? 'Presale spot claimed'
                        : 'Claim presale spot'}
                    </Text>
                  </Group> */}

                  {/* Claim WL */}
                  {/* <Group
                    position="left"
                    spacing="xs"
                    onClick={async () => {
                      if (
                        wlSpotClaimed === false &&
                        connected &&
                        publicKey &&
                        (xp > 50 || daoAndAccessCodeVerified || isWarrior) &&
                        !isWhitelistFull
                      ) {
                        await showNotificationOnError(async () => {
                          await APIClient.claimWlSpot({
                            jwt,
                            address: publicKey.toBase58()
                          });
                          setReloadData(!reloadData);
                        });
                      } else {
                        console.log({
                          wlSpotClaimed,
                          connected,
                          publicKey
                        });
                      }
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    {eligibleForWl ? (
                      <ActionIcon
                        variant={wlSpotClaimed ? 'filled' : 'transparent'}
                        color="green"
                        size={wlSpotClaimed ? 18 : 20}
                        onClick={() => { }}
                      >
                        {wlSpotClaimed ? (
                          <IconCheck size={14} stroke={4} color="black" />
                        ) : (
                          <IconPlus size={20} stroke={4} color="white" />
                        )}
                      </ActionIcon>
                    ) : (
                      <ActionIcon
                        variant={'transparent'}
                        size={20}
                        disabled
                        sx={{ '&[data-disabled]': { opacity: 0.4 } }}
                      >
                        <IconLoader size={20} stroke={3} />
                      </ActionIcon>
                    )}
                    <Text
                      className={
                        eligibleForWl
                          ? classes.statusLabel
                          : classes.disabledStatusLabel
                      }
                      underline={eligibleForWl && !wlSpotClaimed}
                    >
                      {wlSpotClaimed
                        ? 'Whitelist spot claimed'
                        : 'Claim whitelist spot'}
                    </Text>
                  </Group> */}

                  {/* Claim Discord role */}
                  {/* <Group
                    position="left"
                    spacing="xs"
                    sx={{ cursor: 'pointer' }}
                    onClick={async () => {
                      if (publicKey && !survivorRoleClaimed) {
                        await showNotificationOnError(async () => {
                          const nonce = await APIClient.getNonce(publicKey.toBase58());
                          if (signMessage) {
                            const signature = await signMessage(new TextEncoder().encode(nonce));
                            const updatedJwt = await APIClient.login(publicKey.toBase58(), encode(signature));
                            setJwt(updatedJwt);
                            await APIClient.claimSurvivorDiscordRole({ jwt: updatedJwt });
                            setReloadData(!reloadData);
                          }
                        });
                      }
                    }}
                  >
                    {wlSpotClaimed && survivorRoleClaimed ? (
                      <ActionIcon
                        variant={survivorRoleClaimed ? 'filled' : 'transparent'}
                        color="green"
                        size={survivorRoleClaimed ? 18 : 20}
                        onClick={() => { }}
                      >
                        {survivorRoleClaimed ? (
                          <IconCheck size={14} stroke={4} color="black" />
                        ) : (
                          <IconPlus size={20} stroke={4} color="white" />
                        )}
                      </ActionIcon>
                    ) : (
                      <ActionIcon
                        variant={'transparent'}
                        size={20}
                        disabled
                        sx={{ '&[data-disabled]': { opacity: 0.4 } }}
                      >
                        <IconLoader size={20} stroke={3} />
                      </ActionIcon>
                    )}
                    <Text
                      className={(wlSpotClaimed || isWarrior || survivorRoleClaimed) ? classes.statusLabel : classes.disabledStatusLabel}
                      underline={(wlSpotClaimed || isWarrior) && !survivorRoleClaimed}
                    >
                      {survivorRoleClaimed
                        ? 'Survivor role claimed'
                        : 'I MUST SURVIVE!'}
                    </Text>
                  </Group> */}
                </Stack>
                {/* <Space h={28} />
                <Divider
                  size={1}
                  style={{
                    width: '100%',
                    borderColor: 'rgba(87, 87, 87, 0.48)'
                  }}
                />
                <Space h={28} />
                <Stack style={{ width: '100%' }}>
                  <Group position="apart">
                    <Text className={classes.statusLabel}>Your XP:</Text>
                    <Text className={classes.text}>
                      {connected ? xp : 'N/A'}
                    </Text>
                  </Group>
                  <Group position="apart">
                    <Text className={classes.statusLabel}>Quests:</Text>
                    <Button
                      label={
                        connected
                          ? openQuests === 0
                            ? 'No available quests ⚔️'
                            : `${openQuests} quest${openQuests > 1 ? 's' : ''
                            } available`
                          : 'N/A'
                      }
                      variant={openQuests === 0 ? 'outline' : 'primary-mini'}
                      underlined={openQuests > 0}
                      toLink="/quests"
                    />
                  </Group>
                </Stack> */}
              </>
            ) : (
              <></>
            )}
            <Space h={40} />
          </>
        }
      />
      {connected && isAdmin ? (
        <>
          <Space h={18} />
          <Group position="center" spacing={60}>
            <Button
              label="See dashboard"
              toLink={'/bacon'}
              variant="outline"
              underlined
            />
          </Group>
        </>
      ) : connected && status !== null ? (
        stageToIsInProgress(status) ? (
          <>
            <Space h={18} />
          </>
        ) : (
          <>
            <Space h={18} />
          </>
        )
      ) : (
        <></>
      )}
      <Space h={18} />
      <Group>
        <ActionIcon
          variant="outline"
          color="fire-pink.0"
          radius={0}
          size="xl"
          component="a"
          href="https://twitter.com/sujiko_perps"
          target="_blank"
        >
          <IconBrandTwitter size={24} />
        </ActionIcon>
        <ActionIcon
          variant="outline"
          color="fire-pink.0"
          radius={0}
          size="xl"
          component="a"
          href="https://discord.com/invite/Qbd7yNcEPS"
          target="_blank"
        >
          <IconBrandDiscord size={24} />
        </ActionIcon>
      </Group>
      <BottomSpacer />
    </Stack>
  );
}

export interface OptionTextProps {
  fontSize: string;
  isBold?: boolean;
  color?: string;
  textAlign?: 'left' | 'center' | 'right';
  onHoverColor?: string;
}

const OptionText = (props: TextProps & OptionTextProps) => {
  return (
    <Text
      {...props}
      style={{
        fontFamily: 'JetBrains Mono',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        fontSize: props.fontSize ? props.fontSize : '12px',
        fontWeight: props.isBold ? 'bold' : 'normal',
        color: props.color ? props.color : '#d6d6d6',
        textAlign: props.textAlign ? props.textAlign : 'left',
        wordSpacing: '0.5px'
      }}
    />
  );
};

export default OptionText;
