/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { API_URL } from '..';

export class APIClient {
  static async api({
    path,
    id,
    method,
    urlParams,
    body,
    bearer
  }: {
    path: string;
    id?: string;
    method?: string;
    urlParams?: string[][];
    body?: any;
    bearer?: string;
  }) {
    const url = new URL(`${API_URL}/${path}`);
    if (id) url.searchParams.append('id', id);
    if (urlParams)
      urlParams.forEach((param) => url.searchParams.append(param[0], param[1]));
    const resp = await fetch(url.toString(), {
      method: method ? method : 'GET',
      headers: bearer
        ? {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${bearer}`
        }
        : {
          'Content-Type': 'application/json'
        },
      body: body ? JSON.stringify(body) : undefined
    });
    if (!resp.ok) {
      let result: any = { message: resp.statusText };
      try {
        result = await resp.json();
      } catch {}
      throw new Error(`${result.message} | ${resp.status} | ${path}`);
    } else {
      return await resp.json();
    }
  }

  static async getNonce(address: string) {
    const resp = await this.api({
      path: 'auth/nonce',
      method: 'GET',
      urlParams: [['address', address]]
    });

    if (resp.success) {
      if (resp.data.used) {
        return await this.updateNonce(address);
      } else {
        return resp.data.currentNonce;
      }
    } else {
      throw new Error(resp.message);
    }
  }

  static async login(address: string, signature: string) {
    return (
      await this.api({
        path: 'auth/login',
        method: 'POST',
        urlParams: [
          ['address', address],
          ['signature', signature]
        ]
      })
    ).data.jwt;
  }

  static async updateNonce(address: string) {
    return (
      await this.api({
        path: 'auth/nonce',
        method: 'POST',
        urlParams: [['address', address]]
      })
    ).data.currentNonce;
  }

  static async getUser({ address, jwt, updateTwitterTokens = false }: { address?: string; jwt: string; updateTwitterTokens?: boolean; }) {
    if (address) {
      return (
        await this.api({
          path: 'user',
          method: 'GET',
          urlParams: [
            ['updateTwitterTokens', updateTwitterTokens.toString()],
            ['address', address]
          ],
          bearer: jwt
        })
      ).data;
    } else {
      return (
        await this.api({
          path: 'user',
          method: 'GET',
          bearer: jwt,
          urlParams: [['updateTwitterTokens', updateTwitterTokens.toString()]]
        })
      ).data;
    }
  }

  static async getUserStage({
    address,
    jwt
  }: {
    address?: string;
    jwt?: string;
  }) {
    if (address) {
      return (
        await this.api({
          path: 'user/stage',
          method: 'GET',
          urlParams: [['address', address]],
          bearer: jwt
        })
      ).data.currentStage;
    } else {
      return (
        await this.api({
          path: 'user/stage',
          method: 'GET',
          bearer: jwt
        })
      ).data.currentStage;
    }
  }

  static async getUserDiscordRoleClaimed({
    address,
    jwt
  }: {
    address: string;
    jwt?: string;
  }) {
    if (address) {
      return (
        await this.api({
          path: 'user/discord/claimed',
          method: 'GET',
          urlParams: [['address', address]],
          bearer: jwt
        })
      ).data.hasRoleClaimed;
    } else {
      return (
        await this.api({
          path: 'user/discord/claimed',
          method: 'GET',
          bearer: jwt
        })
      ).data.hasRoleClaimed;
    }
  }

  static async updateTwitterRequestToken(address: string, jwt: string) {
    return (
      await this.api({
        path: 'auth/twitter/update/request-token',
        method: 'POST',
        urlParams: [['address', address]],
        bearer: jwt
      })
    ).data;
  }

  static async twitterCallback(oauthToken: string, oauthVerifier: string) {
    return await this.api({
      path: 'auth/twitter/callback',
      method: 'GET',
      urlParams: [
        ['oauth_token', oauthToken],
        ['oauth_verifier', oauthVerifier]
      ]
    });
  }

  static async discordCallback(code: string, state: string) {
    return await this.api({
      path: 'auth/discord/callback',
      method: 'GET',
      urlParams: [
        ['code', code],
        ['state', state]
      ]
    });
  }

  static async updateUserCategory({
    address,
    categoryId,
    jwt
  }: {
    address: string;
    categoryId: number;
    jwt: string;
  }) {
    return (
      await this.api({
        path: 'user/category',
        method: 'GET',
        urlParams: [
          ['address', address],
          ['categoryId', categoryId.toString()]
        ],
        bearer: jwt
      })
    ).data;
  }

  static async updateUserQuestionOneAnswer({
    answer,
    jwt
  }: {
    answer: string;
    jwt: string;
  }) {
    return (
      await this.api({
        path: 'user/question/one',
        method: 'POST',
        bearer: jwt,
        body: { answer }
      })
    ).data;
  }

  static async updateUserQuestionTwoAnswer({
    answer,
    jwt
  }: {
    answer: string;
    jwt: string;
  }) {
    return (
      await this.api({
        path: 'user/question/two',
        method: 'POST',
        bearer: jwt,
        body: { answer }
      })
    ).data;
  }

  static async updateUserQuestionThreeAnswer({
    answer,
    jwt
  }: {
    answer: string[];
    jwt: string;
  }) {
    return (
      await this.api({
        path: 'user/question/three',
        method: 'POST',
        bearer: jwt,
        body: { answer }
      })
    ).data;
  }

  static async approveUser({ address, jwt }: { address: string; jwt: string }) {
    return (
      await this.api({
        path: 'user/approve',
        method: 'POST',
        urlParams: [['address', address]],
        bearer: jwt
      })
    ).data;
  }
  
  static async approveUserOverride({ address, jwt }: { address: string; jwt: string }) {
    return (
      await this.api({
        path: 'user/approve/override',
        method: 'POST',
        urlParams: [['address', address]],
        bearer: jwt
      })
    ).data;
  }

  static async approveUsers({
    addresses,
    jwt
  }: {
    addresses: string[];
    jwt: string;
  }) {
    return (
      await this.api({
        path: 'user/approve/batch',
        method: 'POST',
        body: { addresses },
        bearer: jwt
      })
    ).data;
  }

  static async roleUserManually({ address, jwt }: { address: string; jwt: string }) {
    return (
      await this.api({
        path: 'user/role',
        method: 'POST',
        urlParams: [['address', address]],
        bearer: jwt
      })
    ).data;
  }


  static async rejectUser({ address, jwt }: { address: string; jwt: string }) {
    return (
      await this.api({
        path: 'user/reject',
        method: 'POST',
        urlParams: [['address', address]],
        bearer: jwt
      })
    ).data;
  }

  static async getAllUsersStats({ jwt }: { jwt: string }) {
    return (
      await this.api({
        path: 'users/all/stats',
        method: 'GET',
        bearer: jwt
      })
    ).data;
  }

  static async getAllUsers({
    jwt,
    page = 1,
    limit = 10,
    filter = 'all',
    sortBy = 'twitterFollowersCount',
    sortDirection = 'desc' as SortByDirection,
    searchFilter = '',
    queued = false,
    q1Only = false,
    q2Only = false,
    searchByQuestionOne = false,
    categoryId = '0',
    searchByAddress = false
  }: {
    jwt: string;
    page?: number;
    limit?: number;
    filter?: string;
    sortBy?: string;
    sortDirection?: SortByDirection;
    searchFilter?: string;
    queued?: boolean;
    q1Only?: boolean;
    q2Only?: boolean;
    searchByQuestionOne?: boolean;
    categoryId?: string;
    searchByAddress?: boolean;
  }) {
    return (
      await this.api({
        path: 'users/all',
        method: 'GET',
        bearer: jwt,
        urlParams: [
          ['page', page.toString()],
          ['limit', limit.toString()],
          ['filter', filter],
          ['sortBy', sortBy],
          ['sortDirection', sortDirection],
          ['handleFilter', searchFilter],
          ['queued', queued.toString()],
          ['hasQuestionOne', q1Only.toString()],
          ['hasQuestionTwo', q2Only.toString()],
          ['searchByQuestionOne', searchByQuestionOne.toString()],
          ['categoryId', categoryId],
          ['searchByAddress', searchByAddress.toString()]
        ]
      })
    ).data;
  }

  static async updateUserToAdmin({
    address,
    jwt
  }: {
    address: string;
    jwt: string;
  }) {
    return (
      await this.api({
        path: 'user/admin',
        method: 'POST',
        urlParams: [['address', address]],
        bearer: jwt
      })
    ).data;
  }

  static async pendingRejectUser({
    address,
    jwt
  }: {
    address: string;
    jwt: string;
  }) {
    return (
      await this.api({
        path: 'user/reject/pending',
        method: 'POST',
        urlParams: [['address', address]],
        bearer: jwt
      })
    ).data;
  }

  static async pendingRejectUsers({
    addresses,
    jwt
  }: {
    addresses: string[];
    jwt: string;
  }) {
    return (
      await this.api({
        path: 'user/reject/pending/batch',
        method: 'POST',
        body: { addresses },
        bearer: jwt
      })
    ).data;
  }

  static async claimUserDiscordRole({ jwt }: { jwt: string }) {
    return (
      await this.api({
        path: 'user/discord/claim',
        method: 'POST',
        bearer: jwt
      })
    ).data;
  }
  static async claimSurvivorDiscordRole({ jwt }: { jwt: string }) {
    return (
      await this.api({
        path: 'user/claim/survivor',
        method: 'POST',
        bearer: jwt
      })
    ).data;
  }

  static async processTweetQueue({ jwt }: { jwt: string }) {
    return (
      await this.api({
        path: 'queue/process',
        method: 'POST',
        bearer: jwt
      })
    ).data;
  }

  static async getQueueCount({ jwt }: { jwt: string }) {
    return (
      await this.api({
        path: 'queue/count',
        method: 'GET',
        bearer: jwt
      })
    ).data.count;
  }

  static async getAllUsersCount({ jwt }: { jwt: string }) {
    return (
      await this.api({
        path: 'users/all/count',
        method: 'GET',
        bearer: jwt
      })
    ).data.count;
  }

  static async getAllQuests({
    jwt,
    page = 1,
    limit = 10,
    filter = QuestFilter.ALL,
    sortByFilter = QuestSortByFilter.UPDATED_AT,
    sortByDirection = SortByDirection.ASC,
    titleContains = '',
    urlContains = '',
    verificationRequired = undefined,
    isCapped = undefined,
    address = undefined,
    showCompletedOnly = true
  }: {
    jwt: string;
    page?: number;
    limit?: number;
    filter?: string;
    sortByFilter?: string;
    sortByDirection?: SortByDirection;
    titleContains?: string;
    urlContains?: string;
    verificationRequired?: boolean;
    isCapped?: boolean;
    address?: string;
    showCompletedOnly?: boolean;
  }) {
    let params = [
      ['page', page.toString()],
      ['limit', limit.toString()],
      ['filter', filter],
      ['sortByFilter', sortByFilter],
      ['sortByDirection', sortByDirection],
      ['titleContains', titleContains],
      ['urlContains', urlContains],
      ['showCompletedOnly', showCompletedOnly.toString()]
    ];

    if (verificationRequired !== undefined) {
      params.push(['verificationRequired', verificationRequired.toString()]);
    }

    if (isCapped !== undefined) {
      params.push(['isCapped', isCapped.toString()]);
    }

    if (address !== undefined) {
      params.push(['address', address]);
    }
    
    return (
      await this.api({
        path: 'quests/all',
        method: 'GET',
        bearer: jwt,
        urlParams: params
      })
    ).data;
  }

  static async getAllQuestsAdmin({
    jwt,
    page = 1,
    limit = 10,
    filter = QuestFilter.ALL,
    sortByFilter = QuestSortByFilter.UPDATED_AT,
    sortByDirection = SortByDirection.ASC,
    titleContains = '',
    urlContains = '',
    verificationRequired = undefined,
    isCapped = undefined
  }: {
    jwt: string;
    page?: number;
    limit?: number;
    filter?: string;
    sortByFilter?: string;
    sortByDirection?: SortByDirection;
    titleContains?: string;
    urlContains?: string;
    verificationRequired?: boolean;
    isCapped?: boolean;
    address?: string;
  }) {
    let params = [
      ['page', page.toString()],
      ['limit', limit.toString()],
      ['filter', filter],
      ['sortByFilter', sortByFilter],
      ['sortByDirection', sortByDirection],
      ['titleContains', titleContains],
      ['urlContains', urlContains]
    ];

    if (verificationRequired !== undefined) {
      params.push(['verificationRequired', verificationRequired.toString()]);
    }

    if (isCapped !== undefined) {
      params.push(['isCapped', isCapped.toString()]);
    }
    
    return (
      await this.api({
        path: 'quests/all/admin',
        method: 'GET',
        bearer: jwt,
        urlParams: params
      })
    ).data;
  }

  static async createQuest({
    jwt,
    title,
    startAt,
    startTime,
    endAt,
    endTime,
    xp,
    url,
    requiresVerification,
    isRedeemableCapped,
    redeemableCount,
    questTypeId,
    entityId,
    platformId
  }: {
    jwt?: string;
    title: string;
    startAt: Date;
    startTime: Date;
    endAt: Date | null;
    endTime: Date | null;
    xp: number;
    url: string;
    requiresVerification: boolean;
    isRedeemableCapped: boolean;
    redeemableCount: number;
    questTypeId: number;
    entityId: string;
    platformId: number;
  }) {  
    return (
      await this.api({
        path: 'quest',
        method: 'POST',
        bearer: jwt,
        body: {
          title,
          startDate: startAt,
          startTime,
          endDate: endAt,
          endTime,
          xp,
          url,
          requiresVerification,
          isRedeemableCapped,
          maxRedeemable: redeemableCount,
          questType: questTypeId,
          platform: platformId,
          id: entityId
        }
      })
    ).data;
  }

  static async updateQuest({
    jwt,
    questId,
    title,
    startAt,
    startTime,
    endAt,
    endTime,
    xp,
    url,
    requiresVerification,
    isRedeemableCapped,
    redeemableCount,
    questTypeId,
    entityId,
    platformId
  }: {
    jwt?: string;
    questId: string;
    title: string;
    startAt: Date;
    startTime: Date;
    endAt: Date | null;
    endTime: Date | null;
    xp: number;
    url: string;
    requiresVerification: boolean;
    isRedeemableCapped: boolean;
    redeemableCount: number;
    questTypeId: number;
    entityId: string;
    platformId: number;
  }) {  
    return (
      await this.api({
        path: 'quest',
        method: 'PATCH',
        bearer: jwt,
        body: {
          questId,
          title,
          startDate: startAt,
          startTime,
          endDate: endAt,
          endTime,
          xp,
          url,
          requiresVerification,
          isRedeemableCapped,
          maxRedeemable: redeemableCount,
          questType: questTypeId,
          platform: platformId,
          id: entityId
        }
      })
    ).data;
  }

  static async deleteQuest({
    jwt,
    questId
  }: {
    jwt: string;
    questId: string;
  }) {
    return (
      await this.api({
        path: 'quest',
        method: 'DELETE',
        bearer: jwt,
        body: {
          id: questId
        }
      })
    ).data;
  }

  static async getAllQuestsCount(jwt: string) {
    return (
      await this.api({
        path: 'quests/all/count',
        method: 'POST',
        bearer: jwt
      })
    ).data;
  }

  static async submitQuest({
    jwt,
    questId,
  }: {
    jwt: string;
    questId: number;
  }) {
    return await this.api({
      path: 'quest/submit',
      method: 'POST',
      bearer: jwt,
      urlParams: [['questId', questId.toString()]]
    });
  }

  static async updateUserAddress({
    jwt,
    oldAddress,
    newAddress
  }: {
    jwt: string;
    oldAddress: string;
    newAddress: string;
  }) {
    return (
      await this.api({
        path: 'user/address',
        method: 'PUT',
        bearer: jwt,
        body: {
          oldAddress,
          newAddress
        }
      })
    ).data;
  }

  static async claimWlSpot({
    jwt,
    address,
  }: {
    jwt: string;
    address: string;
  }) {
    return (
      await this.api({
        path: 'user/claim/whitelist',
        method: 'POST',
        bearer: jwt,
        urlParams: [['address', address.toString()]]
      })
    ).data;
  }

  static async claimPresaleSpot({
    jwt,
    address,
  }: {
    jwt: string;
    address: string;
  }) {
    return (
      await this.api({
        path: 'user/claim/presale',
        method: 'POST',
        bearer: jwt,
        urlParams: [['address', address.toString()]]
      })
    ).data;
  }

  static async getCollabs({
    jwt,
    page,
    limit,
    sortBy,
    ordering,
    searchFilter
  }: {
    jwt: string;
    page: number;
    limit: number;
    sortBy: 'dao' | 'accessCode' | 'raffleEndsAt' | 'createdAt' | 'updatedAt' | 'totalWhitelistSpots' | 'redeemedWhitelistSpots' | 'totalPresaleSpots' | 'redeemedPresaleSpots' | 'redemptions';
    ordering: 'asc' | 'desc';
    searchFilter?: string;
  }) {
    return (
      await this.api({
        path: 'collabs',
        method: 'GET',
        bearer: jwt,
        urlParams: [
          ['page', page.toString()], 
          ['limit', limit.toString()],
          ['sortBy', sortBy],
          ['ordering', ordering],
          ['filter', searchFilter || '']
        ]
      })
    ).data;
  }

  static async createCollab({
    jwt,
    dao,
    accessCode,
    totalWhitelistSpots,
    totalPresaleSpots,
  }: {
    jwt: string;
    dao: string;
    accessCode: string;
    totalWhitelistSpots: number;
    totalPresaleSpots: number;
  }) {
    return (
      await this.api({
        path: 'collab',
        method: 'POST',
        bearer: jwt,
        body: {
          dao,
          accessCode,
          totalWhitelistSpots,
          totalPresaleSpots,
        }
      })
    ).data;
  }

  static async getCollabsInfo(jwt: string) {
    return (
      await this.api({
        path: 'collabs/info',
        method: 'GET',
        bearer: jwt,
      })
    ).data;
  }

  static async deleteDaoAccessCode({
    jwt,
    id
  }: {
    jwt: string;
    id: number;
  }) {
    return (
      await this.api({
        path: 'collab',
        method: 'DELETE',
        bearer: jwt,
        body: {
          id
        }
      })
    ).data;
  }

  static async updateUserDaoAccessCode({
    jwt,
    dao,
    accessCode,
  }: {
    jwt: string;
    dao: string;
    accessCode: string;
  }) {
    return (
      await this.api({
        path: 'user/dao',
        method: 'PUT',
        bearer: jwt,
        body: {
          daoName: dao,
          accessCode,
        }
      })
    ).data;
  }

  static async enterRaffle(jwt: string) {
    return (
      await this.api({
        path: 'user/dao/raffle',
        method: 'POST',
        bearer: jwt,
      })
    ).data;
  }

  static async getUserRaffleInfo(jwt: string) {
    return (
      await this.api({
        path: 'user/dao/raffle',
        method: 'GET',
        bearer: jwt,
      })
    ).data;
  }

  static async endRaffle({ jwt, id }: { jwt: string; id: number; }) {
    return (
      await this.api({
        path: 'collab/raffle',
        method: 'PUT',
        bearer: jwt,
        body: {
          id
        }
      })
    ).data;
  }

  static async extendRaffleByOneDay({ jwt, id }: { jwt: string; id: number; }) {
    return (
      await this.api({
        path: 'collab/raffle/extend',
        method: 'PUT',
        bearer: jwt,
        body: {
          id
        }
      })
    ).data;
  }

  static async resetDiscordForUser({ jwt }: { jwt: string; }) {
    return (
      await this.api({
        path: 'user/discord',
        method: 'DELETE',
        bearer: jwt,
      })
    ).data;
  }
}

export enum QuestFilter {
  ALL = 'all',
  OPEN = 'open',
  UPCOMING = 'upcoming',
  ENDED = 'ended'
}

export enum QuestSortByFilter {
  START_AT = 'start_at',
  END_AT = 'end_at',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  TITLE = 'title'
}

export enum SortByDirection {
  ASC = 'asc',
  DESC = 'desc'
}