import { createStyles, Stepper } from '@mantine/core';
import { useState } from 'react';
import { PRIMARY_TEXT_COLOR } from '../..';

const useStyles = createStyles(() => ({
  separator: {
    height: 2,
    width: '35px',
    border: '1px solid #707070',
    borderRadius: '2px',
    backgroundColor: 'transparent',
  },
  separatorActive: {
    height: 2,
    border: '1px solid #ff8aad',
    borderRadius: '2px',
    backgroundColor: 'transparent',
  },
}));

export function StyledStepper({ step }: { step: number }) {
  const { classes } = useStyles();
  const [active, setActive] = useState(step);

  return (
    <Stepper
      active={active}
      onStepClick={setActive}
      breakpoint="sm"
      classNames={classes}
      orientation='horizontal'
      styles={{
        stepLabel: {
          fontFamily: 'JetBrains Mono',
          fontSize: '14px',
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          color: PRIMARY_TEXT_COLOR
        },
        stepDescription: {
          fontFamily: 'JetBrains Mono',
          fontSize: '12px',
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          color: '#6a7386'
        },
        stepIcon: {
          border: 'solid 2px #ff8aad !important',
          backgroundColor: '#0f1012'
        },
        stepCompletedIcon: {
          backgroundColor: '#ff8aad',
          borderRadius: '50%', 
          color: 'black'
        }
      }}
    >
      <Stepper.Step
        label="First step"
        description="Create an account"
        allowStepSelect={false}
      />
      <Stepper.Step
        label="Second step"
        description="Verify yourself"
        allowStepSelect={false}
      />
      <Stepper.Step
        label="Final step"
        description="Answer questions"
        allowStepSelect={false}
      />
    </Stepper>
  );
}

interface IStep {
  label: string;
  description: string;
  loading?: boolean;
}

export function StyledStepperPresale({ step, steps }: { step: number; steps: IStep[] }) {
  const { classes } = useStyles();

  return (
    <Stepper
      active={step}
      breakpoint="xs"
      size='xs'
      classNames={classes}
      orientation='horizontal'
      styles={{
        stepLabel: {
          fontFamily: 'JetBrains Mono',
          fontSize: '12px',
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          color: PRIMARY_TEXT_COLOR
        },
        stepDescription: {
          fontFamily: 'JetBrains Mono',
          fontSize: '10px',
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          color: '#6a7386'
        },
        stepIcon: {
          border: 'solid 2px #ff8aad !important',
          backgroundColor: '#0f1012'
        },
        stepCompletedIcon: {
          backgroundColor: '#ff8aad',
          borderRadius: '50%', 
          color: 'black'
        },
        step: {
          padding: 0,
        },
        separator: {
          marginLeft: -2,
          marginRight: -2,
          height: 10,
        },
      }}
    >
      {steps.map((step, index) => (
        <Stepper.Step
          key={index}
          label={step.label}
          description={step.description}
          allowStepSelect={false}
          loading={step.loading}
          completedIcon='✓'
          size='xs'
        />
      ))}
    </Stepper>
  );
}
