import { Space, Stack, Text } from '@mantine/core';
import { Navigate, useLocation } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { APIClient } from '../api/ApiClient';
import { useLocalStorage } from '@mantine/hooks';
import { BottomSpacer, TopSpacer } from '../utils';
import { parseJWT } from '../utils';
import { useUserContext } from '../contexts/UserContext';
import { StyledStepper } from '../stories/Stepper/Stepper';
import { StyledDialog } from '../stories/StyledDialog/StyledDialog';
import { Button } from '../stories/Button/Button';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export function TwitterCallbackView() {
  // get query params
  let query = useQuery();
  const { setUser } = useUserContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [jwt] = useLocalStorage({
    key: 'sw-jwt',
    defaultValue: ''
  });
  const [, setLSStatus] = useLocalStorage({
    key: 'sw-status',
    defaultValue: ''
  });

  useMemo(() => {
    // validate oauth_token and oauth_verifier are present as query params
    async function callback() {
      // send request to validate oauth_token and oauth_verifier
      const verifier = query.get('oauth_verifier');
      const token = query.get('oauth_token');

      try {
        const response = await APIClient.twitterCallback(
          token as string,
          verifier as string
        );

        if (response.success) {
          if (jwt) {
            // get status
            const parsedJwt = parseJWT(jwt);
            const address = parsedJwt.address as string;
            console.log('address', address);

            // update user
            const user = await APIClient.getUser({ address, jwt });
            setUser(user);

            // get status
            const status = await APIClient.getUserStage({ address, jwt });
            setLSStatus(status);
          } else {
            throw new Error('No JWT or user found');
          }
        } else {
          throw new Error(`Twitter callback failed: ${response.message}`);
        }
      } catch (error: any) {
        setError(error.message);
      }

      setLoading(false);
    }

    if (jwt && query.get('oauth_verifier') && query.get('oauth_token')) {
      callback();
    }
  }, [jwt]);

  if (
    query.get('oauth_verifier') === null ||
    query.get('oauth_token') === null ||
    error
  ) {
    return (
      <Stack align="center" spacing={0}>
        <Space h={77} />
        <Text
          size="xl"
          align="center"
          style={{
            maxWidth: '500px'
          }}
        >
          Something went wrong. <br /> <br />
          Share error code 0x01T with our developers to let us debug what went
          wrong.
          {error ? <br /> : null}
          {error ? `Developer logs: ${error}` : null}
        </Text>
      </Stack>
    );
  } else if (loading) {
    return (
      <Stack align="center" spacing={0}>
        <TopSpacer />
        <StyledStepper step={1} />
        <Space h={41} />
        <StyledDialog
          title="We gotta make sure you’re a human, part 1"
          description="We only request access to verify your username. You can revoke access at anytime."
          descriptionProps={{
            color: '#909296',
            size: 12
          }}
          children={
            <>
              <Space h={30} />
              <Button
                {...{
                  label: 'Connecting with Twitter',
                  variant: 'twitter',
                  defaultIsLoading: true
                }}
              />
              <Space h={38} />
            </>
          }
        />
        <BottomSpacer />
      </Stack>
    );
  } else {
    return <Navigate to={'/status'} />;
  }
}
