import {
  Group,
  Stack,
  Title,
  createStyles,
  Loader,
  Table,
  Button as MantineButton,
  Badge,
  ActionIcon,
  Text,
  TextInput
} from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { APIClient } from '../api/ApiClient';
import {
  BottomSpacer,
  parseJWT,
  showNotificationOnError,
  stageToBadgeColor,
  stageToStatusLabel,
  TopSpacer
} from '../utils';
import { IconRefresh, IconArrowBack } from '@tabler/icons';
import { PRIMARY_TEXT_COLOR } from '..';
import { useStyles as useAdminStyles } from './AdminView';
import { Button } from '../stories/Button/Button';
import { PublicKey } from '@solana/web3.js';

const useStyles = createStyles(() => ({
  text: {
    fontFamily: 'JetBrains Mono',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#d6d6d6'
  },
  title: {
    fontFamily: 'JetBrains Mono',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: PRIMARY_TEXT_COLOR
  },
  root: {
    width: 400,
    height: 40,
    borderRadius: 0,
    backgroundColor: 'transparent',
    fontFamily: 'JetBrains Mono',
    fontSize: '10px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    padding: '0'
  },
  label: {
    fontFamily: 'JetBrains Mono',
    fontSize: '10px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#d6d6d6',
    height: '100%',
    borderRadius: 0,
    padding: '12px',
    border: '1px solid #2f3747'
  },
  labelActive: {
    fontFamily: 'JetBrains Mono',
    fontSize: '10px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    border: '1px solid #ff8aad',
    color: '#d6d6d6'
  },
  control: {
    fontFamily: 'JetBrains Mono',
    fontSize: '10px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#d6d6d6'
  },
  controlActive: {
    fontFamily: 'JetBrains Mono',
    fontSize: '10px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#d6d6d6'
  },
  active: {
    backgroundColor: 'transparent',
    borderRadius: 0,
    margin: 0,
    fontFamily: 'JetBrains Mono',
    fontSize: '10px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#d6d6d6'
  },
  normalText: {
    fontFamily: 'JetBrains Mono',
    fontSize: '12px !important',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: PRIMARY_TEXT_COLOR,
    maxWidth: '200px',
    wordWrap: 'break-word'
  },
}));

export const AdminUserView = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any | null>(null);
  const [reloadData, setReloadData] = useState(false);
  const [newAddress, setNewAddress] = useState('');

  const [jwt] = useLocalStorage({
    key: 'sw-jwt',
    defaultValue: ''
  });
  let { id } = useParams();
  const { classes } = useStyles();
  const { classes: adminClasses } = useAdminStyles();
  let navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      if (jwt) {
        const parsedJwt = parseJWT(jwt);
        const isAdmin = parsedJwt.admin as boolean;

        // throw error if not admin
        if (!isAdmin) {
          setError(true);
        } else {
          // TODO: load user from api
          console.log('user address', id);

          const user = await APIClient.getUser({
            address: id,
            jwt
          });

          setData(user);
        }
      } else {
        console.log('no jwt');

        if (!loading) {
          setError(true);
        }
      }

      setLoading(false);
    }

    fetchData();
  }, [jwt, reloadData]);

  // check user is admin

  if (loading) {
    return (
      <Stack align="center" spacing={0}>
        <TopSpacer />
        <Group>
          <Title order={1} className={classes.title}>
            User
          </Title>
          <Loader />
        </Group>
      </Stack>
    );
  } else if (error) {
    return <Navigate to={'/'} />;
  } else {
    return (
      <Stack align="center" spacing={0}>
        <TopSpacer />
        <Group>
          <Group align={'flex-start'}>
            <Group>
              <Title order={1} className={classes.title}>
                User Details
              </Title>
              <Badge
                children={stageToStatusLabel(data?.status, true)}
                color={stageToBadgeColor(data?.status, true)}
                variant="outline"
              />
              <ActionIcon
                onClick={() => setReloadData(!reloadData)}
                variant="outline"
              >
                <IconRefresh size={18} />
              </ActionIcon>
              <ActionIcon
                component={Link}
                to={'/bacon'}
                variant="outline"
              >
                <IconArrowBack size={18} />
              </ActionIcon>
            </Group>
            <Table>
              <thead>
                <tr>
                  <th className={classes.normalText}>Field</th>
                  <th className={classes.normalText}>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr key={data?.id}>
                  <td className={classes.normalText}>Address</td>
                  <td className={classes.normalText}>{data?.address}</td>
                </tr>
                <tr key={data?.id}>
                  <td className={classes.normalText}>Address verified</td>
                  <td className={classes.normalText}>
                    {data?.addressVerified ? '✅' : '❌'}
                  </td>
                </tr>
                <tr key={data?.id}>
                  <td className={classes.normalText}>Twitter verified</td>
                  <td className={classes.normalText}>
                    <Group>
                      {data?.twitterVerified ? '✅' : '❌'}
                      <Text>
                        {data?.twitterScreenName ? (
                          <a
                            href={`https://twitter.com/${data?.twitterScreenName}`}
                            target="_blank"
                          >{`@${data?.twitterScreenName}`}</a>
                        ) : (
                          <>No handle?</>
                        )}
                      </Text>
                    </Group>
                  </td>
                </tr>
                <tr key={data?.id}>
                  <td className={classes.normalText}>Followers</td>
                  <td className={classes.normalText}>
                    {data?.twitterVerified ? data.twitterFollowersCount : '-'}
                  </td>
                </tr>
                <tr key={data?.id}>
                  <td className={classes.normalText}>Following</td>
                  <td className={classes.normalText}>
                    {data?.twitterVerified ? data.twitterFollowingCount : '-'}
                  </td>
                </tr>
                <tr key={data?.id}>
                  <td className={classes.normalText}>Ratio ({'>'}1 better)</td>
                  <td className={classes.normalText}>
                    {data?.twitterVerified ? (data.twitterFollowersCount/data.twitterFollowingCount) : '-'}
                  </td>
                </tr>
                <tr key={data?.id}>
                  <td className={classes.normalText}>Discord verified</td>
                  <td className={classes.normalText}>
                    {data?.discordVerified ? '✅' : '❌'}
                  </td>
                </tr>
                <tr key={data?.id}>
                  <td className={classes.normalText}>Discord username</td>
                  <td className={classes.normalText}>
                    {data?.discordVerified ? data?.discordUsername : '-'}
                  </td>
                </tr>
                <tr key={data?.id}>
                  <td className={classes.normalText}>Discord user ID</td>
                  <td className={classes.normalText}>
                    {data?.discordVerified ? data?.discordUserId : '-'}
                  </td>
                </tr>
                <tr key={data?.id}>
                  <td className={classes.normalText}>Submitted?</td>
                  <td className={classes.normalText}>
                    {data?.submitted ? '✅' : '❌'}
                  </td>
                </tr>
                <tr key={data?.id}>
                  <td className={classes.normalText}>Updated at</td>
                  <td className={classes.normalText}>
                    {new Date(data?.updatedAt).toLocaleString()}
                  </td>
                </tr>
                <tr key={data?.id}>
                  <td className={classes.normalText}>Category</td>
                  <td className={classes.normalText}>
                    {data?.category} ({data?.categoryId})
                  </td>
                </tr>
                <tr key={data?.id}>
                  <td className={classes.normalText}>Q1 answer</td>
                  <td className={classes.normalText}>
                    {data?.questionOneAnswer}
                  </td>
                </tr>
                <tr key={data?.id}>
                  <td className={classes.normalText}>Q2 answer</td>
                  <td className={classes.normalText}>
                    {data?.questionTwoAnswer}
                  </td>
                </tr>
                <tr key={data?.id}>
                  <td className={classes.normalText}>Q3 answer</td>
                  <td className={classes.normalText}>
                    {data?.questionThreeAnswer}
                  </td>
                </tr>
                <tr key={data?.id}>
                  <td className={classes.normalText}>DAO name</td>
                  <td className={classes.normalText}>
                    {data?.dao}
                  </td>
                </tr>
                <tr key={data?.id}>
                  <td className={classes.normalText}>Access code</td>
                  <td className={classes.normalText}>
                    {data?.accessCode}
                  </td>
                </tr>
                <tr key={data?.id}>
                  <td className={classes.normalText}>WL spot claimed</td>
                  <td className={classes.normalText}>
                    {data?.claimedWhitelistSpot === false ? '❌' : '✅'}
                  </td>
                </tr>
                <tr key={data?.id}>
                  <td className={classes.normalText}>Presale spot claimed</td>
                  <td className={classes.normalText}>
                    {data?.claimedPresaleSpot === false ? '❌' : '✅'}
                  </td>
                </tr>
                <tr key={data?.id}>
                  <td className={classes.normalText}>Status</td>
                  <td className={classes.normalText}>
                    <Group>
                      <Badge
                        children={stageToStatusLabel(data?.status, true)}
                        color={stageToBadgeColor(data?.status, true)}
                        variant="outline"
                      />
                      <Text>{data?.stage}</Text>
                    </Group>
                  </td>
                </tr>
                <tr key={data?.id}>
                  <td className={classes.normalText}>
                    Update address to:
                  </td>
                  <td className={classes.normalText}>
                    <Group>
                      <TextInput
                        value={newAddress}
                        onChange={(event) =>
                          setNewAddress(event.currentTarget.value)
                        }
                        classNames={adminClasses}
                        placeholder="Enter new address"
                      />
                      <Button label='Update address' 
                        onClick={async () => {
                          await showNotificationOnError(async () => {
                            // validate new address is not blank or null and is valid
                            if (!newAddress || newAddress === '') {
                              throw new Error('New address cannot be blank');
                            }

                            try {
                              new PublicKey(newAddress);
                            } catch (error: any) {
                              throw new Error('Invalid address');
                            }
                            
                            const resp = await APIClient.updateUserAddress({
                              oldAddress: data?.address, 
                              newAddress, 
                              jwt
                            });
                            console.log(resp);
                            navigate(`/bacon/${newAddress}`);
                          })
                        }}
                      />
                    </Group>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Group position="apart" align="center">
              <MantineButton
                variant="outline"
                color="green"
                onClick={async () => {
                  await showNotificationOnError(async () => {
                    await APIClient.approveUser({
                      jwt,
                      address: data?.address
                    });
                  }, {
                    showOnSuccess: true,
                    message: 'User is now approved'
                  });
                  setReloadData(!reloadData);
                }}
              >
                APPROVE
              </MantineButton>
              <MantineButton
                variant="outline"
                color="green"
                onClick={async () => {
                  await showNotificationOnError(async () => {
                    await APIClient.approveUserOverride({
                      jwt,
                      address: data?.address
                    });
                  }, {
                    showOnSuccess: true,
                    message: 'User is now approved'
                  });
                  setReloadData(!reloadData);
                }}
              >
                APPROVE NOW
              </MantineButton>
              <MantineButton
                variant="outline"
                color="red"
                onClick={async () => {
                  await showNotificationOnError(async () => {
                    await APIClient.rejectUser({
                      jwt,
                      address: data?.address
                    });
                  }), {
                    showOnSuccess: true,
                    message: 'User is now rejected'
                  };
                  setReloadData(!reloadData);
                }}
              >
                REJECT
              </MantineButton>
              <MantineButton
                variant="outline"
                color="red"
                onClick={async () => {
                  await showNotificationOnError(async () => {
                    await APIClient.pendingRejectUser({
                      jwt,
                      address: data?.address
                    });
                  }, {
                    showOnSuccess: true,
                    message: 'User is now pending reject'
                  });
                  setReloadData(!reloadData);
                }}
              >
                PENDING REJECT
              </MantineButton>
              <MantineButton
                variant="outline"
                color="orange"
                onClick={async () => {
                  await showNotificationOnError(async () => {
                    await APIClient.updateUserToAdmin({
                      jwt,
                      address: data?.address
                    });
                  }, { 
                    showOnSuccess: true,
                    message: 'User is now an admin'
                  });
                  setReloadData(!reloadData);
                }}
              >
                MAKE ADMIN
              </MantineButton>
              <MantineButton
                variant="outline"
                color="blue"
                onClick={async () => {
                  await showNotificationOnError(async () => {
                    await APIClient.roleUserManually({
                      jwt,
                      address: data?.address
                    });
                  }, {
                    showOnSuccess: true,
                    message: 'User has been manually rolled'
                  });
                  setReloadData(!reloadData);
                }}
              >
                MANUALLY ROLE ON DISCORD
              </MantineButton>
            </Group>
          </Group>
        </Group>
        <BottomSpacer />
      </Stack>
    );
  }
};
