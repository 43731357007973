import { createStyles, Grid, Space, Stack, Text } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import { PRIMARY_TEXT_COLOR } from '..';
import { APIClient } from '../api/ApiClient';
import { useUserContext } from '../contexts/UserContext';
import { InfoCard } from '../stories/InfoCard/InfoCard';
import { StyledStepper } from '../stories/Stepper/Stepper';
import { BottomSpacer, parseJWT, routeBasedOnStage, showNotificationOnError, TopSpacer } from '../utils';

export const Categories = [
  {
    title: '👷‍♀️ Builder/Founder',
    description:
      'You have a vision and you do whatever it takes to make your vision a reality. For the glass chewers.',
    id: 1
  },
  {
    title: 'Brand Mascot',
    description:
      'You live and breathe brands. You want to represent Sujiko and turn the NFT into your entire brand.',
    id: 2
  },
  {
    title: 'Quality Content Creator',
    description:
      'You create content that is valuable to the community. Blogs, videos, podcasts.',
    id: 3
  },
  {
    title: '🧑‍🎨 Artist/Illustrator',
    description: 'You create art and want to collaborate with other artists.',
    id: 4
  },
  {
    title: 'Very Important Person',
    description: 'You have a large audience or you have a pretty cool job.',
    id: 5
  },
  {
    title: '24/7 Contributor',
    description:
      'You help people out. You spend your time on Twitter or Discord helping where you can.',
    id: 6
  },
  {
    title: 'Working in Web3',
    description:
      'You want to network with other industry professionals and learn from them.',
    id: 7
  },
  {
    title: 'Designer',
    description:
      'You love to create great user experiences. You have an eye for great design.',
    id: 8
  },
  {
    title: 'Meme Creator',
    description:
      'You make dope, funny and original memes. You make them often. You make them good.',
    id: 9
  },
  {
    title: 'Other',
    description:
      'You have a unique background that doesn’t fit into any of the these categories.',
    id: 10
  }
];

const useStyles = createStyles(() => ({
  title: {
    fontFamily: 'JetBrains Mono',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: PRIMARY_TEXT_COLOR,
    maxWidth: '366px'
  },
  subtitle: {
    margin: '14px 26px',
    fontFamily: 'JetBrains Mono',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#909296',
    maxWidth: '330px'
  }
}));

export function StepFourView() {
  const { classes } = useStyles();
  const { setUser } = useUserContext();
  const navigate = useNavigate();
  const [jwt] = useLocalStorage({
    key: 'sw-jwt',
    defaultValue: ''
  });
  const [, setStatus] = useLocalStorage({
    key: 'sw-status',
    defaultValue: ''
  });

  return (
    <Stack align="center" spacing={0}>
      <TopSpacer />
      <StyledStepper step={2} />
      <Space h={41} />
      <Text align="center" className={classes.title}>
        Choose the category that fits you
      </Text>
      <Space h={12} />
      <Text align="center" className={classes.subtitle}>
        This isn’t an exam, don’t overthink it. Just pick a category & LFG!
      </Text>
      <Space h={46} />
      <Grid gutter={20} justify="center" grow>
        {Categories.map((category) => (
          <Grid.Col xs={12} sm={6} md={4} lg={4} xl={3}>
            <InfoCard
              title={category.title}
              description={category.description}
              alignText="center"
              onClick={async () => {
                await showNotificationOnError(async () => {
                  if (jwt === undefined) {
                    throw new Error('Address is undefined');
                  }

                  const parsedJwt = parseJWT(jwt);
                  const address = parsedJwt.address as string;

                  await APIClient.updateUserCategory({
                    categoryId: category.id,
                    jwt,
                    address
                  });

                  // get user
                  const user = await APIClient.getUser({ address, jwt });
                  setUser(user);

                  // get status
                  const status = await APIClient.getUserStage({ address, jwt });
                  setStatus(status);

                  navigate(routeBasedOnStage(status));
                });
              }}
            />
          </Grid.Col>
        ))}
      </Grid>
      <BottomSpacer />
    </Stack>
  );
}
