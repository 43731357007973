import { createStyles, Footer, Space, Stack, Text } from '@mantine/core';
import { PRIMARY_BACKGROUND_COLOR, PRIMARY_TEXT_COLOR } from '../..';

export const useStyles = createStyles(() => ({
  hintText: {
    fontFamily: 'JetBrains Mono',
    fontSize: '10px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#575757'
  },
  footerText: {
    fontFamily: 'JetBrains Mono',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: PRIMARY_TEXT_COLOR
  },
  footer: {
    backgroundColor: PRIMARY_BACKGROUND_COLOR,
    borderTop: 'solid 1px rgba(87, 87, 87, 0.48)',
    height: '85px',
    paddingTop: '25px',
    '@media (max-width: 900px)': {
      height: '60px',
      paddingTop: '10px',
    },
  }
}));

export function StyledFooter() {
  const { classes } = useStyles();

  return (
    <Footer
      className={classes.footer}
      height={''}
    >
      <Stack align="center" spacing={0}>
        <Text className={classes.footerText}>
          Made with 💖 by Sujiko.
        </Text>
        <Space h={8} />
        <Text className={classes.hintText}>🍣 Summer 2023</Text>
      </Stack>
    </Footer>
  );
}
