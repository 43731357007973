import {
  Group,
  SegmentedControl,
  Stack,
  Title,
  Text,
  createStyles,
  Loader,
  Table,
  Pagination,
  ActionIcon,
  Badge,
  MediaQuery,
  Select,
  Avatar,
  TextInput,
  Checkbox,
  Space
} from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { APIClient, SortByDirection } from '../api/ApiClient';
import {
  parseJWT,
  showNotificationOnError,
  stageToBadgeColor,
  stageToStatusLabel,
  TopSpacer
} from '../utils';
import {
  IconAward,
  IconBan,
  IconEye,
  IconRefresh,
  IconShieldX,
  IconWindmill,
  IconSkull,
  IconCheck
} from '@tabler/icons';
import { Categories } from './StepFourView';
import { PRIMARY_TEXT_COLOR } from '..';

export const useStyles = createStyles(() => ({
  text: {
    fontFamily: 'JetBrains Mono',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#d6d6d6'
  },
  title: {
    fontFamily: 'JetBrains Mono',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: PRIMARY_TEXT_COLOR
  },
  root: {
    width: 220,
    height: 40,
    borderRadius: 0,
    backgroundColor: 'transparent',
    fontFamily: 'JetBrains Mono',
    fontSize: '10px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    padding: '0'
  },
  input: {
    height: 40,
    borderRadius: 0,
    border: '0.5px solid #ff8aad',
    backgroundColor: 'transparent',
    fontFamily: 'JetBrains Mono',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    padding: '0 0 0 20px',
    color: 'white'
  },
  dropdown: {
    backgroundColor: 'black',
    color: 'white'
  },
  item: {
    backgroundColor: 'transparent',
    color: 'white',
    '&:hover': {
      backgroundColor: '#2f3747'
    }
  },
  label: {
    fontFamily: 'JetBrains Mono',
    fontSize: '10px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#d6d6d6',
    height: '100%',
    borderRadius: 0,
    padding: '12px',
    border: '1px solid #2f3747'
  },
  labelActive: {
    fontFamily: 'JetBrains Mono',
    fontSize: '10px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    border: '1px solid #ff8aad',
    color: '#d6d6d6'
  },
  control: {
    fontFamily: 'JetBrains Mono',
    fontSize: '10px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#d6d6d6',
    borderStyle: 'none !important',
  },
  controlActive: {
    fontFamily: 'JetBrains Mono',
    fontSize: '10px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#d6d6d6',
    borderStyle: 'none !important',
  },
  active: {
    backgroundColor: 'transparent',
    borderRadius: 0,
    margin: 0,
    fontFamily: 'JetBrains Mono',
    fontSize: '10px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#d6d6d6'
  },
  normalText: {
    fontFamily: 'JetBrains Mono',
    fontSize: '14px !important',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: PRIMARY_TEXT_COLOR
  },
  link: {
    color: 'white'
  },
  tab: {
    backgroundColor: 'transparent',
    color: 'white',
    border: '1px solid #2f3747',
    padding: '12px 18px',
    cursor: 'pointer',
    fontFamily: 'JetBrains Mono',
    fontSize: '12px !important',
    display: 'flex',
    alignItems: 'center',

    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed'
    },

    '&:not(:first-of-type)': {
      borderLeft: 0
    },

    '&[data-active]': {
      border: '1px solid #ff8aad',
      color: 'white'
    }
  }
}));

export const AdminView = () => {
  const [categoryIdFilter, setCategoryIdFilter] = useState<string | null>('0');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filterValue, setFilterValue] = useState('pending');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(10);
  const [data, setData] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [queuedOnly, setQueuedOnly] = useState(false);
  const [q1Only, setQ1Only] = useState(false);
  const [q2Only, setQ2Only] = useState(false);
  const [searchByQ1, setSearchByQ1] = useState(false);
  const [searchByAddress, setSearchByAddress] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [sortByOrderValue, setSortByOrderValue] = useState('desc');
  const [stats, setStats] = useState({
    all: 0,
    submitted: 0,
    approved: 0,
    rejected: 0
  });
  const [queueSize, setQueueSize] = useState(0);
  const [sortByValue, setSortByValue] = useState<string | null>(
    'twitterFollowersCount'
  );
  const { classes } = useStyles();
  const [jwt] = useLocalStorage({
    key: 'sw-jwt',
    defaultValue: ''
  });

  useEffect(() => {
    async function fetchData() {
      setRefreshing(true);
      if (jwt) {
        const parsedJwt = parseJWT(jwt);
        const isAdmin = parsedJwt.admin as boolean;

        // throw error if not admin
        if (!isAdmin) {
          setError(true);
        } else {
          // load stats
          const stats = await APIClient.getAllUsersStats({ jwt });
          const allUsers = await APIClient.getAllUsersCount({ jwt });

          setStats({
            all: allUsers,
            submitted: stats.submitted,
            approved: stats.approved,
            rejected: stats.rejected
          });

          // load users
          const users = await APIClient.getAllUsers({
            jwt,
            page: currentPage,
            limit,
            filter: filterValue,
            sortBy: sortByValue || undefined,
            sortDirection: sortByOrderValue as SortByDirection,
            searchFilter: searchFilter || undefined,
            queued: queuedOnly,
            q1Only,
            q2Only,
            searchByQuestionOne: searchByQ1,
            categoryId: categoryIdFilter === '0' ? undefined : categoryIdFilter!,
            searchByAddress
          });
          const mappedUsers = users.users.map((x: any) => {
            return {
              id: x.id,
              address: x.address,
              isAdmin: x.isAdmin,
              addressVerified: x.addressVerified,
              createdAt: x.createdAt,
              updatedAt: x.updatedAt,
              twitterVerified: x.twitterVerified,
              twitterHandle: x.twitterScreenName,
              discordVerified: x.discordVerified,
              category: x.category,
              categoryId: x.categoryId,
              submitted: x.submitted,
              stage: x.stage,
              status: x.status,
              followers: x.twitterFollowersCount,
              following: x.twitterFollowingCount,
              questionOneAnswer: x.questionOneAnswer,
              questionTwoAnswer: x.questionTwoAnswer,
              imageUrl: x.twitterProfileImageUrl
            };
          });
          setData(mappedUsers);
          // load pagination data
          setCurrentPage(users.currentPage);
          setTotalPages(users.totalPages);

          const queued = await APIClient.getQueueCount({ jwt });
          setQueueSize(queued);
        }
      } else {
        console.log('no jwt');

        if (!loading) {
          setError(true);
        }
      }
      setLoading(false);
      setRefreshing(false);
    }

    fetchData();
  }, [
    jwt,
    currentPage,
    filterValue,
    reloadData,
    sortByValue,
    sortByOrderValue,
    searchFilter,
    queuedOnly,
    q1Only,
    q2Only,
    searchByQ1,
    categoryIdFilter
  ]);

  if (loading) {
    return (
      <Stack align="center" spacing={0}>
        <TopSpacer />
        <Group>
          <Title order={1} className={classes.title}>
            Dashboard
          </Title>
          <Loader />
        </Group>
      </Stack>
    );
  } else if (error) {
    return <Navigate to={'/'} />;
  } else {
    const rows = data.map((element: any) => {
      return (
        <tr key={element.id}>
          <td className={classes.normalText}>
            <Group position='apart' style={{ maxWidth: '600px' }}>
              <Stack align="center" spacing={'xs'}>
                {element.imageUrl ? (
                  <Avatar src={element.imageUrl} size="lg" />
                ) : (
                  <></>
                )}
                {element.twitterHandle ? (
                  <>
                    <a
                      href={`https://twitter.com/${element.twitterHandle}`}
                      target="_blank"
                      className={classes.link}
                    >{`@${element.twitterHandle}`}</a>{' '}
                    <Text>
                      {element.following}:{element.followers}
                    </Text>
                  </>
                ) : (
                  <>{element.address.slice(0, 6)}...</>
                )}
              </Stack>
              <Space h={18} />
              <Stack align="center" spacing={'xs'}>
                <Text>
                  {element.category} ({element.categoryId})
                </Text>
                <Badge
                  children={stageToStatusLabel(element?.status, true)}
                  color={stageToBadgeColor(element?.status, true)}
                  variant="outline"
                />
              </Stack>
              <Space h={18} />
              <Stack align="center" spacing={'xs'}>
                <Text>{element.address.slice(0, 8)}…</Text>
                <Text>{new Date(element.updatedAt).toLocaleString()}</Text>
                <Group>
                  <ActionIcon
                    component={Link}
                    variant="outline"
                    color="gray"
                    to={`${element.address}`}
                    target="_blank"
                  >
                    <IconEye size={18} />
                  </ActionIcon>
                  <ActionIcon
                    variant="outline"
                    color="green"
                    onClick={async () => {
                      await showNotificationOnError(async () => {
                        await APIClient.approveUser({
                          jwt,
                          address: element.address
                        });
                      });
                      setReloadData(!reloadData);
                    }}
                  >
                    <IconAward size={18} />
                  </ActionIcon>
                  <ActionIcon
                    variant="outline"
                    color="blue"
                    onClick={async () => {
                      await showNotificationOnError(async () => {
                        await APIClient.pendingRejectUser({
                          jwt,
                          address: element.address
                        });
                      });
                      setReloadData(!reloadData);
                    }}
                  >
                    <IconShieldX size={18} />
                  </ActionIcon>
                  <ActionIcon
                    variant="outline"
                    color="red"
                    onClick={async () => {
                      await showNotificationOnError(async () => {
                        await APIClient.rejectUser({
                          jwt,
                          address: element.address
                        });
                      });
                      setReloadData(!reloadData);
                    }}
                  >
                    <IconBan size={18} />
                  </ActionIcon>
                </Group>
              </Stack>
              <Space h={18} />
            </Group>
          </td>
          <MediaQuery smallerThan={'lg'} styles={{ display: 'none' }}>
            <td className={classes.normalText} style={{ width: '400px' }}>
              {element.questionOneAnswer}
            </td>
          </MediaQuery>
          {q2Only ? (
            <MediaQuery smallerThan={'lg'} styles={{ display: 'none' }}>
              <td className={classes.normalText} style={{ maxWidth: '100px' }}>
                {element.questionTwoAnswer}
              </td>
            </MediaQuery>
          ) : (
            <></>
          )}
        </tr>
      );
    });

    return (
      <Group>
        <Group align={'flex-start'}>
          <Stack align="flex-start" spacing={'xs'}>
            <Group>
              <Title order={1} className={classes.title}>
                Dashboard
              </Title>
              <Stack spacing={'xs'} align="flex-start">
                <Text className={classes.text}>
                  {stats.all} applications
                  <br />
                  {stats.submitted} submitted
                  <br />
                  {stats.approved} approved
                  <br />
                  {stats.rejected} rejected
                  <br />
                  {queueSize} queued
                </Text>
              </Stack>
              <Stack spacing={'xs'} align="flex-start">
                <SegmentedControl
                  data={[
                    { label: 'All', value: 'all' },
                    { label: 'New', value: 'pending' },
                    { label: 'Approved', value: 'approved' },
                    { label: 'Rejected', value: 'rejected' },
                    { label: 'Rejected (P)', value: 'rejected-p' }
                  ]}
                  transitionDuration={250}
                  transitionTimingFunction="linear"
                  color={'blue'}
                  classNames={classes}
                  value={filterValue}
                  onChange={setFilterValue}
                  style={{
                    width: '340px'
                  }}
                />
                <Group>
                  <Select
                    data={[
                      { value: 'twitterFollowersCount', label: 'Followers' },
                      { value: 'twitterFollowingCount', label: 'Following' },
                      { value: 'createdAt', label: 'Created at' },
                      { value: 'updatedAt', label: 'Updated at' },
                      { value: 'submittedAt', label: 'Submitted at' }
                    ]}
                    value={sortByValue}
                    onChange={setSortByValue}
                    classNames={classes}
                  />
                  <SegmentedControl
                    data={[
                      { label: 'Asc', value: 'asc' },
                      { label: 'Desc', value: 'desc' }
                    ]}
                    transitionDuration={250}
                    transitionTimingFunction="linear"
                    color={'blue'}
                    classNames={classes}
                    style={{
                      width: '100px'
                    }}
                    value={sortByOrderValue}
                    onChange={setSortByOrderValue}
                  />
                </Group>
              </Stack>
              <Stack spacing={'xs'} align="flex-start">
                <Group>
                  <TextInput
                    value={searchFilter}
                    onChange={(event) =>
                      setSearchFilter(event.currentTarget.value)
                    }
                    classNames={classes}
                    placeholder="Search by @handle"
                  />
                  <Checkbox
                    checked={searchByQ1}
                    onChange={(event) =>
                      setSearchByQ1(event.currentTarget.checked)
                    }
                    label="Search by Q1"
                    classNames={{
                      label: classes.normalText
                    }}
                  />
                </Group>
                <Group>
                  <Group>
                    <Select
                      data={[
                        {
                          value: '0',
                          label: 'All'
                        },
                        ...Categories.map((x) => {
                          return {
                            value: x.id.toString(),
                            label: x.title
                          };
                        })
                      ]}
                      value={categoryIdFilter}
                      onChange={setCategoryIdFilter}
                      classNames={classes}
                    />
                    <ActionIcon
                      onClick={() => setReloadData(!reloadData)}
                      variant="outline"
                      loading={refreshing}
                      radius={0}
                      color="fire-pink.0"
                      size={40}
                    >
                      <IconRefresh size={18} />
                    </ActionIcon>
                    <ActionIcon
                      onClick={async () => {
                        await showNotificationOnError(async () => {
                          await APIClient.processTweetQueue({ jwt });
                        });
                      }}
                      variant="outline"
                      radius={0}
                      color="fire-pink.0"
                      size={40}
                    >
                      <IconWindmill size={18} />
                    </ActionIcon>
                    <ActionIcon
                      onClick={async () => {
                        await showNotificationOnError(async () => {
                          await APIClient.approveUsers({ 
                            jwt,
                            addresses: data.map((x: any) => x.address)
                          });
                          setReloadData(!reloadData);
                        });
                      }}
                      variant="outline"
                      radius={0}
                      color="fire-pink.0"
                      size={40}
                    >
                      <IconCheck size={18} />
                    </ActionIcon>
                    <ActionIcon
                      onClick={async () => {
                        await showNotificationOnError(async () => {
                          await APIClient.pendingRejectUsers({
                            jwt,
                            addresses: data.map((x: any) => x.address)
                          });
                          setReloadData(!reloadData);
                        });
                      }}
                      variant="outline"
                      radius={0}
                      color="red"
                      size={40}
                    >
                      <IconSkull size={18} />
                    </ActionIcon>
                  </Group>
                </Group>
              </Stack>
              <Stack spacing={'xs'} align="flex-start">
                <Checkbox
                  checked={queuedOnly}
                  onChange={(event) =>
                    setQueuedOnly(event.currentTarget.checked)
                  }
                  label="Show queued only"
                  classNames={{
                    label: classes.normalText
                  }}
                />
                <Checkbox
                  checked={q1Only}
                  onChange={(event) => setQ1Only(event.currentTarget.checked)}
                  label="Show Q1 answered"
                  classNames={{
                    label: classes.normalText
                  }}
                />
                <Checkbox
                  checked={q2Only}
                  onChange={(event) => setQ2Only(event.currentTarget.checked)}
                  label="Show Q2 answered"
                  classNames={{
                    label: classes.normalText
                  }}
                />
              </Stack>
              <Stack spacing={'xs'} align="flex-start">
                <Checkbox
                  checked={searchByAddress}
                  onChange={(event) =>
                    setSearchByAddress(event.currentTarget.checked)
                  }
                  label="Search by address"
                  classNames={{
                    label: classes.normalText
                  }}
                />
              </Stack>
            </Group>
            <Group align="center"></Group>
            <Pagination
              total={totalPages}
              page={currentPage}
              onChange={setCurrentPage}
              styles={() => ({
                item: {
                  '&[data-active]': {
                    border: '1px solid #ff8aad',
                    backgroundColor: 'transparent'
                  },
                  backgroundColor: 'transparent',
                  color: 'white',
                  border: '1px solid #d6d6d6'
                }
              })}
              siblings={3}
            />
          </Stack>
          <Table>
            <thead>
              <tr>
                <th className={classes.normalText}>Address</th>
                <MediaQuery smallerThan={'lg'} styles={{ display: 'none' }}>
                  <th className={classes.normalText}>Q1</th>
                </MediaQuery>
                {q2Only ? (
                  <MediaQuery smallerThan={'lg'} styles={{ display: 'none' }}>
                    <th className={classes.normalText}>Q2</th>
                  </MediaQuery>
                ) : <></>}
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
          <Group align="center">
            <Pagination
              total={totalPages}
              page={currentPage}
              onChange={setCurrentPage}
              styles={() => ({
                item: {
                  '&[data-active]': {
                    border: '1px solid #ff8aad',
                    backgroundColor: 'transparent'
                  },
                  backgroundColor: 'transparent',
                  color: 'white',
                  border: '1px solid #d6d6d6'
                }
              })}
              siblings={3}
            />
          </Group>
        </Group>
      </Group>
    );
  }
};
