import { Space, Stack } from '@mantine/core';
import { APIClient } from '../api/ApiClient';
import { StyledStepper } from '../stories/Stepper/Stepper';
import { StyledDialog } from '../stories/StyledDialog/StyledDialog';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import { encode } from 'bs58';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../contexts/UserContext';
import { useLocalStorage } from '@mantine/hooks';
import { BottomSpacer, routeBasedOnStage, TopSpacer } from '../utils';
import { Button } from '../stories/Button/Button';

export function StepOneView() {
  const { setVisible } = useWalletModal();
  const { connected, publicKey, signMessage, connecting } = useWallet();
  const navigate = useNavigate();
  const { setUser } = useUserContext();
  const [, setJwt] = useLocalStorage({
    key: 'sw-jwt',
    defaultValue: ''
  });
  const [, setAddress] = useLocalStorage({
    key: 'sw-address',
    defaultValue: ''
  });
  const [, setStatus] = useLocalStorage({
    key: 'sw-status',
    defaultValue: ''
  });

  return (
    <Stack align="center" spacing={0}>
      <TopSpacer />
      <StyledStepper step={0} />
      <Space h={41} />
      <StyledDialog
        title="Start your application"
        description="Create your application by signing in with your Solana wallet. You can only use one wallet for your application."
        descriptionProps={{
          color: '#909296',
          size: 12
        }}
        children={
          <>
            <Space h={30} />
            <Button
              {...{
                label: connected
                  ? 'Sign in with Solana'
                  : connecting ? 'Connecting wallet...' : 'Select wallet',
                variant: 'solana',
                onClick: async () => {
                  if (connected && publicKey) {
                    // fetch nonce
                    const nonce = await APIClient.getNonce(
                      publicKey.toBase58()
                    );

                    // sign nonce
                    if (signMessage) {
                      const signature = await signMessage(
                        new TextEncoder().encode(nonce)
                      );

                      // save jwt
                      const jwt = await APIClient.login(
                        publicKey.toBase58(),
                        encode(signature)
                      );
                      setJwt(jwt);
                      setAddress(publicKey.toBase58());

                      // get user
                      // save user
                      const user = await APIClient.getUser({
                        address: publicKey.toBase58(),
                        jwt
                      });

                      setUser(user);

                      // get status
                      const status = await APIClient.getUserStage({
                        address: publicKey.toBase58(),
                        jwt
                      });
                      setStatus(status);
                      navigate(routeBasedOnStage(status));
                    } else {
                      throw new Error('signMessage is undefined');
                    }
                  } else {
                    // connect wallet
                    setVisible(true);
                  }
                }
              }}
            />
            <Space h={38} />
          </>
        }
      />
      <BottomSpacer />
    </Stack>
  );
}

