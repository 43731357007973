import { Divider } from '@mantine/core';


export const StyledDivider = () => {
  return (
    <Divider
      size={1}
      style={{
        width: '100%',
        borderColor: 'rgba(87, 87, 87, 0.48)'
      }} />
  );
};
