import { Space, Stack } from '@mantine/core';
import { Navigate } from 'react-router-dom';
import { DISCORD_AUTH_URL } from '..';
import { useUserContext } from '../contexts/UserContext';
import { Button } from '../stories/Button/Button';
import { StyledStepper } from '../stories/Stepper/Stepper';
import { StyledDialog } from '../stories/StyledDialog/StyledDialog';
import { BottomSpacer, routeBasedOnStage, TopSpacer } from '../utils';

export function StepThreeView() {
  const { user } = useUserContext();

  if (user === null) {
    return <Navigate to={routeBasedOnStage('NOT_CREATED')} />;
  }

  return (
    <Stack align="center" spacing={0}>
      <TopSpacer />
      <StyledStepper step={1} />
      <Space h={41} />
      <StyledDialog
        title="We gotta make sure you’re a human, part 2"
        description="We only request access to verify your username. You can revoke access at anytime."
        descriptionProps={{
          color: '#909296',
          size: 12
        }}
        children={
          <>
            <Space h={30} />
            <Button
              {...{
                label: 'Connect with Discord',
                variant: 'discord',
                toLink: `${DISCORD_AUTH_URL}&state=${user.discordRequestToken}`,
                external: true
              }}
            />
            <Space h={38} />
          </>
        }
      />
      <BottomSpacer />
    </Stack>
  );
}
