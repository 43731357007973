import {
  Group,
  Stack,
  Title,
  Text,
  SegmentedControl,
  Select,
  TextInput,
  ActionIcon,
  Loader,
  Pagination,
  Table,
  Box,
  Modal,
  Space,
  NumberInput,
  Checkbox,
  Divider,
  Badge,
  Button as MantineButton
} from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { useStyles } from './AdminView';
import {
  IconRefresh,
  IconPlus,
  IconExternalLink,
  IconBan,
  IconEdit
} from '@tabler/icons';
import { parseJWT, showNotificationOnError, TopSpacer } from '../utils';
import { Navigate } from 'react-router-dom';
import { DatePicker, TimeInput } from '@mantine/dates';
import { Button } from '../stories/Button/Button';
import { useForm } from '@mantine/form';
import { APIClient, SortByDirection } from '../api/ApiClient';

interface EnrichedQuest {
  id: number
  title: string
  createdAt: string
  updatedAt: string
  startAt: string
  endAt: string | null
  xp: number
  url: string
  requiresVerification: boolean
  isRedeemaleCapped: boolean
  redeemableCount: number
  redeemed: number
  questTypeId: number
  questMetaId: number
  meta: {
    discordChannelId: string | null
    discordMessageId: string | null
    discordGuildId: string | null
    twitterAccountId: string | null
    twitterPostId: string | null
    id: number
  }
  type: {
    id: number
    title: string
    value: string
    questPlatformId: number
    platform: {
      id: number
      title: string
      value: string
      iconUrl: string
    }
  }
}

export const QuestsView = () => {
  const [filterValue, setFilterValue] = useState('all');
  const [sortByOrderValue, setSortByOrderValue] = useState('desc');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [searchBy, setSearchBy] = useState<string | null>('title');
  const [sortByValue, setSortByValue] = useState<string | null>('created_at');
  const [refreshing, setRefreshingData] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(10);
  const [data, setData] = useState<any[]>([]);
  const [modalOpened, setModalOpened] = useState(false);
  const [stats, setStats] = useState({
    total: 0,
    open: 0,
    upcoming: 0,
    completed: 0,
    totalXp: 0
  });
  const { classes } = useStyles();
  const [jwt] = useLocalStorage({
    key: 'sw-jwt',
    defaultValue: ''
  });
  const [editQuest, setEditQuest] = useState<EnrichedQuest | null>(null);

  // fetch constants
  useEffect(() => {}, []);

  // fetch API data
  useEffect(() => {
    async function fetchData() {
      setRefreshingData(true);

      if (jwt) {
        const parsedJwt = parseJWT(jwt);
        const isAdmin = parsedJwt.admin as boolean;

        if (!isAdmin) {
          setError(true);
        } else {
          // load stats
          let statData = await APIClient.getAllQuestsCount(jwt);

          setStats({
            ...stats,
            total: statData.count,
            totalXp: statData.totalXp
          });

          // load data from API
          let data = await APIClient.getAllQuestsAdmin({
            jwt,
            page: currentPage,
            limit,
            filter: filterValue,
            sortByFilter: sortByValue ?? undefined,
            sortByDirection: sortByOrderValue as SortByDirection,
            titleContains: searchBy === 'title' ? searchFilter : undefined,
            urlContains: searchBy === 'url' ? searchFilter : undefined
          });

          setData(data.quests);
          setTotalPages(data.totalPages);
        }
      } else {
        console.log('no jwt');

        if (!loading) {
          setError(true);
        }
      }

      setLoading(false);
      setRefreshingData(false);
    }

    fetchData();
  }, [
    jwt,
    currentPage,
    limit,
    filterValue,
    sortByValue,
    sortByOrderValue,
    searchFilter,
    searchBy,
    reloadData
  ]);

  if (loading) {
    return (
      <Stack align="center" spacing={0}>
        <TopSpacer />
        <Group>
          <Title order={1} className={classes.title}>
            Dashboard
          </Title>
          <Loader />
        </Group>
      </Stack>
    );
  } else if (error) {
    return <Navigate to={'/'} />;
  } else {
    const rows = data.map((row: EnrichedQuest) => {
      return (
        <tr key={row.id}>
          <td className={classes.normalText}>{row.id}</td>
          <td className={classes.normalText}>
            <Stack align="center">
              <Box>{row.title}</Box>
              <Group>
                <ActionIcon
                  variant="outline"
                  color="red"
                  onClick={async () => {
                    await showNotificationOnError(async () => {
                      if (jwt) {
                        await APIClient.deleteQuest({
                          jwt,
                          questId: row.id.toString()
                        });
                      } else {
                        throw new Error('Invalid JWT, please refresh the page');
                      }

                      setReloadData(!reloadData);
                    });
                  }}
                >
                  <IconBan size={18} />
                </ActionIcon>
                <ActionIcon
                  variant="outline"
                  color="yellow"
                  onClick={() => {
                    setEditQuest(row);
                    setModalOpened(true);
                  }}
                >
                  <IconEdit size={18} />
                </ActionIcon>
                <MantineButton
                  component="a"
                  href={row.url}
                  target={'_blank'}
                  rel={'noreferrer'}
                  leftIcon={<IconExternalLink size={14} />}
                  variant="outline"
                >
                  Link
                </MantineButton>
              </Group>
            </Stack>
          </td>
          <td className={classes.normalText}>
            <Stack spacing={'xs'}>
              <Badge variant="filled">{row.type.platform.title}</Badge>
              <Badge>{row.type.title}</Badge>
            </Stack>
          </td>
          <td className={classes.normalText}>
            <Stack spacing={'xs'}>
              <Box>{new Date(row.createdAt).toLocaleString()}</Box>
              <Box>{new Date(row.updatedAt).toLocaleString()}</Box>
              <Box>{new Date(row.updatedAt).toLocaleString()}</Box>
              <Box>
                {row.endAt !== undefined && row.startAt !== undefined 
                  ? Date.parse(row.endAt!) - Date.parse(row.startAt) > 0 ? `${(
                    (Date.parse(row.endAt!) - Date.parse(row.startAt)) /
                      1000 /
                      60 /
                      60
                  ).toFixed(0)} hours`
                    : 'Infinite'
                  : 'Infinite'}
              </Box>
            </Stack>
          </td>
          <td className={classes.normalText}>{row.xp}</td>
          <td className={classes.normalText}>
            <Stack>
              <Checkbox
                checked={row.requiresVerification}
                classNames={{
                  label: classes.normalText
                }}
                label={'Verification required'}
              />
              <Checkbox
                checked={row.isRedeemaleCapped}
                classNames={{
                  label: classes.normalText
                }}
                label="Is capped?"
              />
              <Box>
                <Badge variant="gradient">{row.redeemableCount ?? 'N/A'}</Badge>{' '}
                Max. submission
              </Box>
              <Box>
                <Badge variant="gradient">
                  {row.redeemed}
                  {row.isRedeemaleCapped
                    ? ` (${(row.redeemed / row.redeemableCount) * 100}%)`
                    : ''}
                </Badge>{' '}
                Redeemed
              </Box>
            </Stack>
          </td>
        </tr>
      );
    });
    return (
      <>
        <NewQuestModal
          modalOpened={modalOpened}
          setModalOpened={setModalOpened}
          reloadData={() => {
            setReloadData(!reloadData);
          }}
          existingQuest={editQuest}
          clearExistingQuest={() => {
            setEditQuest(null);
          }}
        />
        <Group>
          <Group align={'flex-start'}>
            <Stack align="flex-start" spacing={'xs'}>
              <Group>
                <Title order={1} className={classes.title}>
                  Quests
                </Title>
                <Stack spacing={'xs'} align="flex-start">
                  <Text className={classes.text}>
                    {stats.total} total
                    <br />
                    {stats.totalXp} total XP
                    <br />
                    {stats.upcoming} upcoming
                    <br />
                    {stats.completed} completed
                  </Text>
                </Stack>
                <Stack spacing={'xs'} align="flex-start">
                  <SegmentedControl
                    data={[
                      { label: 'All', value: 'all' },
                      { label: 'Open', value: 'open' },
                      { label: 'Upcoming', value: 'upcoming' },
                      { label: 'Ended', value: 'ended' }
                    ]}
                    transitionDuration={250}
                    transitionTimingFunction="linear"
                    color={'blue'}
                    classNames={classes}
                    value={filterValue}
                    onChange={setFilterValue}
                    style={{
                      width: '320px'
                    }}
                  />
                  <Group>
                    <Select
                      data={[
                        { value: 'created_at', label: 'Created at' },
                        { value: 'updated_at', label: 'Updated at' },
                        { value: 'starts_at', label: 'Starts at' },
                        { value: 'ends_at', label: 'Ends at' }
                      ]}
                      value={sortByValue}
                      onChange={setSortByValue}
                      classNames={classes}
                    />
                    <SegmentedControl
                      data={[
                        { label: 'Asc', value: 'asc' },
                        { label: 'Desc', value: 'desc' }
                      ]}
                      transitionDuration={250}
                      transitionTimingFunction="linear"
                      color={'blue'}
                      classNames={classes}
                      style={{
                        width: '100px'
                      }}
                      value={sortByOrderValue}
                      onChange={setSortByOrderValue}
                    />
                  </Group>
                </Stack>
                <Stack spacing={'xs'} align="flex-start">
                  <TextInput
                    value={searchFilter}
                    onChange={(event) =>
                      setSearchFilter(event.currentTarget.value)
                    }
                    classNames={classes}
                    placeholder={`Search by ${searchBy}`}
                  />
                  <Select
                    data={[
                      { label: 'Title', value: 'title' },
                      { label: 'URL', value: 'url' }
                    ]}
                    transitionDuration={250}
                    transitionTimingFunction="linear"
                    color={'blue'}
                    classNames={classes}
                    value={searchBy}
                    onChange={setSearchBy}
                  />
                </Stack>
                <Stack spacing={'xs'} align="flex-start">
                  <ActionIcon
                    onClick={() => setModalOpened(true)}
                    variant="outline"
                    radius={0}
                    color="fire-pink.0"
                    size={40}
                  >
                    <IconPlus size={18} />
                  </ActionIcon>
                  <ActionIcon
                    onClick={() => setReloadData(!reloadData)}
                    variant="outline"
                    loading={refreshing}
                    radius={0}
                    color="fire-pink.0"
                    size={40}
                  >
                    <IconRefresh size={18} />
                  </ActionIcon>
                </Stack>
              </Group>
              <Pagination
                total={totalPages}
                page={currentPage}
                onChange={setCurrentPage}
                styles={() => ({
                  item: {
                    '&[data-active]': {
                      border: '1px solid #ff8aad',
                      backgroundColor: 'transparent'
                    },
                    backgroundColor: 'transparent',
                    color: 'white',
                    border: '1px solid #d6d6d6'
                  }
                })}
                siblings={3}
              />
            </Stack>
            <Table>
              <thead>
                <tr>
                  <th className={classes.normalText}>ID</th>
                  <th className={classes.normalText}>Title</th>
                  <th className={classes.normalText}>Type</th>
                  <th className={classes.normalText}>
                    <Stack spacing={0}>
                      <Box>Created At</Box>
                      <Box>Updated At</Box>
                      <Box>Start date</Box>
                      <Box>Duration</Box>
                    </Stack>
                  </th>
                  <th className={classes.normalText}>XP</th>
                  <th className={classes.normalText}>Submissions</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
            <Group align="center">
              <Pagination
                total={totalPages}
                page={currentPage}
                onChange={setCurrentPage}
                styles={() => ({
                  item: {
                    '&[data-active]': {
                      border: '1px solid #ff8aad',
                      backgroundColor: 'transparent'
                    },
                    backgroundColor: 'transparent',
                    color: 'white',
                    border: '1px solid #d6d6d6'
                  }
                })}
                siblings={3}
              />
            </Group>
          </Group>
        </Group>
      </>
    );
  }
};

const NewQuestModal = ({
  modalOpened,
  setModalOpened,
  reloadData,
  existingQuest,
  clearExistingQuest
}: {
  modalOpened: boolean;
  setModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  reloadData: () => void;
  existingQuest: EnrichedQuest | null;
  clearExistingQuest: () => void;
}) => {
  const { classes } = useStyles();
  const [jwt] = useLocalStorage({
    key: 'sw-jwt',
    defaultValue: ''
  });

  const form = useForm({
    initialValues: {
      title: existingQuest !== null ? existingQuest.title : '',
      xp: existingQuest ? existingQuest.xp : 0,
      startDate: existingQuest ? new Date(existingQuest.startAt) : new Date(),
      startTime: existingQuest ? new Date(existingQuest.startAt) : new Date(),
      endDate: existingQuest ? existingQuest.endAt !== null ? new Date(existingQuest.endAt) : null : null,
      endTime: existingQuest ? existingQuest.endAt !== null ? new Date(existingQuest.endAt) : null : null,
      selectedPlatform: '2',
      selectedType: '1',
      url: existingQuest ? existingQuest.url : '',
      id: '',
      manualVerification: existingQuest ? existingQuest.requiresVerification : false,
      noTimeLimit: existingQuest ? existingQuest.endAt === null : false,
      redeemableCapped: existingQuest ? existingQuest.isRedeemaleCapped : false,
      maxRedeemers: existingQuest ? existingQuest.redeemableCount : 0,
    }
  });

  if (existingQuest !== null && form.values.title !== existingQuest.title) {
    const itemId = existingQuest.meta.discordChannelId !== null 
      ? existingQuest.meta.discordChannelId.toString()
      : existingQuest.meta.twitterAccountId !== null
        ? existingQuest.meta.twitterAccountId.toString()
        : existingQuest.meta.twitterPostId !== null
          ? existingQuest.meta.twitterPostId.toString()
          : existingQuest.meta.discordMessageId !== null
            ? existingQuest.meta.discordMessageId.toString()
            : existingQuest.meta.discordGuildId !== null
              ? existingQuest.meta.discordGuildId.toString()
              : null; 
    form.setValues({ 
      title: existingQuest.title,
      xp: existingQuest.xp,
      startDate: new Date(existingQuest.startAt),
      startTime: new Date(existingQuest.startAt),
      endDate: existingQuest.endAt ? new Date(existingQuest.endAt) : null,
      endTime: existingQuest.endAt ? new Date(existingQuest.endAt) : null,
      selectedPlatform: existingQuest.type.questPlatformId.toString(),
      selectedType: existingQuest.type.id.toString(),
      url: existingQuest.url,
      id: itemId!,
      manualVerification: existingQuest.requiresVerification,
      noTimeLimit: existingQuest.endAt === null,
      redeemableCapped: existingQuest.isRedeemaleCapped,
      maxRedeemers: existingQuest.redeemableCount,
    });
  }

  return (
    <Modal
      opened={modalOpened}
      onClose={() => setModalOpened(false)}
      size="lg"
      title={existingQuest ? 'Edit an existing quest' : 'Add a new quest'}
      styles={{
        modal: {
          backgroundColor: 'black',
          color: 'white',
          border: '1px solid #ff8aad',
          fontFamily: 'JetBrains Mono'
        },
        title: {
          fontFamily: 'JetBrains Mono'
        }
      }}
    >
      <form
        onSubmit={form.onSubmit(async (values) => {
          await showNotificationOnError(async () => {
            if (jwt) {
              if (existingQuest === null) {
                await APIClient.createQuest({
                  jwt,
                  title: values.title,
                  xp: values.xp,
                  startAt: values.startDate,
                  startTime: values.startTime,
                  endAt: values.endDate,
                  endTime: values.endTime,
                  platformId: parseInt(values.selectedPlatform),
                  entityId: values.id,
                  questTypeId: parseInt(values.selectedType),
                  url: values.url,
                  requiresVerification: values.manualVerification,
                  isRedeemableCapped: values.redeemableCapped,
                  redeemableCount: values.maxRedeemers
                });
              } else {
                console.log('Updating quest', existingQuest.id, form.values);
                await APIClient.updateQuest({
                  jwt,
                  questId: existingQuest.id.toString(),
                  title: values.title,
                  xp: values.xp,
                  startAt: values.startDate,
                  startTime: values.startTime,
                  endAt: values.endDate,
                  endTime: values.endTime,
                  platformId: parseInt(values.selectedPlatform),
                  entityId: values.id,
                  questTypeId: parseInt(values.selectedType),
                  url: values.url,
                  requiresVerification: values.manualVerification,
                  isRedeemableCapped: values.redeemableCapped,
                  redeemableCount: values.maxRedeemers
                });
              }
            } else {
              throw new Error('Invalid JWT - please refresh the page');
            }

            setModalOpened(false);
            clearExistingQuest();
            reloadData();
          });
        })}
      >
        <Group grow>
          <TextInput
            classNames={{
              label: classes.normalText,
              input: classes.input
            }}
            placeholder={'Enter a title'}
            label="Enter a title"
            required
            {...form.getInputProps('title')}
          />
          <NumberInput
            defaultValue={10}
            placeholder={'Enter XP'}
            label="Enter XP"
            withAsterisk
            classNames={{
              label: classes.normalText,
              input: classes.input
            }}
            {...form.getInputProps('xp')}
          />
        </Group>
        <Space h={20} />
        <Group grow>
          <DatePicker
            label="Start date"
            classNames={{
              label: classes.normalText,
              input: classes.input
            }}
            placeholder="Select a date"
            withAsterisk
            inputFormat="MM/DD/YYYY"
            labelFormat="MM/YYYY"
            {...form.getInputProps('startDate')}
          />
          <TimeInput
            label="Start time"
            classNames={{
              label: classes.normalText,
              input: classes.input
            }}
            placeholder="Select a time"
            withAsterisk
            format="12"
            clearable
            {...form.getInputProps('startTime')}
          />
        </Group>
        <Space h={20} />
        <Group grow>
          <DatePicker
            label="End date"
            classNames={{
              label: classes.normalText,
              input: classes.input
            }}
            placeholder="Select a date"
            inputFormat="MM/DD/YYYY"
            labelFormat="MM/YYYY"
            {...form.getInputProps('endDate')}
          />
          <TimeInput
            label="End time"
            classNames={{
              label: classes.normalText,
              amPmInput: classes.normalText,
              input: classes.input
            }}
            placeholder="Select a time"
            format="12"
            clearable
            {...form.getInputProps('endTime')}
          />
        </Group>
        <Space h={20} />
        <Divider style={{ borderColor: '#2f3747' }} />
        <Space h={20} />
        <Group align="center" grow>
          <Select
            data={[
              {
                value: '1',
                label: 'Discord'
              },
              {
                value: '2',
                label: 'Twitter'
              }
            ]}
            label="Select a platform"
            classNames={{
              label: classes.normalText,
              input: classes.input
            }}
            required
            {...form.getInputProps('selectedPlatform')}
          />
          <Select
            data={[
              {
                value: 1,
                label: 'Follow account'
              },
              {
                value: 2,
                label: 'Retweet'
              },
              {
                value: 3,
                label: 'Like'
              },
              {
                value: 4,
                label: 'Join server'
              },
              {
                value: 5,
                label: 'React to message'
              },
              {
                value: 6,
                label: 'Claim role'
              }
            ]}
            label="Select a type"
            classNames={{
              label: classes.normalText,
              input: classes.input
            }}
            required
            {...form.getInputProps('selectedType')}
          />
        </Group>
        <Space h={20} />
        <Group grow>
          <TextInput
            classNames={{
              label: classes.normalText,
              input: classes.input
            }}
            placeholder={'https://...'}
            label="Enter a URL"
            required
            {...form.getInputProps('url')}
          />
          <TextInput
            classNames={{
              label: classes.normalText,
              input: classes.input
            }}
            placeholder={'0123...'}
            label="Enter ID for post, account, etc"
            required
            {...form.getInputProps('id')}
          />
        </Group>
        <Space h={20} />
        <Divider style={{ borderColor: '#2f3747' }} />
        <Space h={20} />
        <Group grow>
          <Checkbox
            label="Requires manual verification?"
            classNames={{
              label: classes.normalText,
              input: classes.input
            }}
            size="lg"
            {...form.getInputProps('manualVerification', { type: 'checkbox' })}
          />
          <Checkbox
            label="No time limit?"
            classNames={{
              label: classes.normalText,
              input: classes.input
            }}
            size="lg"
            {...form.getInputProps('noTimeLimit', { type: 'checkbox' })}
          />
        </Group>
        <Space h={20} />
        <Group grow>
          <Checkbox
            label="Redeemable capped?"
            classNames={{
              label: classes.normalText,
              input: classes.input
            }}
            size="lg"
            {...form.getInputProps('redeemableCapped', { type: 'checkbox' })}
          />
          <NumberInput
            label="Max. users allowed to redeem"
            classNames={{
              label: classes.normalText,
              input: classes.input
            }}
            placeholder={'Enter a number'}
            {...form.getInputProps('maxRedeemers')}
          />
        </Group>
        <Space h={20} />
        <Button
          label={existingQuest ? 'Update quest' : 'Create quest'}
          props={{
            type: 'submit'
          }}
        />
      </form>
    </Modal>
  );
};
