import { Stack, Group, Title, Loader, Text, SegmentedControl, Pagination, Table, Modal, TextInput, NumberInput, Space, ActionIcon, Badge, Popover, Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useLocalStorage } from '@mantine/hooks';
import { IconBan, IconBinary, IconPlus, IconRefresh, IconRuler } from '@tabler/icons';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { APIClient } from '../api/ApiClient';
import { Button } from '../stories/Button/Button';
import { parseJWT, showNotificationOnError, TopSpacer } from '../utils';
import { useStyles } from './AdminView';

interface DaoAccessCode {
  id: number;
  dao: string;
  accessCode: string;
  createdAt: Date;
  updatedAt: Date;
  totalWhitelistSpots: number;
  redeemedWhitelistSpots: number;
  totalPresaleSpots: number;
  redeemedPresaleSpots: number;
  raffleEndsAt: Date;
  raffleEntrantAdresses: any[];
  raffleWinners: any[];
  redemptions: number;
}

export const DaoAccessCodesView = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState('10');
  const [reloadData, setReloadData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [sortBy, setSortBy] = useState<string | null>('dao');
  const [ordering, setOrdering] = useState('asc');
  const [searchFilter, setSearchFilter] = useState('');
  const [collabInfo, setCollabInfo] = useState({
    codesIssued: 0,
    whitelistSpotsIssued: 0,
    presaleSpotsIssued: 0,
    codesRedeemed: 0,
    whitelistSpotsRedeemed: 0,
    presaleSpotsRedeemed: 0,
  });
  const [jwt] = useLocalStorage({
    key: 'sw-jwt',
    defaultValue: ''
  });
  const form = useForm({
    initialValues: {
      daoname: '',
      accesscode: '',
      wlspots: 0,
      presalespots: 0
    },

    validate: {
      wlspots: (value) => (value < 1) ? 'Number of WL spots must be greater than 0' : null,
      presalespots: (value) => (value < 1) ? 'Number of presale spots must be greater than 0' : null,
      // daoname must not have spaces
      daoname: (value) => (value.includes(' ')) ? 'DAO name must not contain spaces' : null,
      // accesscode must not have spaces
      accesscode: (value) => (value.includes(' ')) ? 'Access code must not contain spaces' : null,
    }
  });

  const { classes } = useStyles();

  // fetch data
  useEffect(() => {
    async function fetchCollabs() {
      setLoading(true);
      setError(false);
      
      if (jwt) {
        const parsedJwt = parseJWT(jwt);
        const isAdmin = parsedJwt.admin as boolean;

        if (!isAdmin) {
          setError(true);
        } else {
          const collabs = await APIClient.getCollabs({
            jwt,
            page: currentPage,
            limit: parseInt(pageSize),
            sortBy: sortBy as 'dao' | 'accessCode' | 'raffleEndsAt' | 'createdAt' | 'updatedAt' | 'totalWhitelistSpots' | 'redeemedWhitelistSpots' | 'totalPresaleSpots' | 'redeemedPresaleSpots' | 'redemptions',
            ordering: ordering as 'asc' | 'desc',
            searchFilter
          });
          const info = await APIClient.getCollabsInfo(jwt);
          setCollabInfo({
            codesIssued: info.codesIssued,
            whitelistSpotsIssued: info.whitelistSpotsIssued,
            presaleSpotsIssued: info.presaleSpotsIssued,
            codesRedeemed: info.codesRedeemed,
            whitelistSpotsRedeemed: info.whitelistSpotsRedeemed,
            presaleSpotsRedeemed: info.presaleSpotsRedeemed
          });
          setData(collabs.entries);
          setCurrentPage(collabs.currentPage);
          setTotalPages(collabs.totalPages);
        }
      } else {
        console.log('No JWT');
      }

      setLoading(false);
    }

    fetchCollabs();
  }, [
    reloadData,
    pageSize,
    ordering,
    sortBy,
    jwt,
    currentPage
  ]);

  useEffect(() => {
    async function updateData() {
      const collabs = await APIClient.getCollabs({
        jwt,
        page: currentPage,
        limit: parseInt(pageSize),
        sortBy: sortBy as 'dao' | 'accessCode' | 'raffleEndsAt' | 'createdAt' | 'updatedAt' | 'totalWhitelistSpots' | 'redeemedWhitelistSpots' | 'totalPresaleSpots' | 'redeemedPresaleSpots' | 'redemptions',
        ordering: ordering as 'asc' | 'desc',
        searchFilter
      });
      const info = await APIClient.getCollabsInfo(jwt);
      setCollabInfo({
        codesIssued: info.codesIssued,
        whitelistSpotsIssued: info.whitelistSpotsIssued,
        presaleSpotsIssued: info.presaleSpotsIssued,
        codesRedeemed: info.codesRedeemed,
        whitelistSpotsRedeemed: info.whitelistSpotsRedeemed,
        presaleSpotsRedeemed: info.presaleSpotsRedeemed
      });
      setData(collabs.entries);
      setCurrentPage(collabs.currentPage);
      setTotalPages(collabs.totalPages);
    }

    updateData();
  }, [searchFilter])
  
  if (loading) {
    return (
      <Stack align="center" spacing={0}>
        <TopSpacer />
        <Group>
          <Loader />
        </Group>
      </Stack>
    );
  } else if (error) {
    return <Navigate to={'/'} />;
  } else {
    const rows = data.map((collab: DaoAccessCode) => {
      return (
        <tr key={collab.id}>
          <td className={classes.normalText}>{collab.id}</td>
          <td className={classes.normalText}>{collab.dao}</td>
          <td className={classes.normalText}>{collab.accessCode}</td>
          <td className={classes.normalText}>{collab.redemptions}</td>
          <td className={classes.normalText}>
            <Stack>
              <Text className={classes.normalText}>{collab.redeemedWhitelistSpots} claimed</Text>
              <Text className={classes.normalText}>{collab.totalWhitelistSpots} issued</Text>
            </Stack>
          </td>
          <td className={classes.normalText}>
            <Stack>
              <Text className={classes.normalText}>{collab.redeemedPresaleSpots} claimed</Text>
              <Text className={classes.normalText}>{collab.totalPresaleSpots} issued</Text>
            </Stack>
          </td>
          <td className={classes.normalText}>
            <Badge variant='outline' color={collab.raffleWinners.length > 0 ? 'green' : 'yellow'}>
              {collab.raffleWinners.length > 0 ? 'Ended' : 'Pending'}
            </Badge>
          </td>
          <td className={classes.normalText}>{collab.raffleEndsAt.toString()}</td>
          
          <td className={classes.normalText}>
            <Popover position="top-end" withArrow shadow="md"  styles={{ dropdown: { backgroundColor: 'black' }}}>
              <Popover.Target>
                <Stack>
                  <Text className={classes.normalText}>{collab.raffleEntrantAdresses.length} entrants</Text>
                  <Text className={classes.normalText}>{collab.raffleWinners.length} winners</Text>
                </Stack>
              </Popover.Target>
              <Popover.Dropdown>
                <Stack>
                  {collab.raffleWinners.map((winner: string) => {
                    return (
                      <Text key={winner} className={classes.normalText}>{winner}</Text>
                    );
                  })}
                </Stack>
              </Popover.Dropdown>
            </Popover>
          </td>
        
          <td className={classes.normalText}>
            <Group grow>
              <ActionIcon
                variant="outline"
                color="red"
                onClick={async () => {
                  await showNotificationOnError(async () => {
                    await APIClient.deleteDaoAccessCode({
                      jwt,
                      id: collab.id
                    });
                  });
                  setReloadData(!reloadData);
                }}
              >
                <IconBan size={18} />
              </ActionIcon>
              <ActionIcon
                variant="outline"
                color="green"
                onClick={async () => {
                  await showNotificationOnError(async () => {
                    await APIClient.endRaffle({
                      jwt,
                      id: collab.id
                    });
                  });
                  setReloadData(!reloadData);
                }}
              >
                <IconBinary size={18} />
              </ActionIcon>
              <ActionIcon
                variant="outline"
                color="orange"
                onClick={async () => {
                  await showNotificationOnError(async () => {
                    await APIClient.extendRaffleByOneDay({
                      jwt,
                      id: collab.id
                    });
                  });
                  setReloadData(!reloadData);
                }}
              >
                <IconRuler size={18} />
              </ActionIcon>
            </Group>
          </td>
        </tr>
      );
    });

    return (
      <>
        <Modal
          opened={modalOpen}
          onClose={() => setModalOpen(false)}
          size="lg"
          title="Create new DAO/Access Code"
          styles={{
            modal: {
              backgroundColor: 'black',
              color: 'white',
              border: '1px solid #ff8aad',
              fontFamily: 'JetBrains Mono'
            },
            title: {
              fontFamily: 'JetBrains Mono'
            }
          }}
        >
          <form onSubmit={form.onSubmit(async (values) => {
            await showNotificationOnError(async () => {
              if (jwt) {
                console.log(values);
                await APIClient.createCollab({
                  jwt,
                  dao: values.daoname,
                  accessCode: values.accesscode,
                  totalWhitelistSpots: values.wlspots,
                  totalPresaleSpots: values.presalespots
                });
              } else {
                throw new Error('Invalid JWT - please refresh the page');
              }

              setModalOpen(false);
              setReloadData(!reloadData);
            })
          })}>
            <Group grow>
              <TextInput
                classNames={{
                  label: classes.normalText,
                  input: classes.input
                }}
                placeholder={'DAOs name'}
                label="Name of the DAO"
                withAsterisk
                required
                {...form.getInputProps('daoname')}
              />
              <TextInput
                placeholder={'Access code'}
                label="Access code for the DAO"
                withAsterisk
                required
                classNames={{
                  label: classes.normalText,
                  input: classes.input
                }}
                {...form.getInputProps('accesscode')}
              />
            </Group>
            <Space h={20} />
            <Group grow>
              <NumberInput
                classNames={{
                  label: classes.normalText,
                  input: classes.input
                }}
                placeholder={'Number of WL spots'}
                label="Number of WL spots"
                withAsterisk
                required
                {...form.getInputProps('wlspots')}
              />
              <NumberInput
                placeholder={'Number of presale spots'}
                label="Number of presale spots"
                withAsterisk
                required
                classNames={{
                  label: classes.normalText,
                  input: classes.input
                }}
                {...form.getInputProps('presalespots')}
              />
            </Group>
            <Space h={20} />
            <Group position='right'>
              <Button
                label={'Create DAO/Access Code'}
                props={{ type: 'submit' }}
              />
            </Group>
          </form>
        </Modal>
        <Group>
          <Group align={'flex-start'}>
            <Stack align="flex-start" spacing={'xs'}>
              <Group>
                <Title order={1} className={classes.title}>
                  Manage DAO access
                </Title>
                <Stack spacing={'xs'} align="flex-start">
                  <Text className={classes.text}>
                    {collabInfo.codesIssued} codes issued<br />
                    {collabInfo.codesRedeemed} codes redeemed<br />
                    {collabInfo.whitelistSpotsIssued} WL issued<br />
                    {collabInfo.whitelistSpotsRedeemed} WL redeemed<br />
                    {collabInfo.presaleSpotsIssued} presale issued<br />
                    {collabInfo.presaleSpotsRedeemed} presale redeemed<br />
                  </Text>
                </Stack>
                <Stack spacing={'xs'} align="flex-start">
                  <ActionIcon
                    onClick={() => setModalOpen(true)}
                    variant="outline"
                    radius={0}
                    color="fire-pink.0"
                    size={40}
                  >
                    <IconPlus size={18} />
                  </ActionIcon>
                  <ActionIcon
                    onClick={() => setReloadData(!reloadData)}
                    variant="outline"
                    radius={0}
                    color="fire-pink.0"
                    size={40}
                  >
                    <IconRefresh size={18} />
                  </ActionIcon>
                </Stack>
              </Group>
            </Stack>
            <Stack spacing={'xs'} align="flex-start">
              <SegmentedControl
                data={[
                  { label: '10', value: '10' },
                  { label: '20', value: '20' },
                  { label: '50', value: '50' },
                  { label: '100', value: '100' }
                ]}
                transitionDuration={250}
                transitionTimingFunction="linear"
                color={'blue'}
                classNames={classes}
                value={pageSize.toString()}
                onChange={setPageSize}
                style={{
                  width: '320px'
                }}
              />
            </Stack>
            <Stack spacing={'xs'} align="flex-start">
              <SegmentedControl
                data={[
                  { label: 'asc', value: 'asc' },
                  { label: 'desc', value: 'desc' }
                ]}
                transitionDuration={250}
                transitionTimingFunction="linear"
                color={'blue'}
                classNames={classes}
                value={ordering}
                onChange={setOrdering}
                style={{ width: '320px' }}
              />
            </Stack>
            <Stack spacing={'xs'} align="flex-start">
              <Group>
                <Select
                  data={[
                    { label: 'DAO', value: 'dao' },
                    { label: 'Access Code', value: 'accessCode' },
                    { label: 'Raffle', value: 'raffleEndsAt' },
                    { label: 'Created', value: 'createdAt' },
                    { label: 'Updated', value: 'updatedAt' },
                    { label: 'WL', value: 'totalWhitelistSpots' },
                    { label: 'WL (R)', value: 'redeemedWhitelistSpots' },
                    { label: 'Presale', value: 'totalPresaleSpots' },
                    { label: 'Presale (R)', value: 'redeemedPresaleSpots' },
                    { label: 'Redemptions', value: 'redemptions' }
                  ]}
                  transitionDuration={250}
                  transitionTimingFunction="linear"
                  color={'blue'}
                  classNames={classes}
                  value={sortBy}
                  onChange={setSortBy}
                  style={{ width: '320px' }}
                />
                <TextInput
                  value={searchFilter}
                  onChange={(event) =>
                    setSearchFilter(event.currentTarget.value)
                  }
                  classNames={classes}
                  placeholder="Search by DAO name or access code"
                />
              </Group>
            </Stack>
            <Stack style={{ width: '100%' }}>
              <Pagination
                total={totalPages}
                page={currentPage}
                onChange={setCurrentPage}
                styles={() => ({
                  item: {
                    '&[data-active]': {
                      border: '1px solid #ff8aad',
                      backgroundColor: 'transparent'
                    },
                    backgroundColor: 'transparent',
                    color: 'white',
                    border: '1px solid #d6d6d6'
                  }
                })}
                siblings={3}
              />
              <Table>
                <thead>
                  <tr>
                    <th className={classes.normalText}>ID</th>
                    <th className={classes.normalText}>DAO</th>
                    <th className={classes.normalText}>Access Code</th>
                    <th className={classes.normalText}>Redemptions</th>
                    <th className={classes.normalText}>WL</th>
                    <th className={classes.normalText}>Presale</th>
                    <th className={classes.normalText}>Raffle Status</th>
                    <th className={classes.normalText}>Raffle end</th>
                    <th className={classes.normalText}># of</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </Table>
            </Stack>
          </Group>
        </Group>
      </>
    )
  }
};
