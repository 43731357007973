import { ActionIcon, Badge, CloseButton, Divider, Grid, Group, Modal, NumberInput, Popover, Radio, Space, Stack, Text } from '@mantine/core';
import { ReactNode, useEffect, useState } from 'react';
import { StyledDialog } from '../stories/StyledDialog/StyledDialog';
import { BottomSpacer, formatNumber, TopSpacer } from '../utils';
import { useWallet } from '@solana/wallet-adapter-react';
import { Button } from '../stories/Button/Button';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { IconRefresh, IconSettings } from '@tabler/icons';
import OptionText, { useStyles } from './StatusView';
import { useStyles as inputStyles } from '../utils';
import { Connection, LAMPORTS_PER_SOL, Transaction } from '@solana/web3.js';
import { SDK, Wallet, State } from '@solworks/presales-sdk';
import { showNotification, updateNotification } from '@mantine/notifications';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
import { Logger, TransactionWrapper } from '@solworks/soltoolkit-sdk';
import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import { ENDPOINT_CONFIGS, IConfig } from '..';
import { useSearchParams } from 'react-router-dom';
import { analytics } from '../App';
import { logEvent } from 'firebase/analytics';

interface ITransactionPreview {
  txType: 'buy' | 'mint';
  state: 'preview' | 'signing' | 'sending' | 'confirmed' | 'failed';
  numberOfTickets?: number;
  costInSol?: number;
  transaction: Transaction;
  uuid: string;
  signature?: string;
}
const logger = new Logger('presales-view');

export const UserPresaleView = () => {
  const { connected, publicKey, disconnect, wallet, signAllTransactions } = useWallet();
  const { setVisible } = useWalletModal();
  const { width, height } = useWindowSize();
  
  const [loading, setLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const { classes } = useStyles();
  const { classes: inputClasses } = inputStyles();
  const [presaleCount, setPresaleCount] = useState<number | undefined>(3);
  const [userBalanceSOL, setUserBalanceSOL] = useState(0);
  const [userTicketsRedeemed, setUserTicketsRedeemed] = useState(0);
  const [userTicketsBought, setUserTicketsBought] = useState(0);
  const [presalePricePerUnit, setPresalePricePerUnit] = useState(2.5);
  const [sdk, setSdk] = useState<SDK | null>(null);
  const [state, setState] = useState<State | null>(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [claimingTickets, setClaimingTickets] = useState(false);
  const [buyingTickets, setBuyingTickets] = useState(false);
  const [isEligible, setIsEligible] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [endpoint, setEndpoint] = useLocalStorage<IConfig>({ key: 'endpoint', defaultValue: ENDPOINT_CONFIGS[0] });
  const [previewTranscations, setPreviewTransactions] = useState<ITransactionPreview[]>([]);
  const [opened, { open, close }] = useDisclosure(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [searchParams] = useSearchParams();
  const [paEnabled] = useState(searchParams.get('__pa') || 0);

  useEffect(() => {
    async function init() {
      if (wallet && publicKey) {
        logger.info('init', JSON.stringify({ 
          connected, 
          publicKey: publicKey.toBase58(),
          commitment: 'processed',
          rpc: endpoint.url
        }, null, 2));

        // set SDK
        const sdk = await SDK.build(
          new Connection(endpoint.url, 'processed'), 
          wallet.adapter as Wallet, 
          endpoint.network === 'mainnet-beta' ? 'mainnet' : endpoint.network === 'devnet' ? 'devnet' : 'localnet'
        );
        setSdk(sdk);

        // set state
        const state = await sdk.getState();
        setState(state);
        setHasStarted(state.hasStarted);
        logger.debug('state', JSON.stringify(prettyState(state), null, 2));

        // set whitelist data
        const wlAccount = await sdk.getWhitelistAccount(state);
        const wl = await sdk.getWhitelist(state);
        setPresalePricePerUnit(state.unitPrice.toNumber() / LAMPORTS_PER_SOL);
        const user = wl.users.find((u) => u.address.toBase58() === publicKey?.toBase58());
        if (user) {
          setIsEligible(true);
          logger.debug('user', JSON.stringify({ 
            address: user.address.toBase58(),
            minted: user.unitsMinted.toNumber(), 
            bought: user.unitsBought.toNumber(),
            isEligible: true
          }, null, 2));
        } else {
          logger.debug(JSON.stringify({ isEligible: false }, null, 2));
        }
        setUserTicketsBought(user?.unitsBought.toNumber() || 0);
        setUserTicketsRedeemed(user?.unitsMinted.toNumber() || 0);

        // set up listeners
        sdk.onStateChange((state) => {
          setState(state);
          setHasStarted(state.hasStarted);
          logger.debug('state', JSON.stringify(prettyState(state), null, 2));
        });
        sdk.onWhitelistChange((wl) => {
          const user = wl.users.find((u) => u.address.toBase58() === publicKey?.toBase58());
          setUserTicketsBought(user?.unitsBought.toNumber() || 0);
          setUserTicketsRedeemed(user?.unitsMinted.toNumber() || 0);
        }, wlAccount);
        sdk.connection.onAccountChange(publicKey, async () => {
          const sol = await sdk.connection.getBalance(publicKey);
          const solBalance = sol / LAMPORTS_PER_SOL;
          setUserBalanceSOL(solBalance);
          logger.debug(JSON.stringify({ balance: solBalance }, null, 2));
        });

        // set user balance
        const sol = await sdk.connection.getBalance(publicKey);
        const solBalance = sol / LAMPORTS_PER_SOL;
        setUserBalanceSOL(solBalance);
        // logger.debug(JSON.stringify({ balance: solBalance }, null, 2));
      }
      setLoading(false);
    }

    init();
  }, [refreshData, wallet, publicKey, endpoint,]);

  return (
    <Stack align="center" spacing={0}>
      <Modal
        opened={opened}
        onClose={close}
        size="lg"
        centered
        withCloseButton={false}
        styles={{
          modal: {
            backgroundColor: 'black',
            color: 'white',
            border: '1px solid rgba(87, 87, 87, 0.48)',
            fontFamily: 'JetBrains Mono',
            boxShadow: 'rgba(256, 256, 256, 0.1) 0px 5px 10px 0px',
            padding: '0px !important',
          },
          title: {
            fontFamily: 'JetBrains Mono'
          }
        }}
      >
        <Group position='center' sx={{ padding: '14px' }}>
          <Text size='lg' weight={700} style={{ fontFamily: 'JetBrains Mono' }}>Execute transactions</Text>
          <CloseButton onClick={() => {
            setClaimingTickets(false);
            setBuyingTickets(false);
            close();
          }} sx={{ position: 'absolute', right: '20px' }} />
        </Group>
        <Divider size={1} style={{ width: '100%', borderColor: 'rgba(87, 87, 87, 0.48)' }} />
        <Grid>
          <Grid.Col span='auto' >
            <Stack spacing={5} style={{ padding: '20px', borderRight: '1px solid rgba(87, 87, 87, 0.48)' }}>
              <Text size='md' weight={800} style={{ fontFamily: 'JetBrains Mono' }}>TRANSACTIONS</Text>
              {previewTranscations.length === 0 && <Text size='sm' weight={600} style={{ fontFamily: 'JetBrains Mono' }}>No transactions (try disabling AdBlock/Brave Shield/VPN)</Text>}
              {previewTranscations.map((tx, i) => (
                <TransactionPreview {...tx} key={i} />
              ))}
            </Stack>
          </Grid.Col>
          <Grid.Col span={3} style={{ padding: '14px' }}>
          </Grid.Col>
        </Grid>
        <Divider size={1} style={{ width: '100%', borderColor: 'rgba(87, 87, 87, 0.48)' }} />
        <Group position='center' sx={{ padding: '14px' }}>
          <Button 
            variant={
              previewTranscations.filter((tx) => tx.state === 'sending' || tx.state === 'signing').length > 0 || isProcessing // txs are being signed or sent 
                ? 'primary-disabled'
                : previewTranscations.filter((tx) => tx.state === 'preview').length > 0 // txs exist to sign
                  ? 'primary'
                  : previewTranscations.filter((tx) => tx.state === 'failed').length > 0 // some txs failed
                    ? 'primary'
                    : 'green'
            }
            label={
              previewTranscations.filter((tx) => tx.state === 'sending' || tx.state === 'signing').length > 0 // txs are being signed or sent 
                ? 'PREPARING...'
                : previewTranscations.filter((tx) => tx.state === 'preview').length > 0 // txs exist to sign
                  ? `EXECUTE ${previewTranscations.length} TRANSACTIONS`
                  : previewTranscations.filter((tx) => tx.state === 'failed').length > 0 // some txs failed
                    ? 'CLOSE AND TRY AGAIN'
                    : 'CLOSE'
            }
            onClick={async () => {
              if (signAllTransactions && sdk && previewTranscations.filter((tx) => tx.state === 'preview').length > 0) {
                logEvent(analytics, 'attempt_mint');
                setIsProcessing(true);
                // update status of all transactions to 'signing'
                logger.debug('signing transactions');
                setPreviewTransactions(previewTranscations.map((tx) => ({
                  ...tx,
                  state: 'signing'
                })));

                createNotificationWithId('minting-tickets', 'Executing transactions', 'Requesting signature from wallet...');
                let signedTxs: Transaction[] = [];
                try {
                  signedTxs = await signAllTransactions(previewTranscations.map((tx) => tx.transaction));
                } catch (e: any) {
                  logger.error(e);
                  updateExistingNotification('minting-tickets', 'Something went wrong', e.message || 'Something went wrong, this usually happens if you do not have enough SOL to cover the transaction fee. Please try again.', 'red', false);
                  setPreviewTransactions(previewTranscations.map((tx) => ({
                    ...tx,
                    state: 'failed'
                  })));
                }

                if (signedTxs.length === 0) {
                  logger.error('no signed transactions');
                  updateExistingNotification('minting-tickets', 'Something went wrong', 'No transaction signatures found. Please try again.', 'red', false);
                  setPreviewTransactions(previewTranscations.map((tx) => ({
                    ...tx,
                    state: 'failed'
                  })));
                } else {
                  logger.debug('sending transactions');
                  setPreviewTransactions(previewTranscations.map((tx) => ({
                    ...tx,
                    state: 'sending'
                  })));
  
                  let successfulTxs = 0;
                  for (let i = 0; i < signedTxs.length; i++) {
                    try {
                      const transferSig = await (await TransactionWrapper
                        .create({ connection: sdk.connection })
                        .addBlockhashAndFeePayer(publicKey!))
                        .sendAndConfirm({
                          serialisedTx: signedTxs[i].serialize(),
                          commitment: 'processed',
                        });
                      logger.debug('Transaction sent:', transferSig.toString());
                      const updatedTransactions = [...previewTranscations];
                      updatedTransactions[i].state = 'confirmed';
                      updatedTransactions[i].signature = transferSig.toString();
                      setPreviewTransactions(updatedTransactions);
                      await sleep(250);
                      successfulTxs++;
                    } catch (e: any) {
                      logger.error(e);
                      const updatedTransactions = [...previewTranscations];
                      updatedTransactions[i].state = 'failed';
                      setPreviewTransactions(updatedTransactions);
                    }
                  }
  
                  if (successfulTxs === signedTxs.length) {
                    logEvent(analytics, 'mint');
                    updateExistingNotification('minting-tickets', 'Transactions confirmed', 'Confirmed transactions', 'green', false);
                    setShowConfetti(true);
                  } else if (successfulTxs === 0) {
                    logEvent(analytics, 'mint_failed');
                    updateExistingNotification('minting-tickets', 'Something went wrong', 'No transactions confirmed. Please try again.', 'red', false);
                  } else {
                    logEvent(analytics, 'mint_partial');
                    updateExistingNotification('minting-tickets', 'Something went wrong', 'Some transactions failed. Please try again.', 'red', false);
                  }
                }
              } else {
                close();
              }
              
              setBuyingTickets(false);
              setClaimingTickets(false);
              setRefreshData(!refreshData);
              // after 30 seconds, hide confetti
              setTimeout(() => {
                setShowConfetti(false);
              }, 30_000);
              setIsProcessing(false);
            }} />
        </Group>
      </Modal>
      {showConfetti && <Confetti
        width={width}
        height={height}
        friction={1}
        recycle={true}
        numberOfPieces={200}
        wind={0.01}
        gravity={0.1}
        initialVelocityX={2}
        initialVelocityY={5}
      />}
      <TopSpacer />
      <StyledDialog
        title={'Sujiko Warriors Presale'}
        description={
          'Presale tickets will be redeemable nearer the public mint ⚔️'
        }
        descriptionProps={{
          color: '#909296',
          size: 12
        }}
        children={
          <>
            <Space h={30} />
            <Group>
              <Button
                {...{
                  label: connected
                    ? `${publicKey?.toBase58().slice(0, 4)}...${publicKey
                      ?.toBase58()
                      .slice(-4)}`
                    : 'Select wallet',
                  variant: 'solana',
                  onClick: () => {
                    if (!connected) {
                      setVisible(true);
                    } else {
                      disconnect();
                    }
                  }
                }}
              />
              {connected && (
                <ActionIcon
                  onClick={() => setRefreshData(!refreshData)}
                  variant="outline"
                  loading={loading}
                  radius={0}
                  color="fire-pink.0"
                  size={42.5}
                  sx={{
                    '&[data-loading]::before': {
                      backgroundColor: 'black',
                      zIndex: -1,
                      border: '1px solid #909296'
                    },
                    '&[data-loading]': {
                      backgroundColor: 'transparent !important',
                      border: '1px solid rgba(87, 87, 87, 1)'
                    }
                  }}
                  loaderProps={{
                    color: 'fire-pink.0',
                    style: {
                      backgroundColor: 'black',
                      border: 'none'
                    }
                  }}
                >
                  <IconRefresh size={18} color="gray" />
                </ActionIcon>
              )}
              {connected && (
                <Popover
                  shadow="md"
                  width="320px"
                  styles={{
                    dropdown: {
                      backgroundColor: 'black',
                      borderRadius: 0,
                      border: '1px solid #2f3747',
                      boxShadow: 'rgba(255, 255, 255, 0.05) 0px 10px 20px -3px'
                    }
                  }}
                  position="bottom"
                >
                  <Popover.Target>
                    <ActionIcon
                      variant="outline"
                      radius={0}
                      color="fire-pink.0"
                      size={42.5}
                      sx={{
                        '&[data-loading]::before': {
                          backgroundColor: 'black',
                          zIndex: -1,
                          border: '1px solid #909296'
                        },
                        '&[data-loading]': {
                          backgroundColor: 'transparent !important',
                          border: '1px solid rgba(87, 87, 87, 1)'
                        }
                      }}
                      loaderProps={{
                        color: 'fire-pink.0',
                        style: {
                          backgroundColor: 'black',
                          border: 'none'
                        }
                      }}
                    >
                      <IconSettings size={18} color="gray" />
                    </ActionIcon>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <OptionText fontSize="14px">Settings</OptionText>
                    <Space h={8} />
                    <Divider color="#2f3747" />
                    <Space h={12} />
                    <OptionText fontSize="12px">PREFERRED RPC</OptionText>
                    <Radio.Group
                      name=""
                      withAsterisk
                      value={endpoint.value}
                      onChange={(e: any) => {
                        const newEndpoint = ENDPOINT_CONFIGS.find(
                          (endpoint) => endpoint.value === e
                        );
                        if (newEndpoint) {
                          logger.info('new endpoint', newEndpoint);
                          setEndpoint(newEndpoint);
                        } else {
                          logger.error('endpoint not found');
                        }
                      }}
                    >
                      <Group mt="xs" spacing={'lg'}>
                        {ENDPOINT_CONFIGS.map((endpoint) => {
                          return (
                            <Radio
                              value={endpoint.value}
                              label={endpoint.label}
                              size="xs"
                              styles={{
                                label: {
                                  fontFamily: 'JetBrains Mono',
                                  fontSize: '10px',
                                  fontWeight: 'bold',
                                  fontStretch: 'normal',
                                  fontStyle: 'normal',
                                  lineHeight: 'normal',
                                  letterSpacing: 'normal',
                                  color: '#d6d6d6',
                                  borderRadius: 0,
                                  paddingTop: '2px'
                                },
                                radio: {
                                  '&:checked': {
                                    backgroundColor: '#ff8aad',
                                    borderColor: '#ff8aad'
                                  }
                                },
                                icon: {
                                  color: 'black'
                                }
                              }}
                            />
                          );
                        })}
                      </Group>
                    </Radio.Group>
                    <Space h={8} />
                  </Popover.Dropdown>
                </Popover>
              )}
            </Group>
            <Space h={28} />
            <Divider
              size={1}
              style={{
                width: '100%',
                borderColor: 'rgba(87, 87, 87, 0.48)'
              }}
            />
            {/* <Space h={12} />
            {connected ? (
              <>
                <Space h={17} />
                <Group position="apart" style={{ width: '100%' }}>
                  <Text className={classes.statusLabel}>Status:</Text>
                  {loading ? (
                    <Loader />
                  ) : (
                    <Badge
                      children={isEligible ? 'Eligible' : 'Not eligible'}
                      color={isEligible ? 'green' : 'red'}
                      variant="outline"
                    />
                  )}
                </Group>
              </>
            ) : (
              <></>
            )} */}
            <Space h={18} />
            <Stack style={{ width: '100%' }}>
              <Group position="apart" style={{ width: '100%' }}>
                <Text className={classes.statusLabel}>Ticket cost:</Text>
                <Text className={classes.statusLabel}>
                  {state ? `${formatNumber(state.unitPrice.toNumber() / LAMPORTS_PER_SOL, 1)} SOL` : ''}
                </Text>
              </Group>
            </Stack>
            <Space h={18} />
            <Stack style={{ width: '100%' }}>
              <Group position="apart" style={{ width: '100%' }}>
                <Text className={classes.statusLabel}>Opens on:</Text>
                <Text className={classes.statusLabel}>8PM UTC, Jun 17 </Text>
              </Group>
            </Stack>
            {/* <Space h={18} />
            <Stack style={{ width: '100%' }}>
              <Group position="apart" style={{ width: '100%' }}>
                <Text className={classes.statusLabel}>Tickets remaining:</Text>
                <Text className={classes.statusLabel}>
                  {state ? `${state.maxUnits.sub(state.unitsBought).toNumber().toLocaleString()} / ${state.maxUnits.toNumber().toLocaleString()}` : '-'}
                </Text>
              </Group>
            </Stack> */}
            <Space h={18} />
            <Divider
              size={1}
              style={{
                width: '100%',
                borderColor: 'rgba(87, 87, 87, 0.48)'
              }}
            />
            {/* <Space h={18} />
            <Stack style={{ width: '100%' }}>
              <Group position="apart" style={{ width: '100%' }}>
                <Text className={classes.statusLabel}>Maximum per user:</Text>
                <Text className={classes.statusLabel}>
                  {state ? `${state.maxUnitsPerUser.toNumber().toLocaleString()} tickets` : '-'}
                </Text>
              </Group>
            </Stack>
            <Space h={18} />
            <Stack style={{ width: '100%' }}>
              <Group position="apart" style={{ width: '100%' }}>
                <Text className={classes.statusLabel}>You've bought:</Text>
                <Text className={classes.statusLabel}>{formatNumber(userTicketsBought, 0)} tickets</Text>
              </Group>
            </Stack>
            <Space h={18} />
            <Stack style={{ width: '100%' }}>
              <Group position="apart" style={{ width: '100%' }}>
                <Text className={classes.statusLabel}>You've minted:</Text>
                <Text className={classes.statusLabel}>{formatNumber(userTicketsRedeemed, 0)} tickets</Text>
              </Group>
            </Stack>
            <Space h={18} />
            <Divider
              size={1}
              style={{
                width: '100%',
                borderColor: 'rgba(87, 87, 87, 0.48)'
              }}
            /> */}
            <Space h={33} />
            <Stack style={{ width: '100%' }}>
              <Group position="apart" style={{ width: '100%' }}>
                <Text className={classes.statusLabel}>Enter number of tickets to buy:</Text>
                <NumberInput
                  withAsterisk
                  width={'100%'}
                  classNames={inputClasses}
                  size='lg'
                  value={presaleCount}
                  onChange={setPresaleCount}
                  max={paEnabled == 1 ? 100 : 24}
                  min={3}
                  hideControls
                  defaultValue={3}
                />
              </Group>
            </Stack>
            <Space h={18} />
            <Button
              {...{
                label: !connected
                  ? 'Connect wallet'
                  : !hasStarted 
                    ? 'Presale has not started' 
                    : userTicketsRedeemed === state?.maxUnitsPerUser.toNumber() 
                      ? 'Ticket limit reached'
                      : userTicketsBought > userTicketsRedeemed 
                        ? <>{buyingTickets||claimingTickets ? '': `Mint ${userTicketsBought - userTicketsRedeemed} presale tickets`}</>
                        : state?.maxUnits.sub(state?.unitsBought).eqn(0) 
                          ? 'Presale ended' 
                          : ((userBalanceSOL) - (presaleCount! * presalePricePerUnit) < 0) 
                            ? `Need ${formatNumber(Math.abs((userBalanceSOL) - (presaleCount! * presalePricePerUnit)), 2)} SOL` 
                            : <>{buyingTickets||claimingTickets ? '': `Buy ${presaleCount || ''} presale tickets`}</>,
                variant: !connected 
                  ? 'primary' 
                  : !isEligible || userTicketsBought > userTicketsRedeemed
                    ? 'primary'
                    : ((userBalanceSOL) - (presaleCount! * presalePricePerUnit) < 0) || buyingTickets || claimingTickets || (userTicketsRedeemed === (state?.maxUnitsPerUser.toNumber() || 0)) || (state?.maxUnits.eq(state?.unitsBought)) || !hasStarted 
                      ? 'primary-disabled-full-width' 
                      : 'primary-full-width',
                onClick: async () => {
                  if (!connected) {
                    setVisible(true);
                  } 
                  // else if (
                  //   sdk && 
                  //   connected && 
                  //   publicKey && 
                  //   !isEligible &&
                  //   hasStarted
                  // ) {
                  //   createNotificationWithId('whitelisting', 'Adding address to whitelist', 'Requesting signature from wallet...');
                  //   try {
                  //     const sig = await sdk.addUserToWhitelist(publicKey);
                  //     logger.debug('sig', sig);
                  //     updateExistingNotification('whitelisting', 'Whitelisting successful', 'You have been added to the whitelist!', 'green', false);
                  //   } catch (e: any) {
                  //     logger.error(e);
                  //     updateExistingNotification('whitelisting', 'Something went wrong', e.message || 'Something went wrong, this usually happens if you do not have enough SOL to cover the transaction fee. Please try again.', 'red', false);
                  //   }
                  //   setRefreshData(!refreshData);
                  // } 
                  else if (
                    sdk && 
                    connected && 
                    publicKey && 
                    userTicketsBought > userTicketsRedeemed && 
                    !claimingTickets && 
                    !buyingTickets && 
                    userTicketsRedeemed !== state?.maxUnitsPerUser.toNumber() &&
                    isEligible &&
                    hasStarted
                  ) {
                    setClaimingTickets(true);
                    try {
                      const diff = userTicketsBought - userTicketsRedeemed;

                      const txs = [];
                      let recentBlockhash = await sdk.connection.getLatestBlockhashAndContext();
                      for (let i = 0; i < diff; i++) {
                        const tx = await sdk.mintUnitTransaction({ recentBlockhash: recentBlockhash.value.blockhash });
                        txs.push(tx);
                      }
                      setPreviewTransactions(txs.map((tx) => ({
                        txType: 'mint',
                        state: 'preview',
                        transaction: tx,
                        numberOfTickets: 1,
                        costInSol: 0.01,
                        uuid: Math.random().toString(36).substring(7)
                      })));
                      open();
                      
                      // if (signAllTransactions) {
                      //   createNotificationWithId('minting-tickets', `Minting ${diff} tickets`, 'Requesting signature from wallet...');
                      //   const signedTxs = await signAllTransactions(txs);

                      //   for (let i = 0; i < signedTxs.length; i++) {
                      //     const sig = await sdk.connection.sendRawTransaction(signedTxs[i].serialize());
                      //     logger.debug('sig', sig);
                      //     await sdk.connection.confirmTransaction(sig, 'confirmed');
                      //     updateExistingNotification(
                      //       'minting-tickets', 
                      //       'Mint successful', 
                      //       `Minted ${i+1} of ${diff} tickets`, 
                      //       'green', 
                      //       true,
                      //       false
                      //     );
                      //   }
                      //   updateExistingNotification('minting-tickets', 'Minted tickets', `You just minted ${presaleCount!} presale tickets!`, 'green', false);
                      //   setShowConfetti(true);
                      // }
                    } catch (e: any) {
                      logger.error(e);
                      showNotification({
                        title: 'Something isn\'t right...',
                        message: e.error.errorMessage !== undefined ? e.error.errorMessage : e.message || 'Something went wrong, this usually happens if you do not have enough SOL to cover the transaction fee. Please try again.',
                        color: 'red'
                      })
                    }

                    setClaimingTickets(false);
                  } else if (
                    sdk && 
                    connected && 
                    (userBalanceSOL) - (presaleCount! * presalePricePerUnit) > 0 && 
                    publicKey && 
                    !claimingTickets && 
                    !buyingTickets &&
                    !state?.maxUnits.eq(state?.unitsBought) &&
                    userTicketsBought !== state?.maxUnitsPerUser.toNumber() &&
                    isEligible && 
                    hasStarted
                  ) {
                    setBuyingTickets(true);
                    try {
                      // createNotificationWithId('buying-tickets', `Purchasing ${presaleCount} tickets`, 'Requesting signature from wallet...');
                      // updateExistingNotification('buying-tickets', 'Confirming transaction', 'Waiting for confirmation...', 'pink', true);
                      // await sdk.connection.confirmTransaction(sig, 'confirmed');
                      // updateExistingNotification('buying-tickets', 'Holy shit, LFG!', `You just bought ${presaleCount} presale tickets!`, 'green', false);
                      // logger.debug('sig', sig);
                      // setClaimingTickets(true);
                      // setBuyingTickets(false);
                      
                      const txs = [];
                      let recentBlockhash = await sdk.connection.getLatestBlockhashAndContext();
                      const tx = await sdk.buyUnitsTransaction({
                        units: presaleCount!,
                        payer: publicKey,
                      });
                      tx.recentBlockhash = recentBlockhash.value.blockhash;
                      tx.feePayer = publicKey;
                      txs.push({
                        tx,
                        type: 'buy'
                      });
                      for (let i = 0; i < presaleCount!; i++) {
                        const tx = await sdk.mintUnitTransaction({ recentBlockhash: recentBlockhash.value.blockhash });
                        txs.push({
                          tx,
                          type: 'mint'
                        });
                      }
                      setPreviewTransactions(txs.map((tx) => ({
                        txType: tx.type as 'buy' | 'mint',
                        state: 'preview',
                        transaction: tx.tx,
                        numberOfTickets:  (tx.type as 'buy' | 'mint' === 'buy') ? presaleCount : 1,
                        costInSol: (tx.type as 'buy' | 'mint' === 'buy') ? (presaleCount || 1) * presalePricePerUnit : 0.01,
                        uuid: Math.random().toString(36).substring(7)
                      })));
                      open();

                      // setRefreshData(!refreshData);
                      // // after 30 seconds, hide confetti
                      // setTimeout(() => {
                      //   setShowConfetti(false);
                      // }, 30_000);
                      // setClaimingTickets(false);
                    } catch (e: any) {
                      logger.debug(JSON.stringify(e, null, 2));
                      showNotification({
                        title: 'Something isn\'t right...',
                        message: e.error.errorMessage !== undefined ? e.error.errorMessage : e.message || 'Something went wrong, this usually happens if you do not have enough SOL to cover the transaction fee. Please try again.',
                        color: 'red'
                      })
                      setBuyingTickets(false);
                      setClaimingTickets(false);
                    }
                  }
                },
                defaultIsLoading: buyingTickets || claimingTickets,
              }}
            />
            <Space h={33} />
            <Divider size={1} style={{ width: '100%', borderColor: 'rgba(87, 87, 87, 0.48)' }} />
            <Space h={33} />
            <Stack style={{ width: '100%' }}>
              <Group position="apart" style={{ width: '100%' }}>
                <Text className={classes.statusLabel}>Your balance:</Text>
                <Text className={classes.statusLabel}>{formatNumber(userBalanceSOL, 2)} SOL</Text>
              </Group>
            </Stack>
            <Space h={18} />
            <Stack style={{ width: '100%' }}>
              <Group position="apart" style={{ width: '100%' }}>
                <Text className={classes.redStatusLabel}>Total cost:</Text>
                <Text className={classes.redStatusLabel}>-{formatNumber(presaleCount! * presalePricePerUnit, 2)} SOL</Text>
              </Group>
            </Stack>
            <Space h={18} />
            <Stack style={{ width: '100%' }}>
              <Group position="apart" style={{ width: '100%' }}>
                <Text className={classes.statusLabel}>Est. balance after:</Text>
                <Text className={classes.statusLabel}>= {formatNumber((userBalanceSOL) - (presaleCount! * presalePricePerUnit), 2)} SOL</Text>
              </Group>
              <Group position="apart" style={{ width: '100%' }}>
                <Text className={classes.statusLabel}></Text>
                <Text className={classes.greenStatusLabel}>= {formatNumber((userTicketsRedeemed) + (presaleCount!), 0)} tickets</Text>
              </Group>
            </Stack>
            {/* <Space h={33} />
            <Button 
              label={
                <>
                  <Stack align={'center'}>
                    <Group>
                      <b>Borrow SOL to mint</b>
                      <IconExternalLink size={14} />
                    </Group>
                  </Stack>
                </>
              } 
              variant='secondary'
              toLink='https://sharky.fi/borrow'
              external
            />
            <Space h={28} />
            <Text className={classes.smallText}>Take a loan against any of your NFTs to mint more. Powered by <a href='https://sharky.fi' style={{textDecoration: 'underline', color: 'grey'}} target='_blank'>Sharky.fi</a>.</Text> */}
            <Space h={41} />
          </>
        }
      />
      <Space h={18} />
      <BottomSpacer />
    </Stack>
  );
};

function createNotificationWithId(id: string, title: string, message: string, color: string = 'grey', loading: boolean = false, autoClose: number = 45_000) {
  showNotification({
    id,
    title,
    message,
    color,
    loading,
    autoClose
  });
}

function updateExistingNotification(id: string, title: string, message: ReactNode, color: string, loading: boolean, autoClose: boolean = true) {
  updateNotification({
    id,
    title,
    message,
    color,
    loading,
    autoClose
  })
}

function prettyState(state: State) {
  const formattedState = {
    authority: state.authority.toBase58(),
    unitLots: state.unitLots,
    unitPrice: state.unitPrice.toNumber(),
    maxUnits: state.maxUnits.toNumber(),
    unitsBought: state.unitsBought.toNumber(),
    isInitialized: state.isInitialized,
    isEnded: state.isEnded,
    vault: state.vault.toBase58(),
    whitelist: state.whitelist.toBase58(),
    maxUnitsPerUser: state.maxUnitsPerUser.toNumber(),
    updateAuthority: state.updateAuthority.toBase58(),
    unitsRedeemed: state.unitsRedeemed.toNumber(),
    collection: state.collection.toBase58(),
    collectionMetadata: state.collectionMetadata.toBase58(),
    collectionMasterEdition: state.collectionMasterEdition.toBase58(),
    hasStarted: state.hasStarted,
  };
  return formattedState;
}

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}


const TransactionPreview = ({
  txType,
  state = 'preview',
  numberOfTickets = 1,
  costInSol = 2.5
}: {
  txType: 'buy' | 'mint';
  state: 'preview' | 'signing' | 'confirmed' | 'failed' | 'sending';
  numberOfTickets?: number;
  costInSol?: number;
}) => {
  return (
    <Group
      position="apart"
      style={{ 
        width: '100%', 
        padding: '4px', 
        backgroundColor: state === 'confirmed' ? '#13261C' : state === 'failed' ? '#2E040F' : '#161616'
      }}
    >
      <Badge
        size="md"
        radius={0}
        variant="filled"
        styles={{
          inner: {
            color: 'black',
            fontFamily: 'JetBrains Mono',
            fontWeight: 900,
            fontSize: '12px'
          },
          root: {
            backgroundColor: txType === 'buy' ? '#AAF4CE' : '#AAF4CE'
          }
        }}
      >
        {txType === 'buy' ? 'BUY' : 'MINT'}
      </Badge>
      <Text size="sm" weight={600} style={{ fontFamily: 'JetBrains Mono' }}>
        {numberOfTickets} ticket{numberOfTickets > 1 ? 's' : ''} ◎{costInSol.toFixed(5)}
      </Text>
      <Badge
        variant="dot"
        radius={0}
        size="lg"
        color={
          state === 'preview'
            ? 'grey'
            : state === 'signing'
              ? 'pink'
              : state === 'confirmed'
                ? 'green'
                : state === 'sending'
                  ? 'pink'
                  : 'red'
        }
        styles={{
          inner: {
            color: 'white',
            fontFamily: 'JetBrains Mono',
            fontWeight: 500,
          }
        }}
      >
        {state === 'preview'
          ? 'Preview'
          : state === 'signing'
            ? 'Signing'
            : state === 'sending'
              ? 'Sending'
              : state === 'confirmed'
                ? 'Confirmed'
                : 'Failed'}
      </Badge>
    </Group>
  );
};
