import {
  Badge,
  Center,
  createStyles,
  Group,
  Loader,
  Pagination,
  Space,
  Stack,
  Text
} from '@mantine/core';
import { IconExternalLink } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { Button, SimpleButton } from '../stories/Button/Button';
import { StyledDialog } from '../stories/StyledDialog/StyledDialog';
import { BottomSpacer, showNotificationOnError, TopSpacer } from '../utils';
import { StyledDivider } from '../components/StyledDivider';
import { useWallet } from '@solana/wallet-adapter-react';
import { useLocalStorage } from '@mantine/hooks';
import { APIClient, QuestFilter } from '../api/ApiClient';
import { PublicKey } from '@solana/web3.js';
import { DISCORD_AUTH_URL, PRIMARY_TEXT_COLOR } from '..';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { showNotification } from '@mantine/notifications';

export const UserQuestsView = () => {
  const { connected, publicKey } = useWallet();
  const [jwt] = useLocalStorage({
    key: 'sw-jwt',
    defaultValue: ''
  });
  const [quests, setQuests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [xp, setXp] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [refreshData, setRefreshData] = useState(false);
  const [showCompletedOnly, setShowCompletedOnly] = useState(false);
  const [discordVerified, setDiscordVerified] = useState(false);
  const [discordRequestToken, setDiscordRequestToken] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    async function getQuests() {
      if (connected && publicKey !== null && jwt !== '') {
        const questData = await APIClient.getAllQuests({
          jwt,
          address: publicKey?.toBase58() || undefined,
          page: currentPage,
          showCompletedOnly,
          filter: showCompletedOnly ? QuestFilter.ALL : QuestFilter.OPEN
        });
        setQuests(questData.quests);
        setCurrentPage(questData.currentPage);
        setTotalPages(questData.totalPages);
        setTotalCount(questData.totalCount);
        
        const userData = await APIClient.getUser({
          jwt,
          updateTwitterTokens: true,
        });
        setXp(userData.xp);
        setCompleted(userData.QuestSubmission.length);
        setDiscordVerified(userData.discordVerified);
        setDiscordRequestToken(userData.discordRequestToken);
        setRefreshData(false);
        setLoading(false);
      }
    }

    getQuests();
  }, [connected, publicKey, jwt, refreshData, showCompletedOnly, currentPage]);

  if (loading) {
    return (
      <Stack align="center" spacing={0}>
        <TopSpacer />
        <Space h={24} />
        <Loader />
      </Stack>
    );
  } else if (!discordVerified) {
    return (
      <Stack align="center" spacing={0}>
        <TopSpacer />
        <StyledDialog
          title="Let's make sure you're not a bot 🤖"
          description={<>It looks like you haven't linked your Discord account. Let's get that fixed. <br /><br /> We only request access to verify your username. You can revoke access at anytime.</>}
          descriptionProps={{
            color: '#909296',
            size: 12
          }}
          children={
            <>
              <Space h={30} />
              <Button
                {...{
                  label: 'Connect with Discord',
                  variant: 'discord',
                  toLink: `${DISCORD_AUTH_URL}&state=${discordRequestToken}`,
                  external: true
                }}
              />
              <Space h={38} />
            </>
          }
        />
      </Stack>
    );
  } else {
    return (
      <Stack align="center" spacing={0}>
        <TopSpacer />
        <StyledDialog
          title={`You have ${xp} XP.`}
          description={`50 XP = whitelist spot, 100 XP = presale spot. You have completed ${completed} quests. ⚔️`}
          descriptionProps={{
            color: '#909296',
            size: 12
          }}
          children={<Space h={40} />}
        />
        <Space h={24} />
        <GroupedQuestsBlock
          quests={quests}
          connected={connected}
          publicKey={publicKey!}
          refreshData={setRefreshData}
          showCompletedOnly={showCompletedOnly}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalCount={totalCount}
        />
        <Space h={18} />
        <Group position="center" spacing={60}>
          <Button
            label={showCompletedOnly ? 'Show not completed' : 'Show completed'}
            onClick={() => {
              setCurrentPage(1);
              setShowCompletedOnly(!showCompletedOnly);
            }}
            variant="outline"
          />
        </Group>
        <BottomSpacer />
      </Stack>
    );
  }
};

const GroupedQuestsBlock = ({
  quests,
  connected,
  publicKey,
  refreshData,
  showCompletedOnly,
  currentPage, 
  totalPages,
  setCurrentPage,
  totalCount
}: {
  quests: any[];
  connected: boolean;
  publicKey: PublicKey;
  refreshData: (refresh: boolean) => void;
  showCompletedOnly: boolean;
  currentPage: number;
  totalPages: number;
  setCurrentPage: (currentPage: number) => any;
  totalCount: number;
}) => {
  const [blocks, setBlocks] = useState<any[]>([]);

  useEffect(() => {
    const blockElements = quests.map((quest, index, array) => {
      return (
        <>
          <QuestBlock
            questNumber={quest.id}
            description={quest.title}
            xp={quest.xp}
            refreshData={refreshData}
            status={quest.QuestSubmission.length > 0
              ? quest.QuestSubmission[0].status
              : 'TODO'
            }
            completed={quest.QuestSubmission.length > 0 
              ? quest.QuestSubmission[0].status === 'DONE'
              : false
            }
            url={quest.url}
            isTweet={[2, 3].includes(quest.questTypeId)}
            tweetPostId={quest.meta.twitterPostId}
            queuedStatus={quest.QueuedQuest.length > 0 ? quest.QueuedQuest[0].status : 'NONE'}
          />
          {index !== array.length -1 ? <>
            <StyledDivider />
            <Space h={30} />
          </> : <></>}
        </>
      );
    });

    setBlocks(blockElements);
  }, [quests])


  return (
    <StyledDialog
      title={!showCompletedOnly
        ? `${totalCount} quest${totalCount !== 1 ? 's' : ''} available ⚔️`
        : `${totalCount} quest${totalCount !== 1 ? 's' : ''} completed ⚔️`}
      description={showCompletedOnly 
        ? 'Complete the quests to earn XP in order to unlock additional WL spots and trait upgrades.'
        : 'LFG! You\'ll be able to redeem your XP for additional WL spots soon.'}
      descriptionProps={{
        color: '#909296',
        size: 12
      }}
      children={
        <>
          <Space h={30} />
          <Button
            {...{
              label: connected
                ? `${publicKey?.toBase58().slice(0, 4)}...${publicKey
                  ?.toBase58()
                  .slice(-4)}`
                : 'Select wallet',
              variant: 'solana',
              onClick: () => {}
            }}
          />
          <Space h={30} />
          {quests.length > 0 
            ? <>
              <StyledDivider />
              <Space h={30} />
            </> 
            : <></>}
          
          {blocks}
          <Pagination
            total={totalPages}
            page={currentPage}
            onChange={setCurrentPage}
            styles={() => ({
              item: {
                '&[data-active]': {
                  border: '1px solid #ff8aad',
                  backgroundColor: 'transparent'
                },
                backgroundColor: 'transparent',
                color: 'white',
                border: '1px solid #1a1b1e',
                borderRadius: 0,
                opacity: '1 !important'
              }
            })}
            siblings={3}
          />
          <Space h={30} />
        </>
      }
    />
  );
};

const QuestBlock = ({
  questNumber,
  description,
  xp,
  completed = false,
  refreshData,
  status,
  url,
  isTweet = false,
  tweetPostId,
  queuedStatus = 'NONE'
}: {
  questNumber: number;
  description: string;
  xp: number;
  completed?: boolean;
  refreshData?: (refresh: boolean) => void;
  status: string;
  url: string;
  isTweet?: boolean;
  tweetPostId?: string;
  queuedStatus?: string;
}) => {
  const useStyles = createStyles(() => ({
    title: {
      fontFamily: 'JetBrains Mono',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: PRIMARY_TEXT_COLOR,
      textDecoration: 'underline'
    }
  }));

  const { classes } = useStyles();
  const [jwt] = useLocalStorage({
    key: 'sw-jwt',
    defaultValue: ''
  });
  
  return (
    <Stack spacing={0} style={{ width: '100%' }}>
      <Text className={classes.title}>Quest #{questNumber}</Text>
      <Space h={18} />
      <ApartLink
        label="Description"
        value={description}
        url={url}
      />
      <Space h={18} />
      <ApartText label="XP reward" value={`${xp} XP`} />
      <Space h={18} />
      <ApartStatus 
        label="Status" 
        value={status === 'DONE' ? 'Done' : 'To do'} 
        color={status === 'DONE' ? 'green' : 'yellow'} 
      />
      <Space h={18} />
      {isTweet 
        ? (
          <>
            <Space h={8} />
            <TwitterTweetEmbed tweetId={tweetPostId!} />
            <Space h={18} />
          </>
        ) 
        : (<Space h={18} />)}
      {completed ? (
        <Space h={10} />
      ) : (
        <>
          <Center>
            <Button
              label={(queuedStatus === 'QUEUED' || queuedStatus === 'REQUEUED') 
                ? 'Queued for processing, please wait...' 
                : (queuedStatus === 'PROCESSING')
                  ? 'Processing submission, please wait...'
                  : (queuedStatus === 'ERROR')
                    ? 'Submission failed, please try again'
                    : `Update status for Quest #${questNumber}`}
              underlined={queuedStatus === 'NONE' || queuedStatus === 'ERROR'}
              variant="outline"
              onClick={async () => {
                await showNotificationOnError(async () => {
                  const resp = await APIClient.submitQuest({
                    jwt,
                    questId: questNumber
                  });
                  if (resp.success === false && resp.message === 'Quest submission queued') {
                    showNotification({
                      title: 'Success',
                      message: 'Quest submission queued',
                      color: 'green'
                    });
                  }
                  if (refreshData) {
                    refreshData(true);
                  }
                })
              }}
            />
          </Center>
          <Space h={22} />
        </>
      )}
    </Stack>
  );
};

const ApartText = ({ label, value }: { label: string; value: string }) => {
  const useStyles = createStyles(() => ({
    label: {
      fontFamily: 'JetBrains Mono',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: PRIMARY_TEXT_COLOR
    },
    value: {
      fontFamily: 'JetBrains Mono',
      fontSize: '12px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'right',
      color: '#909296'
    }
  }));

  const { classes } = useStyles();

  return (
    <Group position="apart">
      <Text className={classes.label}>{label}</Text>
      <Text className={classes.value}>{value}</Text>
    </Group>
  );
};

const ApartStatus = ({
  label,
  value,
  color
}: {
  label: string;
  value: string;
  color: string;
}) => {
  const useStyles = createStyles(() => ({
    label: {
      fontFamily: 'JetBrains Mono',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: PRIMARY_TEXT_COLOR
    }
  }));

  const { classes } = useStyles();

  return (
    <Group position="apart">
      <Text className={classes.label}>{label}</Text>
      <Badge children={value} color={color} variant="outline" />
    </Group>
  );
};

const ApartLink = ({
  label,
  value,
  url
}: {
  label: string;
  value: string;
  url: string;
}) => {
  const useStyles = createStyles(() => ({
    label: {
      fontFamily: 'JetBrains Mono',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: PRIMARY_TEXT_COLOR
    },
    value: {
      fontFamily: 'JetBrains Mono',
      fontSize: '12px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'right',
      color: '#909296'
    }
  }));

  const { classes } = useStyles();

  return (
    <Group position="apart">
      <Text className={classes.label}>{label}</Text>
      <SimpleButton
        label={
          <>
            <Group spacing={8}>
              <IconExternalLink size={14} />
              <Text className={classes.value}>{value}</Text>
            </Group>
          </>
        }
        url={url}
      />
    </Group>
  );
};
